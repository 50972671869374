import { createRoot } from "react-dom/client";
import ItemTemplate from "./ItemTemplate";
import { DataSet, Timeline } from "vis-timeline/standalone";
import { useEffect, useRef, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useDebounce } from "react-use";
import {
  calculateDuration,
  hasNonZeroData,
} from "../../../utils/commonFunctions";
import ApiEndpoints from "../../../utils/APIEndpoints";
import criticalIcon from "../../../../assets/EventsAlertIcon.svg";
import "./customTimeline.css";
import { DateTime } from "luxon";

const VisTimelineWithTemplate = ({
  selectedCamera,
  orgid,
  refreshData,
  showEvents,
}) => {
  const containerRef = useRef(null);
  const timelineRef = useRef(null); // To store the timeline instance
  const [cameraGraphData, setCameraGraphData] = useState(null);
  const [items, setItems] = useState(new DataSet()); // Store timeline items

  const [timelineWindow, setTimelineWindow] = useState({
    start: new Date(Date.now() - 60 * 60 * 1000), // Default 1h back
    end: new Date(),
  }); // To track the visible time range
  const latestTimelineWindow = useRef(timelineWindow);

  // Function to fetch camera graph and event data
  const fetchCameraData = async () => {
    console.log("Fetching CameraData:");
    try {
      // Convert to epoch time in seconds
      const startTimeEpoch = Math.floor(
        latestTimelineWindow.current.start.getTime() / 1000
      );
      const endTimeEpoch = Math.floor(
        latestTimelineWindow.current.end.getTime() / 1000
      );
      // Fetch camera graph data
      const cameraGraphUrl = ApiEndpoints.GET_TIMELINE_STATS_BY_CAMERAID_REGION(
        orgid,
        selectedCamera.id,
        startTimeEpoch,
        endTimeEpoch
      );
      const cameraGraphResponse = await fetch(cameraGraphUrl);
      const cameraGraphData = await cameraGraphResponse.json();

      setCameraGraphData(cameraGraphData);
      // After updating the items
      updateTimelineItems(cameraGraphData);
    } catch (error) {
      toast.error("Failed to fetch camera data");
      console.error("Error fetching data:", error);
    }
  };

  const updateTimelineItems = (cameraGraphData) => {
    const firstTimeWindow =
      cameraGraphData.overall.history[0].time_window * 1000;
    const lastTimeWindow =
      cameraGraphData.overall.history[
        cameraGraphData.overall.history.length - 1
      ].time_window * 1000;

    const cameraGraphItem = {
      id: 1,
      group: 1,
      start: new Date(firstTimeWindow),
      end: new Date(lastTimeWindow),
      cameraGraphData,
      itemType: "CameraGraph",
    };

    items.update([cameraGraphItem]);

    if (timelineRef.current) {
      if (showEvents) {
        const groups = new DataSet([{ id: 1, content: "" }]);
        timelineRef.current.setGroups(groups);
      }
      timelineRef.current.setItems(items);
    }
  };

  const handleRangeChange = useCallback(() => {
    if (timelineRef.current) {
      const { start, end } = timelineRef.current.getWindow();
      setTimelineWindow({ start: new Date(start), end: new Date(end) });
    }
  }, []);

  // Keep the latest timelineWindow in a ref to avoid triggering re-renders
  useEffect(() => {
    latestTimelineWindow.current = timelineWindow;
  }, [timelineWindow]);

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      if (showEvents) {
        fetchCameraData();
      }
    }, 5000);

    return () => clearInterval(fetchInterval);
  }, [showEvents]);
  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;
    const firstTimeWindow =
      cameraGraphData?.overall?.history.length > 0
        ? cameraGraphData.overall.history[0].time_window * 1000
        : new Date(Date.now() - 1 * 60 * 60 * 1000);

    if (!timelineRef.current) {
      const options = {
        orientation: {
          axis: "top",
          item: "top",
        },
        start: firstTimeWindow,
        end: new Date(),
        editable: false,
        zoomable: true,
        selectable: false,
        rollingMode: {
          follow: true,
          offset: 0.5,
        },
        stack: true,
        showCurrentTime: true,
        zoomMin: 0.125 * 60 * 60 * 1000, // Minimum zoom to 1 hour
        visibleFrameTemplate: function (item, element) {
          if (!element || !item) return;

          if (!element.childElementCount) {
            const rootElement = document.createElement("div");
            const hasOverallData =
              item.cameraGraphData.overall.history.length > 0 &&
              Object.keys(item.cameraGraphData.overall.stats).length > 0 &&
              hasNonZeroData(item.cameraGraphData.overall.history);

            if (hasOverallData) {
              rootElement.style.height = "50px";
            } else {
              rootElement.style.height = "0px";
            }
            element.appendChild(rootElement);
            const root = createRoot(rootElement);
            if (item.itemType === "CameraGraph") {
              root.render(
                <ItemTemplate
                  item={item}
                  selectedCamera={selectedCamera}
                  orgid={orgid}
                  refreshData={refreshData}
                  cameraGraphData={item.cameraGraphData}
                />
              );
            } else {
              root.render(item.content);
            }
          }
        },
      };

      timelineRef.current = new Timeline(container, items, options);
      timelineRef.current.on("rangechange", handleRangeChange);
      // Track if the custom marker is added
      let isCustomMarkerAdded = false;
      // Function to add or update the custom time marker
      const updateCustomTimeMarker = () => {
        const now = new Date();
        const currentTimeText = formatCurrentTime(now);
        // Add or update the custom time marker
        if (!isCustomMarkerAdded) {
          // Add the custom time marker for the first time
          timelineRef.current.addCustomTime(now, "current-time-marker");
          isCustomMarkerAdded = true;
        } else {
          // Update the custom time marker
          timelineRef.current.setCustomTime(now, "current-time-marker");
        }

        timelineRef.current.setCustomTimeMarker(
          `<div class="text-white bg-r_color_primary-500 rounded-full px-3 py-1 text-xs">${currentTimeText}</div>`,
          "current-time-marker"
        );
      };
      // Initially update the marker
      updateCustomTimeMarker();
      const intervalId = setInterval(() => {
        updateCustomTimeMarker();
      }, 5000);

      return () => {
        clearInterval(intervalId); // Cleanup interval on unmount
        if (timelineRef.current) {
          timelineRef.current.off("rangechange", handleRangeChange);
          timelineRef.current.destroy();
          timelineRef.current = null;
        }
      };
    } else {
      timelineRef.current.setItems(items);
    }
  }, [items]);
  const clearTimelineItems = () => {
    if (timelineRef.current) {
      // Clear all groups and items when events are not shown
      timelineRef.current.setGroups([]);
      timelineRef.current.setItems(new DataSet());
    }
    setCameraGraphData(null); // Clear the graph data as well
  };
  useEffect(() => {
    // Check if showEvents is false, clear the timeline items
    if (showEvents === false) {
      clearTimelineItems();
    } else {
      // Fetch camera data when showEvents is true or refreshData changes
      fetchCameraData();
    }
  }, [showEvents, refreshData]);

  return (
    <div>
      <div id="visualization" ref={containerRef} />
    </div>
  );
};

export default VisTimelineWithTemplate;

const formatCurrentTime = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};
