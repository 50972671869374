"use client";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { ReactComponent as ReturnToMenu } from "../assets/ChatbotInputBack.svg";

export default function ChatBotComboBox({
  options = [],
  backToPrevMenuHandler,
  onOptionSelect,
}) {
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // Single selection, use null instead of array

  const filteredOptions =
    query === ""
      ? options
      : options?.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={(option) => {
        setQuery("");
        setSelectedOption(option);
        console.log("debug 1");

        if (onOptionSelect) {
          onOptionSelect(option);
        }
      }}
    >
      <div className="relative mt-2">
        {/* Combobox Input */}
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-8 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery("")}
          displayValue={(option) => option?.label || ""}
        />

        {/* Back Button */}
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            backToPrevMenuHandler();
          }}
          className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          <ReturnToMenu className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        {/* Options */}
        {filteredOptions.length > 0 && (
          <ComboboxOptions className="absolute z-10 mb-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm bottom-full">
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-r_color_primary-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">
                  {option.label}
                </span>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-r_color_primary-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
