import { useState, useEffect } from "react";
import { mainMenuOptions, subMenuOptions } from "./menuServices";

const useChatBotState = (props) => {
  const [botState, setBotState] = useState({
    orgid: "org_ramennetworks",
    duration: "24h",
    username: "",
    sites: [],
    selectedOrg: "Wonderful Citrus",
    selectedSite: "Default Site",
  });
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [calculatedStatistics, setCalculatedStatistics] = useState([]);
  const [calculatedChartData, setCalculatedChartData] = useState([]);
  const [showCombobox, setShowCombobox] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userOptions, setUserOptions] = useState(mainMenuOptions);
  const [comboboxOptions, setcomboboxOptions] = useState([]);
  const [form, setForm] = useState([]);
  const [currentWidgetConfig, setCurrentWidgetConfig] = useState([]);
  const [paths, setPaths] = useState([]);
  const [prevMenu, setPrevMenu] = useState(mainMenuOptions);
  const [chatBotInputValue, setchatBotInputValue] = useState("");
  useEffect(() => {
    if (props.statistics) {
      setBotState((prevBotState) => ({
        ...prevBotState,
        statistics: props.statistics,
      }));
    }
    if (props.chartData) {
      setBotState((prevBotState) => ({
        ...prevBotState,
        chartData: props.chartData,
      }));
    }
    if (props.averages) {
      setBotState((prevBotState) => ({
        ...prevBotState,
        averages: props.averages,
      }));
    }
  }, [props]);

  useEffect(() => {
    if (paths.length > 0) {
      const currentPath = paths[paths.length - 1];
      if (currentPath === "showmainMenu") {
        setUserOptions(mainMenuOptions);
      } else if (subMenuOptions[currentPath]) {
        setPrevMenu(userOptions); // Set the current user options as the previous menu before updating
        setUserOptions(subMenuOptions[currentPath]);
      }
    }
  }, [paths]);

  useEffect(() => {
    const greetings = [
      "Hello! How can I assist you today?",
      "Hi there! What can I do for you?",
      "Greetings! How may I help you?",
      "Hey! Need any assistance?",
      "Hello! What can I help you with today?",
    ];

    const addMessage = (content, sender = "bot") => {
      setMessages((prev) => [...prev, { content, sender, type: "string" }]);
    };

    if (chatBotInputValue) {
      const lowerCaseInput = chatBotInputValue.toLowerCase();

      // Respond with a random greeting if input is 'hi' or 'hello'
      if (lowerCaseInput === "hi" || lowerCaseInput === "hello") {
        const randomGreeting =
          greetings[Math.floor(Math.random() * greetings.length)];
        setTimeout(() => addMessage(randomGreeting), 1000);
      }
      // Clear messages if input is 'clear'
      else if (
        lowerCaseInput === "clear message" ||
        lowerCaseInput === "clear"
      ) {
        setMessages([]);
        setPaths((prev) => [...prev, "start"]);
      }
      // Navigate to fetchCamera if input is 'active camera'
      else if (lowerCaseInput === "active camera") {
        setPaths((prev) => [...prev, "fetchCamera"]);
      }
      // Default response for unrecognized commands
      else {
        setTimeout(() => {
          addMessage(
            'I\'m still learning new tricks! Please use the Option button to navigate. Here are some commands I understand: "hi", "hello", "clear", and "active camera". Thanks for your patience!'
          );
        }, 1000);
      }
    }
  }, [chatBotInputValue]);

  return {
    botState,
    setBotState,
    showUserOptions,
    setShowUserOptions,
    messages,
    setMessages,
    userOptions,
    setUserOptions,
    form,
    setForm,
    paths,
    setPaths,
    setPrevMenu,
    prevMenu,
    mainMenuOptions,
    currentWidgetConfig,
    setCurrentWidgetConfig,
    showCombobox,
    setShowCombobox,
    comboboxOptions,
    setcomboboxOptions,
    calculatedStatistics,
    calculatedChartData,
    setchatBotInputValue,
    chatBotInputValue,
  };
};

export default useChatBotState;
