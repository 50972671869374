// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rcb-chat-window {
  position: initial;
  /* border: 1px solid gray; */
  height: calc(100vh - 64px);
  width: 100%;
}
#chatbotv3 > div {
  width: 100%;
}

.rcb-options-container {
  flex-direction: column;
}
.rcb-chat-input {
  display: none;
}
.rcb-chat-bot.rcb-window-embedded,
.rcb-chat-body-container,
.rcb-chat-window {
  border-radius: 0px;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.rcb-chat-body-container {
  max-height: calc(100vh - 128px);
  /* max-height: calc(100vh - 64px); */
}
`, "",{"version":3,"sources":["webpack://./src/components/chat-bot/chatbotv3.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,4BAA4B;EAC5B,0BAA0B;EAC1B,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;AAIE;;;EAAA,kBAA+C;EAA/C,6BAA+C;EAA/C,oEAA+C;EAA/C,gFAA+C;EAA/C,oGAA+C;EAA/C;AAA+C;;AAGjD;EACE,+BAA+B;EAC/B,oCAAoC;AACtC","sourcesContent":[".rcb-chat-window {\n  position: initial;\n  /* border: 1px solid gray; */\n  height: calc(100vh - 64px);\n  width: 100%;\n}\n#chatbotv3 > div {\n  width: 100%;\n}\n\n.rcb-options-container {\n  flex-direction: column;\n}\n.rcb-chat-input {\n  display: none;\n}\n.rcb-chat-bot.rcb-window-embedded,\n.rcb-chat-body-container,\n.rcb-chat-window {\n  @apply !bg-r_color_base shadow-xl  rounded-none;\n}\n\n.rcb-chat-body-container {\n  max-height: calc(100vh - 128px);\n  /* max-height: calc(100vh - 64px); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
