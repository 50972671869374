"use client";

import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import ChatbotLayout from "./chatbot-layout";
import { BOT_ENUM } from "./botEnums";

export default function ChatbotModal({
  showRamenAIClient,
  onClose,
  statistics,
  chartData,
  averages,
  viewIn,
  toggleRamenAssitWindowSize,
}) {
  const [open, setOpen] = useState(showRamenAIClient);
  const [isMinimized, setIsMinimized] = useState(false);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    setOpen(showRamenAIClient);
  }, [showRamenAIClient]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => {}} className={`relative z-[70] `}>
      <div
        className={`fixed inset-0 z-10 w-screen overflow-y-auto ${
          viewIn === BOT_ENUM.WINDOW_REGULARSIZE
            ? "max-w-md ml-auto top-16"
            : ""
        } ${isMinimized ? "top-[calc(100%-64px)]" : ""}`}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 h-full">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-0 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-full h-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <ChatbotLayout
              viewIn={viewIn}
              handleClose={handleClose}
              statistics={statistics}
              chartData={chartData}
              averages={averages}
              toggleRamenAssitWindowSize={toggleRamenAssitWindowSize}
              handleMinimize={handleMinimize}
            ></ChatbotLayout>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
