import { useEffect, useState } from "react";
import CameraTrouser from "../camera_trouser/camera-trouser";
import { PlusIcon } from "@heroicons/react/20/solid";
import { LocalizationKeys } from "../../../localizationStrings/en";
import AddCameraFlow from "../../addCameraModal";
import { toast } from "react-toastify";

import online_icon from "../../../assets/online.svg";
import offline_icon from "../../../assets/offline.svg";

import TrendLineWidget from "../../trendlineWidget";
import ramenColors from "../../summary-charts/ramen-colors";
import { CameraThumbnail } from "../../thumbnail";
import Toggle from "../../core-components/toggle";
import { ReactComponent as Check } from "../../../assets/check.svg";
import { ReactComponent as Ban } from "../../../assets/Ban.svg";

import ConfirmationModal from "../../core-components/confirmationModal";
import ApiEndpoints from "../../utils/APIEndpoints";

export default function MainContainerCameraList({
  orgid,
  cameraList,
  cameraListLoading,
  toggleRamenAssit,
  showRamenAIClient,
  fetchCameraList,
  widgetLastRefreshedTime,
  duration,
  setisCameraTrouserOpen,
  isCameraTrouserOpen,
}) {
  const [open, setOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [showCameraFlow, setShowCameraFlow] = useState(false);
  const [srcError, setSrcError] = useState({}); // Store error state for each camera
  const [showConfirmationModal, setshowConfirmationModal] = useState(false);
  const [selectedCameraForConfirmation, setSelectedCameraForConfirmation] =
    useState(null);
  const [cameraAdminStates, setCameraAdminStates] = useState({});
  useEffect(() => {
    if (cameraList.length > 0) {
      const initialState = {};
      cameraList.forEach((camera) => {
        initialState[camera.id] = camera.admin_state;
      });
      setCameraAdminStates(initialState);
    }
  }, [cameraList]);
  const handleCloseCameraFlow = () => {
    setShowCameraFlow(false);
  };

  const handleCardClick = (camera) => {
    setSelectedCamera(camera);
    setOpen(true);
    setisCameraTrouserOpen(true);
  };

  const handleImageError = (cameraId) => {
    setSrcError((prevState) => ({
      ...prevState,
      [cameraId]: true,
    }));
  };

  const chartData = [
    {
      time_window: 1724635010,
      total_entries: 48,
      total_object_count: 4,
      unique_objects: 20,
    },
    {
      time_window: 1724638610,
      total_entries: 16,
      total_object_count: 11,
      unique_objects: 4,
    },
    {
      time_window: 1724642210,
      total_entries: 1,
      total_object_count: 67,
      unique_objects: 5,
    },
    {
      time_window: 1724645810,
      total_entries: 40,
      total_object_count: 0,
      unique_objects: 12,
    },
    {
      time_window: 1724649410,
      total_entries: 24,
      total_object_count: 42,
      unique_objects: 9,
    },
    {
      time_window: 1724653010,
      total_entries: 40,
      total_object_count: 47,
      unique_objects: 10,
    },
    {
      time_window: 1724656610,
      total_entries: 0,
      total_object_count: 82,
      unique_objects: 2,
    },
    {
      time_window: 1724660210,
      total_entries: 34,
      total_object_count: 6,
      unique_objects: 12,
    },
    {
      time_window: 1724663810,
      total_entries: 16,
      total_object_count: 76,
      unique_objects: 20,
    },
    {
      time_window: 1724667410,
      total_entries: 49,
      total_object_count: 61,
      unique_objects: 15,
    },
    {
      time_window: 1724671010,
      total_entries: 32,
      total_object_count: 56,
      unique_objects: 15,
    },
    {
      time_window: 1724674610,
      total_entries: 2,
      total_object_count: 52,
      unique_objects: 9,
    },
    {
      time_window: 1724678210,
      total_entries: 31,
      total_object_count: 90,
      unique_objects: 18,
    },
    {
      time_window: 1724681810,
      total_entries: 13,
      total_object_count: 25,
      unique_objects: 9,
    },
    {
      time_window: 1724685410,
      total_entries: 35,
      total_object_count: 29,
      unique_objects: 15,
    },
    {
      time_window: 1724689010,
      total_entries: 6,
      total_object_count: 53,
      unique_objects: 18,
    },
    {
      time_window: 1724692610,
      total_entries: 12,
      total_object_count: 73,
      unique_objects: 6,
    },
    {
      time_window: 1724696210,
      total_entries: 47,
      total_object_count: 25,
      unique_objects: 20,
    },
    {
      time_window: 1724699810,
      total_entries: 42,
      total_object_count: 78,
      unique_objects: 1,
    },
    {
      time_window: 1724703410,
      total_entries: 4,
      total_object_count: 59,
      unique_objects: 19,
    },
    {
      time_window: 1724707010,
      total_entries: 17,
      total_object_count: 39,
      unique_objects: 7,
    },
    {
      time_window: 1724710610,
      total_entries: 15,
      total_object_count: 72,
      unique_objects: 14,
    },
    {
      time_window: 1724714210,
      total_entries: 33,
      total_object_count: 22,
      unique_objects: 1,
    },
    {
      time_window: 1724717810,
      total_entries: 24,
      total_object_count: 8,
      unique_objects: 8,
    },
  ];

  const handleToggleChange = (camera) => {
    setSelectedCameraForConfirmation(camera); // Set the selected camera for confirmation
    setshowConfirmationModal(true); // Show confirmation modal
  };

  // Proceed after confirmation
  const proceedChangingAdminState = () => {
    handleAdminStateUpdate(); // Call the function to update the admin state
  };

  const cancelAdminStateChangeFlow = () => {
    setSelectedCameraForConfirmation(null);
    setshowConfirmationModal(false); // Close the modal if action is canceled
  };

  const handleAdminStateUpdate = async () => {
    const cameraId = selectedCameraForConfirmation.id;
    const currentAdminState = cameraAdminStates[cameraId];
    const newAdminState = !currentAdminState; // Toggle the state locally
    const cameraupdate_url = ApiEndpoints.GET_CAMERA_BY_ID(orgid, cameraId);
    let apiData = { admin_state: newAdminState };

    try {
      const response = await fetch(cameraupdate_url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        toast.success("Admin State updated successfully");
        fetchCameraList();
      } else {
        const errorData = await response.json();
        toast.error(errorData.errors || "Error updating camera admin state");
      }
    } catch (error) {
      console.error("Error updating camera admin state:", error);
      toast.error("An unexpected error occurred");
    } finally {
      setSelectedCameraForConfirmation(null);
      setshowConfirmationModal(false);
    }
  };

  const cameraListFlow = (
    <div className="h-full">
      <div className="py-4 px-2">
        <h2 className="sr-only">Camera List</h2>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-4 lg:grid-cols-3 lg:gap-x-4">
          {cameraList.map((camera) => (
            <div
              key={camera.id}
              onClick={() => handleCardClick(camera)}
              className="group relative cursor-pointer flex flex-col  rounded-lg border border-r_color_gray-200 bg-r_color_base"
            >
              <CameraThumbnail
                camera={camera}
                srcError={srcError}
                handleImageError={handleImageError}
              />
              <div className="flex flex-1 flex-col space-y-2 px-6 py-4">
                <dl className="divide-y divide-r_color_gray-100 flex flex-col">
                  <div className="px-4 pb-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0 flex justify-center items-center">
                    <span className="text-sm font-semibold leading-6 text-r_color_gray-800 truncate">
                      {camera.name}
                    </span>
                    <TrendLineWidget
                      chartData={chartData}
                      chartColor={ramenColors.r_color_secondary[500]}
                      selectedCamera={camera}
                      orgid={orgid}
                      duration={duration}
                      refreshData={widgetLastRefreshedTime}
                      isCameraTrouserOpen={isCameraTrouserOpen}
                    />
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-normal leading-6 text-r_color_gray-400">
                      Description
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-r_color_gray-700 sm:col-span-1 sm:mt-0 justify-end flex truncate text-left">
                      {camera.description ? camera.description : "-"}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-normal leading-6 text-r_color_gray-400">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-r_color_gray-700 sm:col-span-1 sm:mt-0 justify-end flex">
                      {camera.status === "Online" ? (
                        <div className="flex items-center gap-2">
                          <img
                            src={online_icon}
                            className="w-4 h-4"
                            alt="camera online"
                          />
                          <div className="text-r_green-500 text-sm font-medium leading-[21px]">
                            {LocalizationKeys.ONLINE}
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2">
                          <img
                            src={offline_icon}
                            className="w-4 h-4"
                            alt="camera offline"
                          />
                          <div className="text-r_color_gray-500 text-sm font-medium leading-[21px]">
                            {LocalizationKeys.OFFLINE}
                          </div>
                        </div>
                      )}
                    </dd>
                  </div>
                  <div className="px-4 pt-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-normal leading-6 text-r_color_gray-400">
                      Alert
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-r_color_primary-500 sm:col-span-1 sm:mt-0 justify-end flex">
                      {camera?.alerts?.total}
                    </dd>
                  </div>
                  <div className="px-4 pt-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-normal leading-6 text-r_color_gray-400">
                      Admin State
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-r_color_primary-500 sm:col-span-1 sm:mt-0 justify-end flex">
                      <Toggle
                        enabled={cameraAdminStates[camera.id] ?? false}
                        onToggleChange={() => handleToggleChange(camera)}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          Icon={
            selectedCameraForConfirmation?.admin_state !== true ? Check : Ban
          }
          iconcss={
            selectedCameraForConfirmation?.admin_state !== true
              ? "h-6 w-6 text-r_color_primary-500"
              : "h-6 w-6 text-red-500"
          }
          iconbg={
            selectedCameraForConfirmation?.admin_state !== true
              ? "bg-r_color_primary-50"
              : "bg-red-100"
          }
          confimationButtonBg={
            selectedCameraForConfirmation?.admin_state !== true
              ? "bg-r_color_primary-500 hover:bg-r_color_primary-600 "
              : "bg-red-500 hover:bg-red-600"
          }
          confirmationText={
            selectedCameraForConfirmation?.admin_state !== true
              ? "Yes, Enable"
              : "Yes, Disable"
          }
          heading="Confirm Action"
          subheading={
            selectedCameraForConfirmation?.admin_state !== true ? (
              <>
                Are you sure you want to change the admin state for
                <span className="text-gray-800 text-sm font-semibold leading-tight ml-1">
                  {selectedCameraForConfirmation?.name}
                </span>
                ?
              </>
            ) : (
              <>
                <div className="text-gray-500 text-sm font-normal  leading-tight">
                  Are you sure you want to Disable admin state for
                  <span className="text-gray-800 text-sm font-semibold  leading-tight ml-1">
                    {selectedCameraForConfirmation?.name}
                  </span>
                  ?<br />
                  <span className="italic font-semibold ">
                    This action stops analytics for this camera
                  </span>
                </div>
              </>
            )
          }
          cancelText="Cancel"
          onConfirm={proceedChangingAdminState}
          onCancel={cancelAdminStateChangeFlow}
          open={showConfirmationModal}
          setOpen={setshowConfirmationModal}
        />
      )}

      {selectedCamera && (
        <CameraTrouser
          open={open}
          onClose={(e) => {
            setOpen(false);
            setSelectedCamera(null);
            if (e === "fetchCameraList") {
              fetchCameraList();
            }
            setisCameraTrouserOpen(false);
          }}
          selectedCamera={selectedCamera}
          orgid={orgid}
          toggleRamenAssit={toggleRamenAssit}
          showRamenAIClient={showRamenAIClient}
        />
      )}
    </div>
  );

  const cameraAddFlow = (
    <>
      <div className="flex justify-center items-center h-full flex-col gap-2">
        <div className="text-center text-r_color_gray-300 text-xl font-semibold uppercase">
          {LocalizationKeys.NO_CAMERA_TEXT}
        </div>
        <div className="text-center text-r_color_gray-500 text-base font-medium">
          {LocalizationKeys.ADD_CAMERA_TEXT_MAIN_CONTAINER}
        </div>
        <div className="">
          <button
            type="button"
            onClick={() => setShowCameraFlow(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-r_color_primary-500 px-3 py-2 text-sm font-semibold text-r_color_base shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600"
          >
            <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            {LocalizationKeys.Add_Camera}
          </button>
        </div>
      </div>
      {showCameraFlow && (
        <AddCameraFlow handleClose={handleCloseCameraFlow} orgId={orgid} />
      )}
    </>
  );

  const cameraCardLoader = (
    <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-4 lg:grid-cols-3 lg:gap-x-4">
      {Array.from({ length: 20 }).map((_, index) => (
        <div
          key={index}
          className="h-80 px-4 py-3 bg-r_color_base rounded-md shadow flex-col justify-start items-center gap-2 inline-flex"
        >
          <div className="self-stretch h-[160px] bg-r_color_gray-200 animate-pulse" />
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
            <div className="self-stretch h-7 justify-between items-center inline-flex">
              <div className="w-[148px] self-stretch bg-r_color_gray-200 animate-pulse" />
              <div className="w-[68px] self-stretch bg-r_color_gray-200 animate-pulse" />
            </div>
            <div className="self-stretch h-7 justify-between items-center inline-flex">
              <div className="w-[133px] self-stretch bg-r_color_gray-200 animate-pulse" />
              <div className="w-[68px] self-stretch bg-r_color_gray-200 animate-pulse" />
            </div>
            <div className="self-stretch h-7 justify-between items-center inline-flex">
              <div className="w-[133px] self-stretch bg-r_color_gray-200 animate-pulse" />
              <div className="w-[68px] self-stretch bg-r_color_gray-200 animate-pulse" />
            </div>

            <div className="self-stretch h-7 justify-between items-center inline-flex">
              <div className="w-[148px] self-stretch bg-r_color_gray-200 animate-pulse" />
              <div className="w-[68px] self-stretch bg-r_color_gray-200 animate-pulse" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return cameraListLoading
    ? cameraCardLoader
    : cameraList.length > 0
    ? cameraListFlow
    : cameraAddFlow;
}
