import ChatbotNavbar from "./chat-bot-navbar";
import ChatbotSideDrawerPlaceholder from "./chat-bot-side-drawer-placeholder";
import ChatbotLeftActionPanel from "./chat-bot-left-action-panel";
import ChatbotMessageArea from "./chatbot-message-area";
import { BOT_ENUM } from "./botEnums";

export default function ChatbotLayout({
  handleClose,
  statistics,
  chartData,
  averages,
  viewIn,
  toggleRamenAssitWindowSize,
  handleMinimize,
}) {
  return (
    <>
      <div className="min-h-full flex flex-col shadow-lg">
        <ChatbotNavbar
          handleClose={handleClose}
          toggleRamenAssitWindowSize={toggleRamenAssitWindowSize}
          handleMinimize={handleMinimize}
        ></ChatbotNavbar>
        <div className="  flex flex-grow  ">
          <div
            className={`mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-full lg:grid-cols-12 lg:gap-8 lg:px-8  flex flex-grow ${
              viewIn === BOT_ENUM.WINDOW_REGULARSIZE ? "!px-0" : ""
            }`}
          >
            {viewIn !== BOT_ENUM.WINDOW_REGULARSIZE && (
              <ChatbotLeftActionPanel />
            )}
            <ChatbotMessageArea
              viewIn={viewIn}
              statistics={statistics}
              chartData={chartData}
              averages={averages}
            />
            {viewIn !== BOT_ENUM.WINDOW_REGULARSIZE && (
              <ChatbotSideDrawerPlaceholder />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
