import axios from "axios";
import crypto from "crypto-js"; // Import crypto-js for password hashing
import ramen_logo from "../../assets/login-logo.svg";
import loginscreen_image from "../../assets/ramen-corouser-icontwo.png";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Hash/mangle the password before sending it to the backend
    const hashedPassword = crypto.SHA256(password).toString();

    try {
      const response = await axios.post("/login", {
        username,
        password: hashedPassword,
      });

      if (response.status === 200) {
        onLogin();
      } else {
        toast.error("Invalid credentials");
      }
    } catch (error) {
      toast.error("Error during login: " + error.message);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 ">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                alt="Your Company"
                src={ramen_logo}
                className="h-10 w-auto"
              />
              <h2 className="mt-6 text-3xl font-extrabold leading-9 tracking-tight text-r_color_gray-900">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-8">
              <div>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-r_color_gray-700"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-r_color_gray-300 placeholder:text-r_color_gray-400 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-r_color_gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-r_color_gray-300 placeholder:text-r_color_gray-400 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-r_color_primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            alt=""
            src={loginscreen_image}
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
