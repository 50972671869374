import React from "react";
import { fetchCameras } from "./apiServices";
import TableView from "../components/tableView";
import CameraInfoCardBotFlow from "../components/cameraInfo";
import ApiEndpoints from "../../utils/APIEndpoints";
import BotResponse from "../components/botResponse";

const flowConfig = ({
  setBotState,
  botState,
  setForm,
  form,
  setShowUserOptions,
  currentWidgetConfig,
  setShowCombobox,
  setcomboboxOptions,
  setPaths,
  calculatedStatistics,
  calculatedChartData,
  setUserOptions,
}) => ({
  start: {
    message: () => {
      setUserOptions([
        // {
        //   text: "Show Organization List",
        //   menu_path: "fetchOrgList",
        // },
        // {
        //   text: "Show list of camera for this Site",
        //   menu_path: "fetchCamera",
        // },

        {
          text: "Overall Analytics",
          subheading:
            "Get analytics related to all camera under this organisation",
          menu_path: "fetchAllCamera",
        },

        // {
        //   text: "Specific Camera Analytics",
        //   menu_path: "",
        //   subheading: "Get analytics based on the specific camera",
        // },
      ]);
      setShowUserOptions(true);
      return "What would you like to dive into?";
    },
    path: "orgList",
  },
  fetchAllCamera: {
    message: async (params) => {
      if (!botState.allcamera) {
        const orgid = botState.orgid;
        const result = await fetchCameras(orgid);
        setBotState((prevBotState) => ({
          ...prevBotState,
          allcamera: result,
        }));
        const comboOptions = result.map((camera) => {
          return {
            id: camera.id,
            label: camera.name,
            path: "show_camera_card",
          };
        });
        setcomboboxOptions(comboOptions);
      }
      setShowUserOptions(false);
      setShowCombobox(true);

      return "Type the name of the Camera you want to look into";
    },
  },
  show_camera_card: {
    message: async (params) => {
      const fetchCameraGraphData = async () => {
        try {
          const url = ApiEndpoints.GET_REGION_STATS_BY_CAMERAID(
            botState.orgid,

            currentWidgetConfig.id,
            botState.duration
          );
          const response = await fetch(url);
          const data = await response.json();

          setBotState((prevBotState) => ({
            ...prevBotState,
            camera: {
              ...prevBotState.camera, // Spread the existing camera object
              [currentWidgetConfig.id]: data, // Dynamically set the property with the currentWidgetConfig.id
            },
            test: "1234",
          }));
          setPaths((prev) => [...prev, "showcameraInfoCard"]);
        } catch (error) {
          console.error("Failed to fetch camera alert info:", error);
          return "unable to fetch alert region, please try later ";
        }
      };
      fetchCameraGraphData();

      return ``;
    },
  },

  showcameraInfoCard: {
    message: async () => {
      return "fetching data";
    },
    render: (
      <BotResponse>
        <CameraInfoCardBotFlow
          selectedWidget={currentWidgetConfig}
          orgid={botState.orgid}
          history={
            botState?.camera
              ? botState?.camera[currentWidgetConfig?.id]?.overall?.history
              : []
          }
          stats={
            botState?.camera
              ? botState?.camera[currentWidgetConfig?.id]?.overall?.stats
              : []
          }
        ></CameraInfoCardBotFlow>
      </BotResponse>
    ),
  },
  moreOptionsForOverallCard: {
    message: async () => {
      setShowUserOptions(true);
      setUserOptions([
        {
          text: "View other regions",
          subheading: "Add more filters to view specific data",
          path: "viewAllRegion",
        },

        {
          text: "View other regions 1",
          path: "",
          subheading: "Get analytics based on the specific camera",
        },
      ]);
      setShowCombobox(false);
    },
  },
  viewAllRegion: {
    message: async () => {
      console.log(botState.camera.currentWidgetConfig.id);
      debugger;

      setUserOptions([
        {
          text: "View other regions",
          subheading: "Add more filters to view specific data",
          menu_path: "viewAllRegion",
        },

        {
          text: "View other regions 1",
          menu_path: "",
          subheading: "Get analytics based on the specific camera",
        },
      ]);
    },
  },
  prepareItemForDisplay: {
    message: async (params) => {
      return `fetching the current stats`;
    },
    render: (
      <TableView heading={botState?.itemInfo?.tableTitle} subheading={""} />
    ),
  },
});

export default flowConfig;
