import { ResponsiveLine } from "@nivo/line";
import ramenColors from "./summary-charts/ramen-colors";
import { DotsItem, useTheme } from "@nivo/core";
import { useEffect, useState } from "react";
import { chartTheme } from "./summary-charts/chart-theme-service";
import { CameraGraphBar } from "./camera-overview-graph";
import { LocalizationKeys } from "../localizationStrings/en";
import ApiEndpoints from "./utils/APIEndpoints";
import { toast } from "react-toastify";
import { hasNonZeroData } from "./utils/commonFunctions";

function TrendLineWidget({
  selectedCamera,
  orgid,
  duration,
  refreshData,
  isCameraTrouserOpen,
}) {
  const [cameraGraphData, setCameraGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchCameraGraphData = async () => {
      try {
        const url =
          ApiEndpoints.GET_TIMELINE_STATS_BY_CAMERAID_REGION_TRENDLINE(
            orgid,
            selectedCamera.id,
            duration
          );
        const response = await fetch(url);
        const data = await response.json();

        setCameraGraphData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Failed to fetch camera trendline  info");
        console.error("Failed to fetch camera trendline ", error);
      }
    };
    if (isCameraTrouserOpen === false) {
      fetchCameraGraphData();
    }
  }, [selectedCamera, orgid, duration, isCameraTrouserOpen]);
  const hasOverallData =
    cameraGraphData?.overall?.history.length > 0 &&
    Object.keys(cameraGraphData?.overall?.stats).length > 0 &&
    hasNonZeroData(cameraGraphData?.overall?.history);
  return (
    <div className="w-full  h-5 overflow-visible flex-col justify-start items-center gap-px inline-flex z-10 ">
      {/* Render your camera graph */}

      {hasOverallData ? (
        <CameraGraphBar
          chartData={cameraGraphData.overall.history}
          chartColor={ramenColors.r_color_secondary[600]}
          duration={duration}
          axisLeft={null}
          chartUsedInTimeline={true}
          margin={{ bottom: 20, top: 0 }}
          animate={false}
          tooltipStack={true}
        />
      ) : (
        <div className="bg-r_color_base  flex flex-col rounded-md  gap-2  w-full h-full">
          <div className=" flex justify-center  items-center  text-center text-r_color_gray-300  text-xs font-bold  uppercase  h-full">
            {LocalizationKeys.NODATA_TEXT}
          </div>
        </div>
      )}
    </div>
  );
}

function LastPoint({ points, ...props }) {
  const theme = useTheme();
  const shownPoints = points.slice(-1);

  return (
    <g>
      {shownPoints.map((point) => (
        <DotsItem
          key={point.id}
          x={point.x}
          y={point.y}
          datum={point.data}
          symbol={props.pointSymbol}
          size={props.pointSize}
          color={point.color}
          borderWidth={props.pointBorderWidth}
          borderColor={point.borderColor}
          label={point.label}
          labelYOffset={props.pointLabelYOffset}
          theme={theme}
        />
      ))}
    </g>
  );
}

export default TrendLineWidget;
