"use client";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import ramen_logo from "../../../assets/login-logo.svg";
import { ReactComponent as RamenLogoComponent } from "../../../assets/Ramen-Robot-Guy-Logo-Mark-White-Padding.svg";

const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];

export default function TopNav({
  handleSidebarClick,
  toggleRamenAssit,
  showRamenAIClient,
  handleLogout,
}) {
  return (
    <div className=" bg-r_navy-600  sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-r_navy-600  px-4 shadow-sm sm:gap-x-6 sm:px-6 ">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>

      {/* Separator */}
      <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="flex items-center relative  flex-1 ">
          <div className="flex-shrink-0">
            <img alt="Your Company" src={ramen_logo} className="h-8 w-14" />
          </div>
          <div className="hidden md:block">
            <div className="w-96 ml-4 text-r_color_base text-base font-bold "></div>
          </div>
        </div>
        <div className="flex items-center gap-x-2 lg:gap-x-2 ">
          <button
            type="button"
            className={`inline-flex items-center gap-x-2 rounded-full px-3.5  text-sm font-semibold text-r_color_base shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
              showRamenAIClient
                ? "focus-visible:outline-r_color_primary-600 hover:bg-r_color_primary-600 bg-r_color_primary-500"
                : "ring-[2px] ring-r_color_primary-500"
            } `}
            onClick={() => {
              toggleRamenAssit();
              console.log("btn cliccked");
            }}
          >
            <RamenLogoComponent className="-mr-1 h-6 w-6" />
            <div className="text-center text-r_color_base  font-light leading-5">
              Assist
            </div>
          </button>

          {/* Separator */}
          <div
            aria-hidden="true"
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5 ">
              <span className="sr-only">Open user menu</span>
              <img
                alt=""
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                className="h-8 w-8 rounded-full bg-gray-50"
              />
              <span className="hidden lg:flex lg:items-center">
                <span
                  aria-hidden="true"
                  className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                ></span>
              </span>
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-r_color_base py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {userNavigation.map((item) => (
                <MenuItem key={item.name}>
                  <div
                    onClick={() => {
                      if (item.name === "Sign out") {
                        handleLogout();
                      }
                    }}
                    className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                  >
                    {item.name}
                  </div>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
}
