"use client";

import { Field, Label, Switch } from "@headlessui/react";

export default function Toggle({
  heading,
  subheading,
  enabled,
  onToggleChange,
}) {
  return (
    <Field
      className="flex items-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Switch
        checked={enabled}
        onChange={(e) => {
          onToggleChange(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-r_color_gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-r_color_primary-600 focus:ring-offset-2 data-[checked]:bg-r_color_primary-600"
      >
        <span
          aria-hidden="true"
          className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-r_color_base shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
      </Switch>
      {heading && (
        <Label as="span" className="ml-3 text-sm">
          <span className="font-medium text-r_color_gray-900">{heading}</span>{" "}
          <span className="text-r_color_gray-500">{subheading}</span>
        </Label>
      )}
    </Field>
  );
}
