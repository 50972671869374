export default function Button({ label, onClick, customCss }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`rounded-md bg-r_color_primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-r_color_primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600 ${customCss}`}
    >
      {label}
    </button>
  );
}
