import { useEffect, useState } from "react";
import SelectMenu from "../../core-components/select-menu";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const tabs = [
  { name: "Overview", href: "#", current: true },
  { name: "Live", href: "#", current: false },
  { name: "Alerts", href: "#", current: false },
  { name: "Configuration", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CameraTrouserTabs({
  currentTab,
  setCurrentTab,
  selectedCamera,
  handleDurationChange,
  trouserDataDurationOptions,
  showTimeDropdown,
  trouserDataDuration,
}) {
  const [selectedDuration, setSelectedDuration] = useState({});

  useEffect(() => {
    setSelectedDuration(
      trouserDataDurationOptions.find(
        (item) => item.value === trouserDataDuration
      )
    );
  }, [trouserDataDuration, trouserDataDurationOptions]);
  const handleTabClick = (tab) => {
    if (tab.name === "Live" && selectedCamera.status === "Offline") {
      return;
    } else {
      setCurrentTab(tab.name);
    }
  };
  const handleSelectMenuChange = (duration) => {
    handleDurationChange(duration);
  };

  return (
    <div className="">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          value={currentTab}
          onChange={(e) =>
            handleTabClick(tabs.find((tab) => tab.name === e.target.value))
          }
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden  sm:flex justify-between ">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              onClick={(e) => {
                e.preventDefault();
                if (
                  tab.name === "Live" &&
                  selectedCamera.status === "Offline"
                ) {
                  return; // Prevent clicking on the "Live" tab if camera is offline
                }
                handleTabClick(tab);
              }}
              aria-current={currentTab.name === tab.name ? "page" : undefined}
              className={classNames(
                currentTab === tab.name
                  ? "bg-r_color_gray-900 text-r_color_base"
                  : "text-r_color_gray-300 hover:bg-r_color_gray-900",
                tab.name === "Live" && selectedCamera.status === "Offline"
                  ? "cursor-not-allowed text-r_color_gray-500 rounded-md px-3 py-2 text-sm font-medium"
                  : "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>

        {showTimeDropdown && (
          <div className="z-50 w-auto min-w-40">
            <SelectMenu
              customCss={"bg-transparent !text-r_color_base !ring-0"}
              label=""
              selected={selectedDuration}
              onChange={handleSelectMenuChange}
              options={trouserDataDurationOptions}
              placeholder={""}
              showAvatar={false}
              dropdownIcon={ChevronDownIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
}
