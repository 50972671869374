"use client";

import React, { useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utils/commonFunctions";

export default function SelectMenu({
  selected,
  onChange,
  label,
  options,
  placeholder,
  showAvatar,
  customCss,
  dropdownIcon: DropdownIcon,
  disabled,
}) {
  return (
    <div className="">
      <Listbox value={selected} onChange={onChange} disabled={disabled}>
        <Label
          className={classNames(
            "block text-sm font-medium leading-5 text-r_color_gray-700 text-left",
            disabled === true ? "!text-r_color_gray-300" : ""
          )}
        >
          {label}
        </Label>
        <div className="relative mt-1">
          <ListboxButton
            className={`relative w-full cursor-default rounded-md bg-r_color_base py-1.5 pl-3 pr-10 text-left text-r_color_gray-700 shadow-sm ring-1 ring-inset ring-r_color_gray-300 focus:outline-none focus:ring-2 focus:ring-r_color_primary-500 sm:text-sm sm:leading-6 ${customCss}
            ${disabled ? "opacity-50" : ""}
            `}
          >
            <span className="flex items-center">
              {selected && showAvatar && (
                <img
                  alt=""
                  src={selected?.avatar}
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
              )}
              <span
                className={`${
                  showAvatar === true ? "ml-3" : ""
                } block truncate`}
              >
                {selected?.name || placeholder}
              </span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2 ">
              {DropdownIcon ? (
                <DropdownIcon className="h-5 w-5 text-r_color_gray-400" />
              ) : (
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-r_color_gray-400"
                />
              )}
            </span>
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute  z-[90] mt-1  max-h-56 w-full overflow-auto rounded-md bg-r_color_base py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {options.map((option) => (
              <ListboxOption
                key={option.id}
                value={option}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-r_color_gray-900 data-[focus]:bg-r_color_primary-600 data-[focus]:text-r_color_base"
              >
                <div className="flex items-center">
                  {showAvatar === true && (
                    <img
                      alt=""
                      src={option.avatar}
                      className="h-5 w-5 flex-shrink-0 rounded-full"
                    />
                  )}
                  <span
                    className={`block truncate font-normal group-data-[selected]:font-semibold ml-2`}
                  >
                    {option.name}
                  </span>
                </div>

                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-r_color_primary-600 group-data-[focus]:text-r_color_base [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  );
}
