import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Minimize } from "../../assets/minimize.svg";
import { ReactComponent as Maximize } from "../../assets/maximize.svg";

export default function ChatbotNavbar({
  handleClose,
  toggleRamenAssitWindowSize,
  handleMinimize,
}) {
  return (
    <Disclosure as="nav" className="bg-r_color_secondary-600">
      <div className="mx-auto  px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between ">
          <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start  w-full">
            <div className="flex flex-shrink-0 items-center text-r_color_secondary-50  text-lg font-semibold  leading-7">
              Ramen Assist
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-x-3">
            <button
              type="button"
              onClick={handleMinimize}
              className="rounded-md  text-r_color_gray-100 hover:text-r_color_gray-300 focus:outline-none focus:ring-2 focus:ring-r_color_primary-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <Minimize aria-hidden="true" className="h-6 w-6" />
            </button>
            {/* <button
              type="button"
              onClick={toggleRamenAssitWindowSize}
              className="rounded-md  text-r_color_gray-100 hover:text-r_color_gray-300 focus:outline-none focus:ring-2 focus:ring-r_color_primary-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <Maximize aria-hidden="true" className="h-6 w-6 p-0.5" />
            </button> */}
            <button
              type="button"
              onClick={handleClose}
              className="rounded-md  text-r_color_gray-100 hover:text-r_color_gray-300 focus:outline-none focus:ring-2 focus:ring-r_color_primary-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </Disclosure>
  );
}
