import React from "react";
import HLSVideoPlayerWithSegmentEvent from "./VideoFeedv2";

export default function CameraFeed({
  selectedCamera,

  orgid,
  duration,
  refreshData,
}) {
  return (
    <div className=" h-full w-full -mt-5">
      <HLSVideoPlayerWithSegmentEvent
        showTimeScale={true}
        src={`/hls/${selectedCamera.id}/playlist.m3u8`}
        selectedCamera={selectedCamera}
        orgid={orgid}
        duration={duration}
        refreshData={refreshData}
      ></HLSVideoPlayerWithSegmentEvent>
    </div>
  );
}
