import { useEffect, useState } from "react";
import DropdownWithChevron from "../../core-components/dropdownwithChevron";
import { toast } from "react-toastify";
import { LocalizationKeys } from "../../../localizationStrings/en";
import {
  getAlertTypeIcon,
  getSeverityBgColor,
  getSeverityIcon,
  getSeverityTextColor,
} from "../../utils/commonFunctions";
import { Tooltip } from "react-tooltip";

export default function VideoAlertTrackerCard({
  orgid,
  viewAllGlobalAlerts,
  cameraEventList,
  loading,
}) {
  const [recentEventList, setRecentEventList] = useState([]);
  useEffect(() => {
    //take first 5 events from cameraEventList set setRecentEventList
    if (cameraEventList && cameraEventList.length > 0) {
      // Take the first 5 events from cameraEventList
      const recentEvents = cameraEventList.slice(0, 5);
      setRecentEventList(recentEvents);
    }
  }, [cameraEventList]);
  // Handle the case loading present
  const renderWidget = () => {
    if (loading) {
      return (
        <div className="  w-full px-4 py-3 bg-r_color_base rounded-md shadow flex-col justify-start items-center gap-3 inline-flex">
          <div className=" w-full">
            <div className="self-stretch h-5 justify-between items-center inline-flex  w-full">
              <div className="w-[142px] self-stretch bg-r_color_gray-200  animate-pulse" />
              <div className="w-[116px] self-stretch bg-r_color_gray-200  animate-pulse" />
            </div>
            <div className="max-h-56  overflow-scroll gap-2 flex flex-col">
              {Array.from({ length: 100 }).map((_, index) => (
                <div
                  key={index}
                  className="w-full   min-h-[52px] bg-r_color_gray-200  animate-pulse "
                />
              ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="overflow-hidden rounded-lg bg-r_color_base shadow">
        <div className="px-4 pt-4 pb-2.5 sm:px-4 flex justify-between items-center">
          <span> {LocalizationKeys.VIDEO_ALERT_TRACKER}</span>
          <span>
            <DropdownWithChevron
              options={[
                {
                  title: "Sort by Recent Alerts",
                  description: "",
                  current: false,
                },
              ]}
            />
          </span>
        </div>
        <div className="max-h-56  overflow-scroll">
          {recentEventList.length === 0 ? (
            <div className="bg-r_color_base p-4 flex flex-col rounded-md shadow-sm gap-2 ">
              <div className="flex justify-center py-6  ">
                <div className="text-center text-r_color_gray-200 de text-base font-bold  uppercase leading-7">
                  {LocalizationKeys.NODATA_TEXT}
                </div>
              </div>
            </div>
          ) : (
            <ul className=" px-4 py-4 sm:p-4 sm:py-0 divide-y divide-r_color_gray-100 ">
              {recentEventList.map((event, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between gap-x-4 py-4"
                >
                  <div className="flex min-w-0 gap-x-4 ">
                    <div
                      className={`h-12 w-12 flex-none rounded-full justify-center items-center flex ${getSeverityBgColor(
                        event.severity
                      )} ${getSeverityTextColor(event.severity)} `}
                    >
                      {getSeverityIcon(event.severity, " h-[18px] w-[18px] ")}
                    </div>
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 items-center  flex">
                        <span className=" font-medium text-sm leading-5 text-r_color_gray-900">
                          {event.camera_name}
                        </span>
                        <span className="mx-1">|</span>

                        <span className=" truncate flex-1 font-medium text-sm leading-5 text-r_color_gray-400 flex gap-2 w-full">
                          {event.region}
                        </span>
                      </p>
                      <p
                        className="mt-1 truncate text-xs leading-5 text-r_color_gray-500"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={event.message}
                      >
                        {formatTimeDifference(event.timestamp)} -{" "}
                        {event.message}{" "}
                      </p>
                    </div>
                  </div>
                  <div className=" flex">
                    <span className="mx-4">
                      {getAlertTypeIcon(event.event_type)}
                    </span>
                    {/* <div className="text-gray-500 text-sm font-medium ">
                      View
                    </div> */}
                  </div>
                </li>
              ))}
              <Tooltip id="my-tooltip" style={{ zIndex: 100 }} />
            </ul>
          )}
        </div>
        <div
          className={`px-4 pb-4 pt-3 sm:px-4 ${
            recentEventList.length === 0 ? "hidden" : ""
          }`}
        >
          <button
            className={`flex w-full items-center justify-center rounded-md bg-r_color_base px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 focus-visible:outline-offset-0
          ${loading ? "cursor-not-allowed" : ""}
          `}
            onClick={viewAllGlobalAlerts}
          >
            {loading ? "loading" : `View all `}
          </button>
        </div>
      </div>
    );
  };

  return renderWidget();
}

function formatTimeDifference(timestamp) {
  const secondsDiff = Math.floor(Date.now() / 1000) - timestamp;

  const days = Math.floor(secondsDiff / (3600 * 24));
  const hours = Math.floor((secondsDiff % (3600 * 24)) / 3600);
  const minutes = Math.floor((secondsDiff % 3600) / 60);
  const seconds = secondsDiff % 60;
  // console.log(secondsDiff, days, hours, minutes, seconds);
  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}
