import React, { useEffect, useState } from "react";
import CameraAlertList from "../../../gridlist";
import { toast } from "react-toastify";
import { formatDistanceToNow } from "date-fns";
import ApiEndpoints from "../../../utils/APIEndpoints";

export default function CameraOverviewAlertList({
  selectedCamera,
  orgid,
  alertList,
  isLoading,
}) {
  return (
    <CameraAlertList
      alertList={alertList}
      listlabel={"TOP ISSUES TO TROUBLESHOOT"}
      loading={isLoading}
    ></CameraAlertList>
  );
}
