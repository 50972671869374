"use client";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import CameraTrouserTabs from "./camera-touser-tabs";
import { useEffect, useState } from "react";
import CameraOverview from "./overview/camera-overview";
import CameraFeed from "../feed/camera-feed";
import CameraAlerts from "../../camera-alerts";
import CameraConfig from "../../camera-config";

import { ReactComponent as RamenLogoComponent } from "../../../assets/Ramen-Robot-Guy-Logo-Mark-White-Padding.svg";
import ApiEndpoints from "../../utils/APIEndpoints";
import { toast } from "react-toastify";
import RefreshTimerButton from "../../core-components/refreshTimerButton";

export default function CameraTrouser({
  open,
  onClose,
  selectedCamera,
  orgid,
  toggleRamenAssit,
  showRamenAIClient,
}) {
  const durationOptions = [
    { name: "Past 15 minutes", value: "15m", id: 1 },
    { name: "Past 1 hours", value: "1h", id: 2 },
    { name: "Past 24 hours", value: "24h", id: 3 },
    { name: "Past 7 days", value: "7d", id: 4 },
  ];
  const [currentTab, setCurrentTab] = useState("Overview");
  const [alertList, setAlertList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [trouserDataDuration, setTrouserDataDuration] = useState(
    durationOptions[1].value
  );

  const handleTabDurationChange = (duration) => {
    setTrouserDataDuration(duration.value);
  };
  const [trouserDataLastRefreshedTime, settrouserDataLastRefreshedTime] =
    useState(Date.now());

  const onTrouserTimerComplete = () => {
    settrouserDataLastRefreshedTime(Date.now());
  };

  useEffect(() => {
    const fetchCameraAlerts = async () => {
      try {
        const getCameraEventByCamIdUrl =
          ApiEndpoints.GET_CAMERAEVENTS_BY_CAMERAID(
            orgid,
            selectedCamera.id,
            trouserDataDuration
          );
        const response = await fetch(getCameraEventByCamIdUrl);
        const cameraAlert = await response.json();
        setAlertList(cameraAlert);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        toast.error("Failed to fetching  camera alert info ");
        console.error("Failed to fetching  camera alert info:", error);
      }
    };
    setAlertList([]);
    setIsLoading(true);
    fetchCameraAlerts();
  }, [
    selectedCamera,
    orgid,

    trouserDataLastRefreshedTime,
    trouserDataDuration,
  ]);

  useEffect(() => {
    const resetDuration = () => {
      setTrouserDataDuration(durationOptions[1].value);
      setCurrentTab("Overview");
    };
    if (open) {
      resetDuration();
    }
  }, [open]);

  const renderContent = () => {
    switch (currentTab) {
      case "Overview":
        return (
          <CameraOverview
            selectedCamera={selectedCamera}
            orgid={orgid}
            duration={trouserDataDuration}
            alertList={alertList}
            alertListLoading={isLoading}
            refreshData={trouserDataLastRefreshedTime}
          />
        );
      case "Live":
        return (
          <CameraFeed
            selectedCamera={selectedCamera}
            orgid={orgid}
            duration={"7d"}
            refreshData={trouserDataLastRefreshedTime}
          />
        );
      case "Alerts":
        return (
          <CameraAlerts
            selectedCamera={selectedCamera}
            orgid={orgid}
            duration={trouserDataDuration}
            alertList={alertList}
            alertListLoading={isLoading}
            durationOptions={durationOptions}
            handleDurationChange={handleTabDurationChange}
          />
        );
      case "Configuration":
        return (
          <CameraConfig
            selectedCamera={selectedCamera}
            orgid={orgid}
            closeCameraTrouser={onClose}
          />
        );

      default:
        return (
          <CameraOverview
            selectedCamera={selectedCamera}
            orgid={orgid}
            duration={trouserDataDuration}
          />
        );
    }
  };
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden flex flex-col bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-full h-full  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div
              className="  bg-r_color_gray-800 px-8 py-4 text-r_color_base "
              id="camera_trouser_header"
            >
              <div className="flex justify-between items-center border-b border-r_color_gray-600 mb-2">
                <span className="">{selectedCamera.name}</span>

                <span className="flex gap-2  py-2">
                  <RefreshTimerButton
                    onTimerComplete={onTrouserTimerComplete}
                  />

                  <button
                    type="button"
                    className={`inline-flex items-center gap-x-2 rounded-full px-3.5  text-sm font-semibold text-r_color_base shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                      showRamenAIClient
                        ? "focus-visible:outline-r_color_primary-600 hover:bg-r_color_primary-600 bg-r_color_primary-500"
                        : "ring-[2px] ring-r_color_primary-500"
                    } `}
                    onClick={() => {
                      toggleRamenAssit();
                    }}
                  >
                    <RamenLogoComponent className="-mr-1 h-6 w-6" />
                    <div className="text-center text-r_color_base  font-light leading-5">
                      Assist
                    </div>
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="rounded-md bg-r_color_gray-800 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </span>
              </div>
              <div className="-mx-2">
                <CameraTrouserTabs
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  selectedCamera={selectedCamera}
                  handleDurationChange={handleTabDurationChange}
                  trouserDataDurationOptions={durationOptions}
                  trouserDataDuration={trouserDataDuration}
                  showTimeDropdown={currentTab === "Overview"}
                />
              </div>
            </div>
            <div className=" px-8 py-5 flex flex-grow  w-full bg-r_color_gray-100   max-h-full overflow-auto ">
              {/* main content should load different component based on selected tab */}
              {renderContent()}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
