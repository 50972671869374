"use client";

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils/commonFunctions";

export default function ConfirmationModal({
  heading,
  subheading,
  cancelText,
  confirmationText,
  onConfirm,
  onCancel,
  open,
  setOpen,
  Icon,
  iconcss,
  iconbg,
  confimationButtonBg,
  writtenConfig = false,
  writtenConfigPassPharse,
}) {
  const [inputValue, setInputValue] = useState("");
  const [isMatching, setIsMatching] = useState(false);

  // Check if the input matches the passphrase
  useEffect(() => {
    if (writtenConfig && writtenConfigPassPharse) {
      setIsMatching(inputValue === writtenConfigPassPharse);
    }
  }, [inputValue, writtenConfigPassPharse, writtenConfig]);
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-r_color_gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-start">
              <div
                className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${iconbg}`}
              >
                <Icon className={iconcss ? iconcss : "h-6 w-6 text-red-600"} />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-r_color_gray-900"
                >
                  {heading}
                </DialogTitle>
                {writtenConfig === false && (
                  <div className="mt-2">
                    <p className="text-sm text-r_color_gray-500">
                      {subheading}
                    </p>
                  </div>
                )}

                {writtenConfig === true && (
                  <div className=" w-full">
                    <label
                      htmlFor="confirmationText"
                      className="block text-sm font-medium leading-6 text-r_color_gray-900"
                    >
                      {subheading}
                    </label>
                    <div className="mt-2">
                      <input
                        id="confirmationText"
                        name="confirmationText"
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder=""
                        className="block w-full rounded-md border-0 py-1.5 text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 placeholder:text-r_color_gray-400 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => {
                  if (onConfirm) {
                    onConfirm(); // Call confirmation handler
                  }
                  setOpen(false); // Close modal after confirmation
                }}
                disabled={writtenConfig && !isMatching}
                className={classNames(
                  "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto",
                  writtenConfig && !isMatching
                    ? "opacity-50  cursor-not-allowed"
                    : "",
                  confimationButtonBg
                )}
              >
                {confirmationText}
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => {
                  if (onCancel) {
                    onCancel(); // Call cancel handler
                  }
                  setOpen(false); // Close modal on cancel
                }}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 sm:mt-0 sm:w-auto"
              >
                {cancelText}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
