import React, { useEffect, useState } from "react";
import CameraOverviewCard from "./camera-overview-card-widget";

import CameraOverviewAlertList from "./camera-overview-alertlist";

import CameraOverviewGraph from "../../../camera-overview-graph";
import ApiEndpoints from "../../../utils/APIEndpoints";
import { toast } from "react-toastify";

export default function CameraOverview({
  selectedCamera,
  orgid,
  duration,
  alertList,
  alertListLoading,
  refreshData,
}) {
  const [cameraGraphData, setCameraGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchCameraGraphData = async () => {
      try {
        const url = ApiEndpoints.GET_REGION_STATS_BY_CAMERAID(
          orgid,
          selectedCamera.id,
          duration
        );
        const response = await fetch(url);
        const data = await response.json();

        setCameraGraphData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Failed to fetch camera region stats info");
        console.error("Failed to fetch camera alert info:", error);
      }
    };

    fetchCameraGraphData();
  }, [selectedCamera, orgid, duration, refreshData]);
  return (
    <div className="mx-auto flex-col lg:flex-row flex max-w-2xl gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none w-full">
      <div className=" max-h-cameraOverviewTrouserHeight overflow-auto ">
        <CameraOverviewCard
          selectedCamera={selectedCamera}
          orgid={orgid}
          duration={duration}
          refreshData={refreshData}
          cameraGraphData={cameraGraphData}
          alertList={alertList}
        ></CameraOverviewCard>
      </div>

      <div className=" flex-grow max-h-cameraOverviewTrouserHeight overflow-auto ">
        <CameraOverviewAlertList
          selectedCamera={selectedCamera}
          orgid={orgid}
          duration={duration}
          alertList={alertList}
          isLoading={alertListLoading}
        />
      </div>
      <div className=" flex-grow lg:max-w-[550px] max-h-cameraOverviewTrouserHeight overflow-auto">
        <CameraOverviewGraph
          selectedCamera={selectedCamera}
          orgid={orgid}
          duration={duration}
          alertList={alertList}
          refreshData={refreshData}
          cameraGraphData={cameraGraphData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
