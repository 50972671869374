import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import VisTimelineWithTemplate from "./customTimeline/TimelineChart";
import Toggle from "../../core-components/toggle";

const HLSVideoPlayerWithSegmentEvent = ({
  src,
  showTimeScale,
  selectedCamera,
  orgid,
  duration,
  refreshData,
}) => {
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null); // Add reference for the video container
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const [showEvents, setShowEvents] = useState(true);

  const extractBaseTimestampFromSegmentUrl = (url) => {
    const regex = /segment_(\d{8}_\d{6})_\d{5}\.ts/;
    const match = url.match(regex);

    if (match) {
      const timestamp = match[1];
      const hours = parseInt(timestamp.slice(9, 11), 10);
      const minutes = parseInt(timestamp.slice(11, 13), 10);
      const seconds = parseInt(timestamp.slice(13, 15), 10);

      return hours * 3600 + minutes * 60 + seconds;
    }
    return 0;
  };

  const handleSeek = (differenceInSeconds) => {
    console.log("video seek event", differenceInSeconds);
  };

  const handleShowEvents = () => {
    setShowEvents((prev) => !prev);
  };

  const calculateVideoContainerHeight = () => {
    const timelineHeight =
      document.getElementById("timeline-div")?.offsetHeight || 0;
    const headerHeight =
      document.getElementById("camera_trouser_header")?.offsetHeight || 0;
    const windowHeight = window.innerHeight;

    const calculatedHeight = windowHeight - timelineHeight - headerHeight;
    if (videoContainerRef.current) {
      videoContainerRef.current.style.height = `${calculatedHeight}px`;
    }
  };

  useEffect(() => {
    // Initial calculation
    setTimeout(() => {
      calculateVideoContainerHeight();
    }, 1000);

    // Recalculate height on window resize
    const handleResize = () => {
      calculateVideoContainerHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current; // Store video element reference
    let hls; // Declare the HLS instance

    const handleMetadataLoad = () => {
      // Ensure the video is muted before playing (if autoplay is required)
      video.muted = true;
      video.play().catch((error) => {
        console.error("Play request interrupted:", error);
      });
    };

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.muted = true; // Mute the video for autoplay
        video.play().catch((error) => {
          console.error("Autoplay failed:", error);
        });

        // Add the loadedmetadata event listener
        video.addEventListener("loadedmetadata", handleMetadataLoad);
      });

      hls.on(Hls.Events.FRAG_CHANGED, (event, data) => {
        const segmentUrl = data.frag.url;
        const segmentIndex = data.frag.sn;
        const segmentDuration = data.frag.duration;

        const baseTimestamp = extractBaseTimestampFromSegmentUrl(segmentUrl);
        const calculatedSeconds = Math.round(
          baseTimestamp + segmentIndex * segmentDuration
        );
        setCurrentSeconds(calculatedSeconds);
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = src;
      video.muted = true; // Ensure the video is muted
      video.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
      video.addEventListener("loadedmetadata", handleMetadataLoad);
    }

    return () => {
      // Clean up: Remove event listeners and destroy HLS instance
      if (hls) {
        hls.destroy();
      }
      if (video) {
        video.removeEventListener("loadedmetadata", handleMetadataLoad);
        video.pause();
      }
    };
  }, [src]);

  return (
    <div className="player-wrapper flex flex-col h-full w-full">
      {/* Video container with dynamic height */}
      <div ref={videoContainerRef} className="flex-1">
        <video ref={videoRef} autoPlay className="h-full w-full" />
      </div>

      {/* Timeline and controls */}
      <div className="flex-shrink-0 bg-r_color_base" id="timeline-div">
        {showTimeScale && (
          <div className="flex flex-col gap-4 pt-3 pb-[18px] px-6">
            <Toggle
              heading={"Show Stats"}
              enabled={showEvents}
              onToggleChange={handleShowEvents}
            />
            <VisTimelineWithTemplate
              selectedCamera={selectedCamera}
              orgid={orgid}
              duration={duration}
              refreshData={refreshData}
              showEvents={showEvents}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HLSVideoPlayerWithSegmentEvent;
