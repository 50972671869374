import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../utils/commonFunctions";
import { useState } from "react";

export default function Inputbox({
  label,
  id,
  type = "text",
  placeholder,
  name,
  value,
  onChange,
  disabled = false,
  helpText,
  hint,
  hintcss,
  helpTextcss,
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const isPassword = type === "password";

  return (
    <div className={classNames(disabled ? "opacity-50" : "")}>
      <div className="flex justify-between">
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-5 text-r_color_gray-700"
        >
          {label}
        </label>
        {hint && (
          <span className={`text-sm leading-6 text-gray-500 ${hintcss}`}>
            {hint}
          </span>
        )}
      </div>
      <div className="relative mt-1">
        <input
          id={id}
          name={name}
          type={isPassword && passwordVisible ? "text" : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          aria-describedby={`${id}-optional`}
          className="block w-full rounded-md border-0 py-1.5 text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 placeholder:text-r_color_gray-400 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
        />
        {isPassword && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
            {passwordVisible ? (
              <EyeIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
                onClick={() => setPasswordVisible(false)}
              />
            ) : (
              <EyeSlashIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
                onClick={() => setPasswordVisible(true)}
              />
            )}
          </div>
        )}
      </div>
      {helpText && (
        <p
          className={classNames(
            `mt-2 text-left text-sm text-gray-500 ${helpTextcss}`
          )}
        >
          {helpText}
        </p>
      )}
    </div>
  );
}
