"use client";

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import PolygonDrawing from "./add-region";
import { toast } from "react-toastify";
import ApiEndpoints from "../../../utils/APIEndpoints";

export default function AddRegionModal({
  handleClose,
  selectedCamera,
  setRegions,
  orgid,
  regions,
}) {
  const [open, setOpen] = useState(true);
  const [fetchedRegions, setFetchedRegions] = useState([]);
  useEffect(() => {
    setFetchedRegions(regions);
  }, [regions]);
  // Save regions to backend
  const handleSaveRegions = async () => {
    try {
      const url = ApiEndpoints.UPDATE_REGION_BY_CAMERAID(
        orgid,
        selectedCamera.id
      );
      const payload = {
        action: "UPDATE",
        regions: fetchedRegions.map((region) => {
          let regionpayload = {
            name: region.name,
            region: region.points.reduce((result, value, index, array) => {
              if (index % 2 === 0) {
                result.push([array[index], array[index + 1]]);
              }
              return result;
            }, []),
          };
          if (region.id) {
            regionpayload.id = region.id;
          }
          return regionpayload;
        }),
      };

      console.log(payload);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Regions saved successfully!");
      } else {
        toast.error("Failed to save regions");
      }
    } catch (error) {
      toast.error("An error occurred while saving regions");
      console.error("Error saving regions:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-[80] ">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-r_color_gray-500 bg-opacity-75  transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-[80] w-screen overflow-y-auto  ">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg    bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-7xl     sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-r_color_primary-50">
                <PlusIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-r_color_primary-600"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-r_color_gray-900"
                >
                  Add Region
                </DialogTitle>
                <div className="mt-2 overflow-auto  max-h-cameraOverviewTrouserHeight  ">
                  <PolygonDrawing
                    selectedCamera={selectedCamera}
                    orgid={orgid}
                    regions={fetchedRegions}
                    setRegions={setFetchedRegions}
                    imageUrl={`data:image/jpeg;base64,${selectedCamera.thumbnail}`}
                  ></PolygonDrawing>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={() => {
                  handleSaveRegions();
                }}
                className="inline-flex w-full justify-center rounded-md bg-r_color_primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600 sm:col-start-2"
              >
                Save
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => {
                  handleClose();
                }}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
