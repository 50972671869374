import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import StatsDisplay from "../../../camera-overview/drawer-stats";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export default function CameraOverviewCardInfoList({
  cameraGraphData,
  cameraInfo,
  alertList,
  duration,
  refreshData,
}) {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    // Checking if cameraGraphData or other dependencies are valid before updating the info
    if (cameraGraphData || alertList || cameraInfo) {
      setInfo([
        {
          heading: "Overall Processed " + (cameraInfo?.objects_detected || 0),
          subheadingvalue: cameraGraphData?.overall?.stats?.total || 0,
        },
        {
          heading: "Total Alerts",
          subheadingvalue: alertList?.length || 0,
        },
      ]);
    }
  }, [alertList, cameraGraphData, cameraInfo]);

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {info.map((item, index) => (
        <li key={index} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <div className="min-w-0 flex-auto">
              <div className="text-gray-500 text-sm font-medium ">
                {item.heading}
              </div>
              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                <div className="text-center text-r_color_secondary-600  text-xl font-extrabold ">
                  {item.subheadingvalue}
                </div>
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
