// colors.js
const colors = require("tailwindcss/colors");

module.exports = {
  r_color_gray: colors.gray,
  r_color_base: colors.white,

  r_color_primary: {
    50: "#D2EFFC",
    100: "#B3E5FA",
    200: "#8DD7F7",
    300: "#68CAF5",
    400: "#42BDF2",
    500: "#1CB0F0",
    600: "#1793C8",
    700: "#1375A0",
    800: "#0E5878",
    900: "#093B50",
    950: "#062330",
  },
  r_color_secondary: {
    50: "#CCF4F4",
    100: "#AAECEC",
    200: "#80E3E3",
    300: "#55DADA",
    400: "#2BD0D0",
    500: "#00C7C7",
    600: "#00A6A6",
    700: "#008585",
    800: "#006464",
    900: "#004242",
    950: "#002828",
  },
  r_navy: {
    50: "#CDD3D7",
    100: "#ABB6BD",
    200: "#81919B",
    300: "#586C7A",
    400: "#2E4859",
    500: "#042338",
    600: "#031D2F",
    700: "#031725",
    800: "#02121C",
    900: "#010C13",
    950: "#01070B",
  },
  r_lightgray: {
    50: "#EFEFEF",
    100: "#E4E4E4",
    200: "#D7D7D7",
    300: "#CACACA",
    400: "#BCBCBC",
    500: "#AFAFAF",
    600: "#929292",
    700: "#757575",
    800: "#585858",
    900: "#3A3A3A",
    950: "#232323",
  },
  r_offwhite: {
    50: "#FDFDFD",
    100: "#FBFBFB",
    200: "#F9F9F9",
    300: "#F7F7F7",
    400: "#F5F5F5",
    500: "#F3F3F3",
    600: "#CACACA",
    700: "#A2A2A2",
    800: "#7A7A7A",
    900: "#515151",
    950: "#313131",
  },
  r_green: {
    50: "#D0EFDB",
    100: "#B1E4C3",
    200: "#89D6A4",
    300: "#62C986",
    400: "#3BBB68",
    500: "#14AE4A",
    600: "#11913E",
    700: "#0D7431",
    800: "#0A5725",
    900: "#073A19",
    950: "#04230F",
  },
  r_yellow: {
    50: "#FFEFCC",
    100: "#FFE5AB",
    200: "#FFD880",
    300: "#FFCB56",
    400: "#FFBE2C",
    500: "#FFB102",
    600: "#D49302",
    700: "#AA7601",
    800: "#805901",
    900: "#553B01",
    950: "#332300",
  },
  r_orange: {
    50: "#FFE1CC",
    100: "#FFCEAA",
    200: "#FFB580",
    300: "#FF9C55",
    400: "#FF842B",
    500: "#FF6B00",
    600: "#D45900",
    700: "#AA4700",
    800: "#803600",
    900: "#552400",
    950: "#331500",
  },
  r_red: {
    50: "#F6D4D4",
    100: "#F0B8B7",
    200: "#E99492",
    300: "#E2716E",
    400: "#DA4E4A",
    500: "#D32A26",
    600: "#B02320",
    700: "#8D1C19",
    800: "#6A1513",
    900: "#460E0D",
    950: "#2A0808",
  },
};
