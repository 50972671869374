import React from "react";
import { CameraGraph, CameraGraphBar } from "../../../camera-overview-graph";
import ramenColors from "../../../summary-charts/ramen-colors";
import { LocalizationKeys } from "../../../../localizationStrings/en";

const ItemTemplate = ({
  item,
  cameraGraphData,
  selectedCamera,
  orgid,
  duration,
  refreshData,
}) => {
  return (
    <div className=" h-full">
      {/* Render your camera graph */}
      {cameraGraphData.overall.history.length > 0 ? (
        <CameraGraphBar
          chartData={cameraGraphData.overall.history}
          chartColor={ramenColors.r_color_secondary[100]}
          duration={duration}
          axisLeft={null}
          chartUsedInTimeline={true}
          margin={{ bottom: 0, top: 40 }}
          animate={false}
        />
      ) : (
        <div className="bg-r_color_base px-20 flex flex-col rounded-md shadow-sm gap-2  w-full h-full">
          <div className="flex justify-center py-6  ">
            <div className="text-center text-r_color_gray-200 de text-base font-bold  uppercase leading-7">
              {LocalizationKeys.NODATA_TEXT}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemTemplate;
