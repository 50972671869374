export default function StatsDisplay({ stats, labelcss, valuecss }) {
  return (
    <div className="grid grid-cols-2 gap-x-40 gap-y-6">
      {Object.entries(stats).map(([key, value]) => (
        <div key={key} className="flex   flex-col gap-1 ">
          <span
            className={`${
              labelcss
                ? labelcss
                : " text-r_color_gray-500 font-medium capitalize"
            } `}
          >
            {key.replace("_", " ")}
          </span>
          <span
            className={`${valuecss ? valuecss : "  text-r_color_gray-900"} `}
          >
            {typeof value === "number"
              ? Number.isInteger(value)
                ? value
                : value.toFixed(2)
              : value}
          </span>
        </div>
      ))}
    </div>
  );
}
