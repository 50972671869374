"use client";

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { LocalizationKeys } from "../localizationStrings/en";
import Inputbox from "./core-components/inputbox";
import SelectMenu from "./core-components/select-menu";

import { toast } from "react-toastify";
import ApiEndpoints from "./utils/APIEndpoints";
import { cameraModel, classNames } from "./utils/commonFunctions";

export default function AddCameraFlow({ handleClose, orgId, onCameraAdded }) {
  const [objectToDetechList, setObjectToDetectlist] = useState([]);
  useEffect(() => {
    const fetchObjectsToDetechList = async () => {
      try {
        const getcamera_url = ApiEndpoints.GET_SUPPORTED_MODELS(orgId);
        const response = await fetch(getcamera_url);
        const objectList = await response.json();
        const transformedRes = Object.keys(objectList).map((key, index) => {
          return {
            id: index,
            name: key,
          };
        });

        setObjectToDetectlist(transformedRes);
      } catch (error) {
        toast.error("Failed to fetch object detection list");
        console.error("Failed to fetch object detection list", error);
      }
    };
    fetchObjectsToDetechList();
  }, [orgId]);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    vendor: cameraModel[1],
    site_id: "default_site",
    objects_detected: "",
    ip: "",
    feed_info: {},
  });
  const [open, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("handle change", name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedCamera) => {
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        vendor: selectedCamera,
      };
      // console.log("Updated formData:", updatedFormData);
      return updatedFormData;
    });
  };
  const handleObjectDetechChange = (objectSelection) => {
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        objects_detected: objectSelection,
      };
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    let apiData;

    if (formData.vendor.name.toLowerCase() === "hanhwa") {
      apiData = {
        name: formData.name,
        description: formData.description,
        vendor: formData.vendor.name,
        objects_detected: formData.objects_detected.name,
        site_id: "Default site",
        feed_info: {
          rtsp_url: formData.rtsp_url,
          username: formData.username,
          password: formData.password,
        },
      };
    } else if (formData.vendor.name.toLowerCase() === "rhombus") {
      apiData = {
        name: formData.name,
        description: formData.description,
        vendor: formData.vendor.name,
        site_id: "Default Site",
        objects_detected: formData.objects_detected.name,
        feed_info: {
          camera_id: formData.vendorid,
        },
      };
    }

    const url = ApiEndpoints.ADD_CAMERA(orgId);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        toast.success("Camera Added Successfully");
        if (onCameraAdded) {
          onCameraAdded();
        }
        setOpen(false);
      } else {
        const errorData = await response.json();
        const errorString = JSON.stringify(errorData.errors);

        toast.error(errorString || "Error adding the camera");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("An unexpected error occurred");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={closeModal} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-r_color_gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className={classNames(
                "relative transform overflow-hidden rounded-lg bg-r_color_base px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full  sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 sm:max-w-xl "
                // formData.vendor?.name?.toLowerCase() === "hanhwa"
                //   ? "lg:max-w-3xl"
                //   : "sm:max-w-xl"
              )}
            >
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-r_color_primary-50">
                  <PlusIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-r_color_primary-600"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-r_color_gray-900"
                  >
                    {LocalizationKeys.Add_Camera}
                  </DialogTitle>
                  <div className="mt-5  min-h-[150px]">
                    <form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-4   ">
                        <Inputbox
                          label="Name"
                          placeholder="Enter Name"
                          id="name"
                          name="name"
                          value={formData.name || ""}
                          onChange={handleChange}
                        />
                        <SelectMenu
                          label="Vendor"
                          selected={formData.vendor}
                          onChange={handleSelectChange}
                          options={cameraModel}
                          placeholder={"Select Camera Model"}
                          showAvatar={true}
                        />
                        <div className="flex flex-col gap-y-4">
                          <Inputbox
                            label="Description"
                            placeholder="Enter Description"
                            id="description"
                            name="description"
                            value={formData.description || ""}
                            onChange={handleChange}
                          />
                          <SelectMenu
                            label="Objects to monitor"
                            selected={formData.objects_detected}
                            onChange={handleObjectDetechChange}
                            options={objectToDetechList}
                            placeholder={"Select Object Model"}
                            showAvatar={false}
                          />
                        </div>

                        <div
                          className={`flex flex-col gap-2 pl-4  ${
                            formData.vendor?.name?.toLowerCase() === "hanhwa"
                              ? "border-l"
                              : ""
                          }`}
                        >
                          {formData.vendor?.name?.toLowerCase() === "hanhwa" ? (
                            <>
                              <Inputbox
                                label="RTSP Url"
                                placeholder="Enter RTSP Url"
                                id="rtsp_url"
                                name="rtsp_url"
                                value={formData.rtsp_url || ""}
                                onChange={handleChange}
                              />
                              <Inputbox
                                label="Username"
                                placeholder="Enter Username"
                                id="username"
                                name="username" // Corrected this to match formData key
                                value={formData.username || ""}
                                onChange={handleChange}
                              />
                              <Inputbox
                                label="Password"
                                placeholder="Enter Password"
                                id="password"
                                type="password"
                                name="password" // Corrected this to match formData key
                                value={formData.password || ""}
                                onChange={handleChange}
                              />
                            </>
                          ) : (
                            <Inputbox
                              label="Vendor ID"
                              placeholder="Enter Vendor ID"
                              id="vendorid"
                              name="vendorid"
                              value={formData.vendorid || ""}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="Submit"
                  onClick={handleSubmit}
                  className="inline-flex w-full justify-center rounded-md bg-r_color_primary-500 px-3 py-2 text-sm font-semibold text-r_color_base shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  {LocalizationKeys.Add_Camera}
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={closeModal}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-r_color_base px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 sm:col-start-1 sm:mt-0"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
