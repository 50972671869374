import { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import {
  generateDefsAndPatterns,
  chartTheme,
  CustomTooltip,
  ChartLegend,
} from "../summary-charts/chart-theme-service";
import ramenColors from "../summary-charts/ramen-colors";
import { formatLargeNumberWithSuffix } from "../utils/commonFunctions";

export default function DonutChart({ stats }) {
  const [data, setData] = useState();
  useEffect(() => {
    const severityCounts = stats.reduce((acc, event) => {
      const severity = event.severity;
      acc[severity] = (acc[severity] || 0) + 1;
      return acc;
    }, {});

    const formattedData = Object.entries(severityCounts).map(
      ([key, value]) => ({
        id: key,
        label: key,
        value: value,
      })
    );

    setData(formattedData);
  }, [stats]);
  const { defs, fill, colors } = generateDefsAndPatterns(
    data?.map((d) => d.id)
  );

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          color: ramenColors.r_color_secondary["800"],
          textAlign: "center",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "26.64px",
        }}
      >
        {formatLargeNumberWithSuffix(total)}
      </text>
    );
  };
  return (
    <div className="w-full h-full  grid grid-cols-2">
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={0}
        activeOuterRadiusOffset={0}
        borderWidth={1}
        enableArcLinkLabels={false}
        borderColor={null}
        enableArcLabels={false}
        defs={defs}
        fill={fill}
        colors={colors}
        theme={chartTheme}
        tooltip={({ datum }) => <CustomTooltip datum={datum} />}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric,
        ]}
      />
      <div className=" flex justify-center items-center ">
        <ChartLegend data={data} colors={colors} defs={defs} />
      </div>
    </div>
  );
}
