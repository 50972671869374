const ApiEndpoints = {
  GET_CAMERA_EVENTS_PER_CAMERA: (org_id, selectedCameraId) =>
    `/org_id/${org_id}/get_camera_events/camera_id/${selectedCameraId}`,
  GET_CAMERA_STATS: (org_id, duration) =>
    `/org_id/${org_id}/camera_stats?duration=${duration}`,
  GET_CAMERA: (org_id) => `/org_id/${org_id}/camera`,
  ADD_CAMERA: (org_id) => `/org_id/${org_id}/camera`,
  GET_CAMERA_EVENTS_STATS: (org_id, duration) =>
    `/org_id/${org_id}/camera_events/stats?duration=${duration}`,

  GET_SYSTEM_INFO: (org_id, duration) =>
    `/org_id/${org_id}/get_system_info?duration=${duration}`,
  GET_CAMERA_EVENTS_TIMESERIES: (org_id, duration) =>
    `/org_id/${org_id}/camera_events/timeseries?duration=${duration}`,
  GET_CAMERA_EVENTS: (org_id, duration) =>
    `/org_id/${org_id}/camera_events?duration=${duration}`,
  GET_SUPPORTED_MODELS: (org_id) => `/org_id/${org_id}/get_supported_models`,
  GET_CAMERAEVENTS_BY_CAMERAID: (org_id, camera_id, duration) =>
    `/org_id/${org_id}/camera_events/camera_id/${camera_id}?duration=${duration}`,
  GET_CAMERA_BY_ID: (org_id, camera_id) =>
    `/org_id/${org_id}/camera/${camera_id}`,
  GET_REGION_BY_CAMERAID: (org_id, camera_id) =>
    `/org_id/${org_id}/camera_regions/${camera_id}`,
  UPDATE_REGION_BY_CAMERAID: (org_id, camera_id) =>
    `/org_id/${org_id}/camera_regions/${camera_id}`,

  GET_REGION_STATS_BY_CAMERAID_RAW: (org_id, camera_id, duration) =>
    `/org_id/${org_id}/camera_stats/${camera_id}/per_region?duration=${duration}`,
  GET_REGION_STATS_BY_CAMERAID: (org_id, camera_id, duration) =>
    `/org_id/${org_id}/camera_stats/${camera_id}/per_region?duration=${duration}`,
  GET_TIMELINE_STATS_BY_CAMERAID_REGION: (
    org_id,
    camera_id,
    start_time,
    end_time
  ) =>
    `/org_id/${org_id}/camera_stats/${camera_id}/per_region?region=overall&start_time=${start_time}&end_time=${end_time}`,

  GET_TIMELINE_STATS_BY_CAMERAID_REGION_TRENDLINE: (
    org_id,
    camera_id,
    duration
  ) =>
    `/org_id/${org_id}/camera_stats/${camera_id}/per_region?region=overall&duration=${duration}`,
  DOWNLOAD_STATS: (org_id, camera_id, duration) => {
    let url = `/org_id/${org_id}/camera_stats/download`;
    // Append camera_id if present
    if (camera_id) {
      url += `?camera_id=${camera_id}`;
    }
    // Append duration if present, and add it with a `&` if camera_id exists
    if (duration) {
      url += camera_id ? `&duration=${duration}` : `?duration=${duration}`;
    }
    return url;
  },
  DOWNLOAD_STATS_RAW: (org_id, camera_id, duration) => {
    let url = `/org_id/${org_id}/camera_stats/download/raw`;
    // Append camera_id if present
    if (camera_id) {
      url += `?camera_id=${camera_id}`;
    }
    // Append duration if present, and add it with a `&` if camera_id exists
    if (duration) {
      url += camera_id ? `&duration=${duration}` : `?duration=${duration}`;
    }
    return url;
  },
  DELETE_CAMERA_BY_ID: (org_id, camera_id) =>
    `/org_id/${org_id}/camera/${camera_id}`,
};

export default ApiEndpoints;
