import React, { useEffect, useState } from "react";
import PageHeader from "./layout-components/masterLayout/pageheader";
import { LocalizationKeys } from "../localizationStrings/en";
import FilterPanel from "./core-components/filterpanel";
import Table from "./core-components/table_stickyTop";
import Badge from "./core-components/badge";
import TableBordered from "./core-components/table_bordered";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDistanceToNow } from "date-fns";
import { toast } from "react-toastify";
import {
  getAlertTypeIcon,
  getSeverityBgColor,
  getSeverityIcon,
  getSeverityTextColor,
} from "./utils/commonFunctions";

export default function CameraAlerts({
  selectedCamera,
  orgid,
  alertList,
  alertListLoading,
  durationOptions,
  duration,
  handleDurationChange,
}) {
  const [appliedGlobalSearch, setAppliedGlobalSearch] = useState("");
  const handleGlobalSearch = (value) => {
    setAppliedGlobalSearch(value);
  };

  // Create a column helper (no type parameter needed in JS)
  const columnHelper = createColumnHelper();
  const clickHandler = (label) => {
    console.log("hello click handler", label);
  };
  // Define columns

  const columns = [
    columnHelper.accessor("region", {
      header: "Region",
      size: 150,
      cell: (info) => {
        const value = info.renderValue();
        return <div className="truncate">{value ? value : "-"}</div>;
      },
    }),
    columnHelper.accessor("event_type", {
      header: "Alert Type",
      cell: (info) => (
        <div className=" flex gap-x-2.5 ">
          <span>{getAlertTypeIcon(info.renderValue())}</span>
          <div className="truncate"> {info.renderValue()}</div>
        </div>
      ),
      size: 298,
    }),
    columnHelper.accessor("severity", {
      header: () => "severity",
      cell: (info) => (
        <Badge
          label={info.renderValue()}
          customIcon={getSeverityIcon(info.renderValue())}
          iconPosition="left"
          backgroundcolor={getSeverityBgColor(info.renderValue())}
          textcolor={getSeverityTextColor(info.renderValue())}
        ></Badge>
      ),
      size: 130,
    }),
    columnHelper.accessor("message", {
      header: () => <span>Alert Info</span>,
      cell: (info) => <div className="truncate ">{info.renderValue()}</div>,
      size: 500,
    }),

    columnHelper.accessor("timestamp", {
      header: "Generated At",
      size: 350,
      cell: (info) => {
        const timestamp = parseInt(info.renderValue(), 10); // Parse the timestamp

        if (!isNaN(timestamp) && timestamp > 0) {
          return formatDistanceToNow(new Date(timestamp * 1000), {
            addSuffix: true,
          });
        } else {
          return "Invalid timestamp";
        }
      },
    }),
  ];

  return (
    <div className=" w-full flex-col flex gap-4">
      <div className="flex-col flex gap-2">
        <PageHeader
          heading={LocalizationKeys.CAMERA_ALERT_HEADING}
          subheading={LocalizationKeys.CAMER_ALERT_SUBHEADING}
          showTimerBtn={false}
        ></PageHeader>
        <FilterPanel
          globalSearchHandler={handleGlobalSearch}
          durationOptions={durationOptions}
          duration={duration}
          handleDurationChange={handleDurationChange}
        ></FilterPanel>
      </div>
      <div className="w-full flex-1 ">
        <TableBordered
          dataset={alertList}
          columns={columns}
          appliedGlobalSearch={appliedGlobalSearch}
          isloading={alertListLoading}
        />
      </div>
    </div>
  );
}
