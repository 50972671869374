import React, { useState } from 'react';

const ChatBotInputBox = ({onSubmit}) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted');
    console.log('Input Value:', inputValue);
    onSubmit(inputValue);
    setInputValue('')

  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="px-2 py-2 flex justify-center items-center">
        <div className="w-full">
          <label htmlFor="chat-inputbox" className="sr-only">
            Email
          </label>
          <input
            id="chat-inputbox"
            name="chat-inputbox"
            type="text"
            autoComplete="disabled"
            placeholder="Message Ramen AI Bot"
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
            value={inputValue}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-r_color_primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-r_color_primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600 sm:ml-3 sm:mt-0 sm:w-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
        </button>
      </div>
    </form>
  );
};

export default ChatBotInputBox;
