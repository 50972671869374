import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { ResponsivePie } from "@nivo/pie";
import ramenColors from "../summary-charts/ramen-colors";
import { ReactComponent as TimerPlay } from "../../assets/table-timer-play.svg";
import { ReactComponent as TimerPause } from "../../assets/table-timer-pause.svg";

export default function RefreshTimerButton({ onTimerComplete }) {
  const options = [
    { id: 1, label: "Refresh Now", duration: 1 },
    { id: 2, label: "30 Seconds", duration: 30 },
    { id: 3, label: "60 Seconds", duration: 60 },
  ];

  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(
    DateTime.now().toFormat("h:mm a") // AM/PM format
  );
  const [selectedOption, setSelectedOption] = useState(options[2]); // Default to 60 seconds
  const [progress, setProgress] = useState(0); // Progress for pie chart
  const [isPlaying, setIsplaying] = useState(true); // is playing  state

  const togglePlaying = () => {
    setIsplaying((prev) => !prev);
  };

  useEffect(() => {
    let intervalId;
    const startProgressAnimation = () => {
      if (selectedOption.duration !== 1) {
        intervalId = setInterval(() => {
          setProgress((prev) => {
            if (prev >= 100) {
              setLastUpdatedTimestamp(
                DateTime.now().toFormat("h:mm a") // AM/PM format
              );
              if (onTimerComplete) {
                onTimerComplete(); // Call the parent function when progress reaches 100%
              }

              return 0;
            }
            return prev + 100 / selectedOption.duration;
          });
        }, 1000);
      } else {
        // For "Refresh Now", refresh timestamp and set progress to full immediately
        setLastUpdatedTimestamp(
          DateTime.now().toFormat("h:mm a") // AM/PM format
        );
        setProgress(0); // Full progress for Refresh Now
        setSelectedOption(options[2]);
        if (onTimerComplete) {
          onTimerComplete(); // Call the parent function when refresh now is clicked
        }
      }
    };
    // Clear any existing interval
    if (intervalId) {
      clearInterval(intervalId);
    }

    // Only start interval if playing
    if (isPlaying) {
      setProgress(0);
      startProgressAnimation();
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    // Cleanup the interval on unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [selectedOption, isPlaying]);

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className={`relative pr-0.5 py-[6px] inline-flex items-center rounded-l-md  bg-r_color_primary-100 text-r_color_primary-700  px-3  text-sm font-semibold    focus:border-r_color_primary-500 focus:ring-r_color_primary-500  focus:z-10
          ${
            isPlaying
              ? "!text-r_color_primary-600 !bg-r_color_primary-50"
              : "!text-r_color_gray-600 !bg-r_color_gray-200"
          }
          `}
      >
        <div className="flex gap-x-2 items-center">
          <span>{lastUpdatedTimestamp}</span>
          <ReloadingPieChart
            progress={progress}
            isPlaying={isPlaying}
            togglePlaying={togglePlaying}
            setIsplaying={setIsplaying}
          />
        </div>
      </button>
      <Menu as="div" className="relative -ml-px block ">
        <MenuButton
          className={`relative inline-flex items-center rounded-r-md bg-r_color_primary-100 text-r_color_primary-700  px-2 py-[6px] focus:border-r_color_primary-500 focus:ring-r_color_primary-500 focus:z-10
          
             ${
               isPlaying
                 ? "!text-r_color_primary-600 !bg-r_color_primary-50"
                 : "!text-r_color_gray-600 !bg-r_color_gray-200"
             }
          `}
        >
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-6 w-6" />
        </MenuButton>
        <MenuItems
          transition
          className="absolute right-0 z-50  mt-2  w-36  origin-top-right rounded-md bg-r_color_base shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
        >
          <div className="py-1">
            {options.map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => {
                  setSelectedOption(option);
                  setIsplaying(true);
                }}
              >
                <div className="block px-4 py-2 text-sm text-r_color_gray-700 cursor-pointer  data-[focus]:bg-r_color_gray-100 data-[focus]:text-r_color_gray-900">
                  {option.label}
                </div>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}

const ReloadingPieChart = ({ progress, isPlaying, togglePlaying }) => {
  const data = [
    {
      id: "blankTrack",
      label: "blankTrack",
      value: progress,

      color: isPlaying
        ? ramenColors.r_color_primary[600]
        : ramenColors.r_color_gray[400],
    },
    {
      id: "coloredTrack",
      label: "coloredTrack",
      value: 100 - progress,
      color: isPlaying
        ? ramenColors.r_color_primary[50]
        : ramenColors.r_color_gray[200],
    },
  ];

  return (
    <>
      <div className="relative h-6 w-6">
        <span className=" h-full  w-full   relative flex justify-center items-center">
          <ResponsivePie
            data={data}
            margin={{}}
            innerRadius={0.75}
            padAngle={0}
            cornerRadius={0}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            activeOuterRadiusOffset={0}
            arcLabelsSkipAngle={10}
            isInteractive={false}
            legends={[]}
            colors={({ data }) => data.color}
          />
        </span>
        {isPlaying ? (
          <TimerPlay
            className="absolute  h-5 w-5 top-0.5 left-0.5 "
            onClick={togglePlaying}
          />
        ) : (
          <TimerPause
            className="absolute  h-5 w-5 top-0.5 left-0.5 "
            onClick={togglePlaying}
          />
        )}
      </div>
    </>
  );
};
