("use client");

import { useState } from "react";
import DropdownWithChevron from "../../core-components/dropdownwithChevron";

const regionDetails = [{ id: 1, name: "All Regions" }];

export default function MainContainerDropdown() {
  const [selected, setSelected] = useState(regionDetails[0].id);

  const handleChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setSelected(selectedId);
  };

  return (
    <div>
      <DropdownWithChevron
        options={[
          {
            title: "All Regions",
            description: "",
            current: true,
          },
        ]}
      />
    </div>
  );
}
