import { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function RamenComboBox({
  label,
  options,
  placeholder,
  onChange,
  value,
  disabled,
  openMenuUpside,
}) {
  const [query, setQuery] = useState("");
  const [item, setItem] = useState(value);

  const filteredItems =
    query === ""
      ? options
      : options.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });

  function handleOptionChange(newItem) {
    setItem(newItem);
    onChange(newItem);
  }

  useEffect(() => {
    // console.log("line 31", placeholder, value, options);
    setItem(value);
  }, [options]);

  // useEffect(() => {
  //   console.log("line 35", placeholder, value);
  // }, [value]);

  return (
    <div className=" w-full">
      <Combobox as="div" value={item} onChange={handleOptionChange}>
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 ">
          {label}
        </Combobox.Label>
        <div className="relative mt-2 ">
          <Combobox.Input
            className="h-11 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item) => item?.label}
            placeholder={placeholder ? placeholder : "Choose desired option..."}
          />
          <Combobox.Button
            className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none ${
              disabled ? "focus:ring-2 focus:ring-gray-600" : ""
            }`}
            onClick={(e) => {
              if (disabled) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          >
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredItems.length > 0 && (
            <Combobox.Options
              className={`absolute z-10 mt-1 max-h-[256px] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                openMenuUpside ? "bottom-full mb-1" : ""
              }`}
            >
              {filteredItems.map((item, index) => (
                <Combobox.Option
                  key={`${item.value}-${index}`}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {item.label}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
}
