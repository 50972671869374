const data = [
  {
    alertname: "Alert1",
    severity: "Critical",
    alertMessage: "Lorem ipsum dolor ist",
    region: "Belt1",
    timeGeneratedAt: "Apr 23, 2024, 12:11:40 PM",
  },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Table({ columns, data }) {
  return (
    <div className="px-4 sm:px-6 lg:px-8 debug w-ful ">
      <div className="sm:flex sm:items-center"></div>
      {/* <div className="mt-8 flow-root "> */}
      <div className=" flow-root ">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0 debug">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={column.key}
                      scope="col"
                      className={`sticky -top-6 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter ${
                        index === 0 ? "sm:pl-6 lg:pl-8 py-3.5 pl-4 pr-3 " : ""
                      }`}
                    >
                      {column.label} {index}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.alertname}>
                    {columns.map((column, index) => (
                      <td
                        key={column.key}
                        className={classNames(
                          index === 0
                            ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                            : "whitespace-nowrap px-3 py-4 text-sm text-gray-500  border-b border-gray-200 "
                        )}
                      >
                        {item[column.key]} {index}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
