import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar"; // Import the bar chart component

import { ReactComponent as TrendUpIcon } from "../assets/trendup.svg";
import { ReactComponent as TrendDownIcon } from "../assets/trenddown.svg";
import { useMemo } from "react";

import {
  getAxisLeftTickValues,
  formatLargeNumberWithSuffix,
  hasNonZeroData,
} from "./utils/commonFunctions";
import ramenColors from "./summary-charts/ramen-colors";
import { useTheme, DotsItem } from "@nivo/core";
import { toast } from "react-toastify";

import CameraDrawer from "./camera-overview/cameradrawer";
import { chartTheme } from "./summary-charts/chart-theme-service";
import Badge from "./core-components/badge";
import { LocalizationKeys } from "../localizationStrings/en";
import { DateTime } from "luxon";
import ApiEndpoints from "./utils/APIEndpoints";
const calculateTickValues = (domain, count) => {
  const [min, max] = domain;
  // Edge case: if min and max are the same, return an array with the same value repeated
  if (min === max || count === 1) {
    return Array(count).fill(min);
  }
  const step = (max - min) / (count - 1);
  const ticks = [];
  for (let i = 0; i < count; i++) {
    ticks.push(min + i * step);
  }
  // Ensure the max value is included as the last tick
  ticks[ticks.length - 1] = max;
  // Validate tick values to ensure no invalid values
  return ticks.map((value) => (!isFinite(value) || isNaN(value) ? 0 : value));
};

// Utility function to measure the width of a text string
const getTextWidth = (text, font = "12px Inter var, sans-serif") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
};

export const CameraGraph = ({
  chartData,
  chartColor,
  duration,
  chartUsedInTimeline = false,
}) => {
  const [chartConfigs, setChartConfigs] = useState([]);
  const [axisLeftTickValues, setAxisLeftTickValues] = useState([]);

  const [maxLeftAxisValueWidth, setMaxLeftAxisValueWidth] = useState(0);

  const generateTickValues = (duration) => {
    let tickInterval = "";

    // Extract the number and unit from the duration (e.g., 24h, 15m, 1h)
    const number = parseInt(duration, 10);
    const unit = duration.replace(/[0-9]/g, "").toLowerCase();
    if (unit === "h" && number === 24) {
      tickInterval = "every 4 hour";
    } else if (unit === "m" && number === 15) {
      tickInterval = "every 3 minute";
    } else if (unit === "h" && number === 1) {
      tickInterval = "every 15 minutes";
    } else if (unit === "m") {
      tickInterval = `every ${number} minute${number > 1 ? "s" : ""}`;
    } else if (unit === "h") {
      tickInterval = `every ${number} hour${number > 1 ? "s" : ""}`;
    } else if (unit === "d" && number === 7) {
      tickInterval = "every 12 hour";
    } else {
      tickInterval = "every 1 hour"; // Default to 1 hour if no match
    }
    return tickInterval;
  };
  const [tickInterval, setTickInterval] = useState();
  // Memoize chart data transformation
  const transformedData = useMemo(() => {
    return [
      {
        id: "successful_handover_" + Date.now(), // Ensure unique id if there are multiple datasets
        data: chartData.map((item) => ({
          x: new Date(item.time_window * 1000),
          y: item.total_object_count,
        })),
      },
    ];
  }, [chartData]);

  // Memoize axis left tick values and max value width
  const memoizedTickValues = useMemo(() => {
    const yValues = chartData.map((item) => item.total_object_count);
    const maxY = Math.max(...yValues);
    const tickValues = calculateTickValues([0, maxY], 4).map((value) =>
      Math.round(value)
    );
    const formattedMaxValue = formatLargeNumberWithSuffix(
      Math.max(...tickValues)
    );
    const width = getTextWidth(formattedMaxValue, "12px Inter var, sans-serif");
    return { tickValues, width };
  }, [chartData]);

  useEffect(() => {
    setAxisLeftTickValues(memoizedTickValues.tickValues);
    setMaxLeftAxisValueWidth(memoizedTickValues.width);
  }, [memoizedTickValues]);

  useEffect(() => {
    const interval = generateTickValues(duration);
    setTickInterval(interval);
  }, [duration]);

  return (
    <div className="w-full h-full overflow-visible flex-col justify-start items-center gap-px inline-flex z-10">
      <ResponsiveLine
        data={transformedData}
        margin={{
          top: chartUsedInTimeline ? 40 : 8,
          bottom: chartUsedInTimeline ? 0 : 30,
          left: chartUsedInTimeline ? 0 : maxLeftAxisValueWidth + 8,
          right: chartUsedInTimeline ? 0 : 24,
        }}
        theme={chartTheme}
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
        }}
        curve="linear"
        axisTop={null}
        axisRight={null}
        xScale={{
          type: "time",
          useUTC: false,
          precision: "minute",
        }}
        axisBottom={
          chartUsedInTimeline
            ? null
            : {
                format: (date) => DateTime.fromJSDate(date).toFormat("HH:mm"),
                tickValues: tickInterval,
                tickTextSize: 10,
                tickSize: 0,
                legendPosition: "middle",
                legendOffset: 20,
              }
        }
        axisLeft={{
          tickValues: axisLeftTickValues,
          tickSize: 0,
          format: (e) => formatLargeNumberWithSuffix(e),
        }}
        colors={[chartColor]}
        gridYValues={axisLeftTickValues}
        pointBorderWidth={5}
        pointBorderColor={{ from: "serieColor" }}
        maxValue={Math.max(...axisLeftTickValues)}
        pointSize={4.5}
        pointLabel={"y"}
        areaOpacity={0.3}
        enableGridX={false}
        enableGridY={true}
        enableArea={true}
        enableTouchCrosshair={true}
        useMesh={true}
        enablePoints={false}
        animate={false}
        // tooltip={(e) => (
        //   <CamerGraphTooltip
        //     e={e}
        //     axisLeftLegend={"Count"}
        //     chartUsedInTimeline={chartUsedInTimeline}
        //   />
        // )}
        // tooltip={(e) => (
        //   <CamerGraphTooltip
        //     e={e}
        //     axisLeftLegend={"Count"}
        //     chartUsedInTimeline={chartUsedInTimeline}
        //   />
        // )}
        layers={[
          "grid",
          "axes",
          "areas",
          "lines",
          "crosshair",
          "slices",
          "mesh",
          "legends",
          chartUsedInTimeline ? LastPoint : null,
        ]}
      />
    </div>
  );
};
// Function to format the data for Nivo
const formatDataForBelt = (beltData) => {
  return beltData.map((item) => ({
    time_window: DateTime.fromSeconds(item.time_window).toFormat(
      "yyyy-MM-dd HH:mm"
    ),
    total_object_count: item.total_object_count,
  }));
};

export const CameraGraphBar = ({
  chartData,
  chartColor,
  duration,
  bucketUnit,
  chartUsedInTimeline = false,
  animate = true,
  tooltipStack = false,
}) => {
  const [axisLeftTickValues, setAxisLeftTickValues] = useState([]);
  const [maxLeftAxisValueWidth, setMaxLeftAxisValueWidth] = useState(0);

  useEffect(() => {
    const yValues = chartData.map((item) => item.total_object_count);
    const maxY = Math.max(...yValues);
    const tickValues = calculateTickValues([0, maxY], 4).map((value) =>
      Math.round(value)
    );
    const formattedMaxValue = formatLargeNumberWithSuffix(
      Math.max(...tickValues)
    );
    const width = getTextWidth(formattedMaxValue, "12px Inter var, sans-serif");
    setAxisLeftTickValues(tickValues);
    setMaxLeftAxisValueWidth(width);
  }, [chartData]);

  const data = formatDataForBelt(chartData);
  const getAxisBottomFormat = () => {
    if (bucketUnit === "minute") {
      // For shorter durations like 15 minutes or 1 hour, display time in 24-hour format
      return (value) =>
        DateTime.fromFormat(value, "yyyy-MM-dd HH:mm").toFormat("HH:mm");
    }
    if (bucketUnit === "hour") {
      if (duration === "24h") {
        // For 24 hours, show just the hour in 24-hour format
        return (value) =>
          DateTime.fromFormat(value, "yyyy-MM-dd HH:mm").toFormat("HH:mm");
      }
      if (duration === "7d") {
        // For 7 days, show date and time in 24-hour format
        return (value) =>
          DateTime.fromFormat(value, "yyyy-MM-dd HH:mm").toFormat(
            "MM/dd HH:mm"
          );
      }
    }
    // Default to 24-hour time for other cases
    return (value) =>
      DateTime.fromFormat(value, "yyyy-MM-dd HH:mm").toFormat("HH:mm");
  };

  return (
    <div className={` w-full ${chartUsedInTimeline ? "h-full" : "h-40"}`}>
      <ResponsiveBar
        data={data}
        keys={["total_object_count"]}
        indexBy="time_window"
        margin={{
          top: 10,
          bottom: chartUsedInTimeline ? 2 : duration === "7d" ? 60 : 40,
          left: chartUsedInTimeline ? 0 : maxLeftAxisValueWidth + 16,
        }}
        padding={0.3}
        colors={chartColor}
        axisBottom={
          chartUsedInTimeline === false
            ? {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legendPosition: "middle",
                format: getAxisBottomFormat(), // Apply dynamic format here
              }
            : null
        }
        enableLabel={false}
        gridYValues={axisLeftTickValues}
        enableGridY={chartUsedInTimeline === true ? false : true}
        axisLeft={{
          tickValues: axisLeftTickValues,
          format: (e) => formatLargeNumberWithSuffix(e),
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={animate}
        motionStiffness={90}
        motionDamping={15}
        tooltip={(e) => (
          <CamerGraphTooltip
            e={e}
            axisLeftLegend={"Count"}
            tooltipStack={tooltipStack}
          />
        )}
      />
    </div>
  );
};

function LastPoint({ points, ...props }) {
  const theme = useTheme();
  const shownPoints = points.slice(-1);

  return (
    <g>
      {shownPoints.map((point) => (
        <DotsItem
          key={point.id}
          x={point.x}
          y={point.y}
          datum={point.data}
          symbol={props.pointSymbol}
          size={props.pointSize}
          color={point.color}
          borderWidth={props.pointBorderWidth}
          borderColor={point.borderColor}
          label={point.label}
          labelYOffset={props.pointLabelYOffset}
          theme={theme}
        />
      ))}
    </g>
  );
}

export function NetworkComponent() {
  const hourData = Array.from({ length: 24 }, () =>
    Math.floor(Math.random() * 101)
  );

  return (
    <div className="">
      <div className="text-r_color_gray-400 text-sm font-medium  leading-tight">
        Network
      </div>
      <div className="self-stretch justify-between w-full items-start inline-flex ">
        {hourData.map((value, index) => (
          <div
            key={index}
            className={`w-3 h-3 opacity-${Math.min(value, 100)} bg-[#14ae4a]`}
          />
        ))}
      </div>
    </div>
  );
}

export default function CameraOverviewGraph({
  selectedCamera,
  orgid,
  duration,
  alertList,
  refreshData,
  isLoading,
  cameraGraphData,
}) {
  const [showCameraDrawer, setShowCameraDrawer] = useState(false);
  const [cameraDrawerMeta, setSameraDrawerMeta] = useState({});
  const [selectedRegion, setSelectedRegion] = useState({});
  function handleDrawerClose() {
    setShowCameraDrawer(false);
    setSameraDrawerMeta({});
  }

  return (
    <div className="flex flex-col h-full overflow-y-auto w-full">
      <div className="flex-1  flex  flex-col gap-3  ">
        {isLoading ? (
          <SideDrawerLoader />
        ) : cameraGraphData && Object.keys(cameraGraphData).length > 0 ? (
          <CameraGraphList
            cameraGraphData={cameraGraphData}
            setSelectedRegion={setSelectedRegion}
            setShowCameraDrawer={setShowCameraDrawer}
            setSameraDrawerMeta={setSameraDrawerMeta}
            duration={duration}
          />
        ) : (
          <div className="bg-r_color_base p-4 flex flex-col rounded-md shadow-sm gap-2 ">
            <div className="flex justify-center py-6  ">
              <div className="text-center text-r_color_gray-200 text-base font-bold  uppercase leading-7">
                {LocalizationKeys.NODATA_TEXT}
              </div>
            </div>
          </div>
        )}
      </div>
      {showCameraDrawer && (
        <CameraDrawer
          handleDrawerClose={handleDrawerClose}
          selectedRegion={selectedRegion}
          alertList={alertList}
          duration={duration}
          cameraDrawerMeta={cameraDrawerMeta}
        ></CameraDrawer>
      )}
    </div>
  );
}

export function CameraGraphCard({
  regionKey,
  cameraData,
  setSelectedRegion,
  setShowCameraDrawer,
  duration,
  setSameraDrawerMeta,
}) {
  // Ensure there is enough data to calculate the difference
  const history = cameraData.history || [];
  let entryDifference = 0;

  if (history.length >= 2) {
    const lastEntry = history[history.length - 1].total_object_count;
    const secondLastEntry = history[history.length - 2].total_object_count;
    entryDifference = lastEntry - secondLastEntry;
  }
  const TrendIcon = entryDifference >= 0 ? <TrendUpIcon /> : <TrendDownIcon />;
  const TrendBgColor = entryDifference >= 0 ? "bg-r_green-50" : "bg-r_red-50";
  const TrendTextColor =
    entryDifference >= 0 ? "text-r_green-600" : "text-r_red-600";
  const bucket_unit = cameraData.bucket_unit;
  return (
    <div className="relative p-4 bg-r_color_base flex flex-col gap-3   ">
      <div className="top-6 bg-r_color_base flex gap-3 items-center text-gray-500  text-sm font-medium leading-tight">
        <span>
          {`Unit count on ${regionKey}`}

          <span className="ml-2 text-xs  text-gray-400 font-normal">
            per {bucket_unit}
          </span>
        </span>
        <span>
          <Badge
            backgroundcolor={TrendBgColor}
            textcolor={TrendTextColor}
            label={Math.abs(entryDifference)}
            customIcon={TrendIcon}
          ></Badge>
        </span>
      </div>
      <div className="flex h-40">
        <CameraGraphBar
          chartData={cameraData.history}
          chartColor={ramenColors.r_color_secondary[500]}
          duration={duration}
          bucketUnit={cameraData.bucket_unit}
          tooltipStack={true}
        />
      </div>

      <div className=" justify-start items-start gap-2.5 inline-flex ">
        <div
          onClick={() => {
            setSelectedRegion({
              name: regionKey,
              cameraData: cameraData,
            });
            setSameraDrawerMeta({
              regionKey,
              cameraData,
              bucket_unit,
              TrendIcon,
              TrendBgColor,
              TrendTextColor,
              entryDifference,
            });

            setShowCameraDrawer(true);
          }}
          className="text-r_color_primary-500 text-sm font-semibold cursor-pointer leading-tight"
        >
          View depth stats
        </div>
      </div>
    </div>
  );
}

function CameraGraphList({
  cameraGraphData,
  setSelectedRegion,
  setShowCameraDrawer,
  duration,
  setSameraDrawerMeta,
}) {
  // Check if the overall data has valid history and stats
  const hasOverallData =
    cameraGraphData.overall.history.length > 0 &&
    Object.keys(cameraGraphData.overall.stats).length > 0 &&
    hasNonZeroData(cameraGraphData.overall.history);

  // Check if any region has valid data (excluding "overall")
  const regionsWithData = Object.keys(cameraGraphData)
    .filter((regionKey) => regionKey !== "overall")
    .some((regionKey) => {
      const regionData = cameraGraphData[regionKey];
      return (
        regionData.history.length > 0 &&
        Object.keys(regionData.stats).length > 0
      );
    });

  return (
    <div className="flex flex-col gap-3">
      {/* Render the overall chart first */}
      <div className="text-r_color_gray-500 text-xs sticky top-0 z-20 font-medium uppercase leading-none tracking-tight bg-r_color_gray-100 ">
        Main metrics being tracked
      </div>

      {hasOverallData ? (
        <CameraGraphCard
          key="overall"
          regionKey="Overall"
          cameraData={cameraGraphData.overall}
          setSelectedRegion={setSelectedRegion}
          setShowCameraDrawer={setShowCameraDrawer}
          setSameraDrawerMeta={setSameraDrawerMeta}
          duration={duration}
        />
      ) : (
        <div className="text-center text-r_color_gray-500 bg-r_color_base flex items-center justify-center h-40 text-sm font-medium">
          {LocalizationKeys.NODATA_TEXT}
        </div>
      )}

      {/* Check if there is data for regions and show heading conditionally */}
      {regionsWithData && (
        <div className="text-r_color_gray-500 text-xs sticky top-0 z-20 font-medium uppercase leading-none tracking-tight bg-r_color_gray-100">
          Throughput of Each Region
        </div>
      )}

      {/* Render the regions */}
      {Object.keys(cameraGraphData)
        .filter((regionKey) => regionKey !== "overall")
        .map((regionKey) => {
          const regionData = cameraGraphData[regionKey];
          const hasRegionData =
            regionData.history.length > 0 &&
            Object.keys(regionData.stats).length > 0;

          return hasRegionData ? (
            <CameraGraphCard
              key={regionKey}
              regionKey={regionKey}
              cameraData={regionData}
              setSelectedRegion={setSelectedRegion}
              setShowCameraDrawer={setShowCameraDrawer}
              setSameraDrawerMeta={setSameraDrawerMeta}
              duration={duration}
            />
          ) : (
            <div
              key={regionKey}
              className="text-center text-r_color_gray-500 bg-r_color_base flex items-center justify-center h-40 text-sm font-medium"
            >
              {LocalizationKeys.NODATA_TEXT}
            </div>
          );
        })}
    </div>
  );
}

function SideDrawerLoader() {
  return Array.from({ length: 5 }).map((_, index) => {
    return (
      <div
        className="relative p-4 bg-r_color_base flex flex-col gap-1"
        key={index}
      >
        <div className="top-6  flex gap-3 items-center text-r_color_gray-500 text-sm font-medium leading-tight bg-r_color_gray-300 rounded animate-pulse"></div>
        <div className="h-20 bg-r_color_gray-300 rounded animate-pulse"></div>
        <div className="h-10 bg-r_color_gray-300 rounded animate-pulse"></div>
        <div className="h-10  justify-start items-start gap-2.5 inline-flex bg-r_color_gray-300 rounded animate-pulse"></div>
      </div>
    );
  });
}

function CamerGraphTooltip({ e, axisLeftLegend, tooltipStack = true }) {
  let datum = e;

  return (
    <div className="bg-white shadow sm:rounded-none ">
      <div className="border-t border-r_color_gray-100">
        <dl className="divide-y divide-r_color_gray-100">
          <div
            className={`p-2 flex items-center gap-1 rounded-lg  ${
              !tooltipStack ? "flex-row " : "flex-col"
            }`}
          >
            <dt className="text-sm font-medium flex">
              <div
                className="w-4 h-4 mr-1 block"
                style={{
                  background: datum?.color,
                  color: "transparent",
                }}
              >
                .
              </div>
              <span className="font-light text-r_color_gray-700 truncate">
                {datum.data.time_window}
              </span>
            </dt>
            <dd className="text-sm leading-6 text-r_color_gray-900 font-semibold">
              {formatLargeNumberWithSuffix(datum.data.total_object_count)}
              {" " + axisLeftLegend}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
