import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { DateTime } from "luxon";
import { LocalizationKeys } from "../../../localizationStrings/en";
import ApiEndpoints from "../../utils/APIEndpoints";

const AlertBar = ({ orgid, widgetLastRefreshedTime, duration }) => {
  const [cameraEvent, setCameraEvent] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCameraHealthTimeSeries = async () => {
      try {
        const getCameraEventTimeSeries =
          ApiEndpoints.GET_CAMERA_EVENTS_TIMESERIES(orgid, duration);
        const response = await fetch(getCameraEventTimeSeries);
        const cameraEventData = await response.json();

        setCameraEvent(cameraEventData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch camera event list");
        console.error("Failed to fetch camera event:", error);
      }
    };
    fetchCameraHealthTimeSeries();
  }, [orgid, widgetLastRefreshedTime]);

  // Handle the case where data is loading
  if (loading) {
    return (
      <div className="bg-r_color_base p-4 flex flex-col rounded-md shadow-sm gap-2">
        <div className="text-r_color_gray-900 text-sm font-medium leading-tight">
          {LocalizationKeys.CAMERA_FEED_ALERT_HEADING}
        </div>
        <div className="flex justify-center flex-col">
          <div className="text-center rounded-lg text-r_color_gray-200 text-base font-bold uppercase leading-7 bg-r_color_gray-200 animate-pulse h-5 w-full"></div>
          <div className="flex justify-between text-gray-500 text-xs mt-2">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="bg-r_color_gray-200 animate-pulse w-11 h-5 rounded-lg"
              ></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Handle the case where no data is present
  if (!cameraEvent?.overall || cameraEvent.overall.length === 0) {
    return (
      <div className="bg-r_color_base p-4 flex flex-col rounded-md shadow-sm gap-2">
        <div className="text-r_color_gray-900 text-sm font-medium leading-tight">
          {LocalizationKeys.CAMERA_FEED_ALERT_HEADING}
        </div>
        <div className="flex justify-center py-6">
          <div className="text-center text-r_color_gray-200 text-base font-bold uppercase leading-7">
            {LocalizationKeys.NODATA_TEXT}
          </div>
        </div>
      </div>
    );
  }

  // Reverse the order of the camera events
  const cameraEvents = [...cameraEvent.overall];

  const segment = cameraEvents.map((item, index) => {
    const isCritical = item?.Critical && item.Critical > 10;
    const styleblock = isCritical
      ? "bg-yellow-400"
      : "bg-r_green-400 progress-bar-striped";
    const roundEdges =
      index === 0
        ? "rounded-l-lg"
        : index === cameraEvents.length - 1
        ? "rounded-r-lg"
        : "";

    return (
      <React.Fragment key={index}>
        <div
          data-tooltip-id="my-tooltip"
          data-tooltip-content={`Time: ${formatTime(
            item.timestamp,
            true
          )}, Critical: ${item.Critical}, Warning: ${item.Warning}, Total: ${
            item.total_events
          }`}
          className={`h-5 flex-1 ${styleblock} ${roundEdges}`}
        ></div>
        <Tooltip id="my-tooltip" style={{ zIndex: 100 }} />
      </React.Fragment>
    );
  });

  const firstFormattedTime = formatTime(cameraEvents[0]?.timestamp, true);
  const lastFormattedTime = formatTime(
    cameraEvents[cameraEvents.length - 1]?.timestamp,
    true
  );

  return (
    <div className="bg-r_color_base p-4 flex flex-col rounded-md shadow-sm gap-2">
      <div className="text-r_color_gray-900 text-sm font-medium leading-tight">
        Camera Feed Alert Health
      </div>
      <div className="flex justify-between gap-x-0.5">{segment}</div>
      <div className="flex justify-between text-gray-500 text-xs mt-2">
        <span>{firstFormattedTime}</span>
        <span>{lastFormattedTime}</span>
      </div>
    </div>
  );
};

const formatTime = (timestamp, showDate = false) => {
  const date = DateTime.fromSeconds(timestamp); // Convert timestamp to DateTime
  const time = date.toFormat("h:mm a"); // Format time with AM/PM

  if (showDate) {
    const formattedDate = date.toFormat("M/d/yyyy"); // Format date as MM/DD/YYYY
    return `${formattedDate} ${time}`;
  }

  return time;
};

export default AlertBar;
