import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function ButtonWithDropdown({ options, onOptionClick, label }) {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={() => onOptionClick(options[0])}
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-r_color_gray-700 ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 focus:z-10"
      >
        {label}
      </button>
      <Menu as="div" className="relative -ml-px block">
        <MenuButton className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-r_color_gray-400 ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
        <MenuItems
          transition
          className="absolute right-0 z-10  mt-2 w-44  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {options.map((option) => (
              <MenuItem key={option.name}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => onOptionClick(option)}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      active
                        ? "bg-r_color_gray-100 text-r_color_gray-900"
                        : "text-r_color_gray-700"
                    }`}
                  >
                    {option.name}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}
