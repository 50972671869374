"use client";

import { useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function SelectMenuWithIcon({ options, label }) {
  const [selected, setSelected] = useState(options[0]);

  const handleSelect = (person) => {
    setSelected(person);
    if (person.clickHandler) {
      person.clickHandler();
    }
  };

  return (
    <Listbox value={selected} onChange={handleSelect}>
      <Label className="block   text-r_color_gray-600 text-sm font-medium  leading-tight">
        {label}
      </Label>
      <div className="relative mt-2 ">
        <ListboxButton className="relative w-full cursor-default rounded-md bg-r_color_base py-1.5 pl-3 pr-10 text-left text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 focus:outline-none focus:ring-2 focus:ring-r_color_primary-500 sm:text-sm sm:leading-6">
          <span className="flex items-center">
            {selected.avatar}

            <span className="ml-3 block truncate">{selected.name}</span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-r_color_gray-400"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-r_color_base py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.map((option) => (
            <ListboxOption
              key={option.id}
              value={option}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-r_color_gray-700 data-[focus]:bg-r_color_primary-600 data-[focus]:text-r_color_base"
            >
              <div className="flex items-center">
                {option.avatar}
                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                  {option.name}
                </span>
              </div>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-r_color_primary-600 group-data-[focus]:text-r_color_base [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
