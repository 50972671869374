import { LocalizationKeys } from "../../localizationStrings/en";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import { ReactComponent as InfoIcon } from "../../assets/Info.svg";
import { ReactComponent as DebugIcon } from "../../assets/debug.svg";

import { ReactComponent as RouteIcon } from "../../assets/route.svg";
import { ReactComponent as NO_OBJECT_DETECTED_ICON } from "../../assets/package-x.svg";
import { ReactComponent as NO_REGION_DETECTED_ICON } from "../../assets/noRegion.svg";
import { ReactComponent as CAMERA_OFFLINE_ICON } from "../../assets/camera-off.svg";
import { toast } from "react-toastify";
import hanhwa_icon from "../../assets/Hanhwa.png";
import rhombus_icon from "../../assets/Rhombus.png";
export const calculateTickValues = (domain, count) => {
  const min = domain[0];
  const max = domain[1];
  const step = (max - min) / (count - 1);

  const ticks = [];
  for (let i = 0; i < count; i++) {
    ticks.push(min + i * step);
  }

  // Ensure the max value is included as the last tick
  if (ticks[ticks.length - 1] < max) {
    ticks[ticks.length - 1] = max;
  }
  // Validate tick values to ensure no invalid values
  const validatedTickValues = ticks.map((value) =>
    !isFinite(value) || isNaN(value) ? 0 : value
  );

  return validatedTickValues;
};
export function formatYAxis(value, roundUp = false) {
  // Convert from log scale to linear scale
  const logScaleValue = Math.pow(10, value) - 1;
  return formatLargeNumberWithSuffix(logScaleValue, roundUp);
}
export function formatLargeNumberWithSuffix(num, roundUp = false) {
  const magnitudeSuffixes = ["", "K", "M", "B", "T"];
  let magnitude = 0;

  // Determine the magnitude and scale down the number
  while (Math.abs(num) >= 1000 && magnitude < magnitudeSuffixes.length - 1) {
    magnitude++;
    num /= 1000;
  }

  // Format the number to at most 2 decimal places if necessary
  let formattedNumber = num % 1 === 0 ? num : parseFloat(num.toFixed(1));

  // Apply rounding if the flag is true
  if (roundUp && formattedNumber % 1 !== 0) {
    formattedNumber = Math.ceil(formattedNumber);
  }

  if (formattedNumber % 1 === 0) {
    formattedNumber = parseInt(formattedNumber);
  }

  return formattedNumber + magnitudeSuffixes[magnitude];
}

export function formatNumericValue(number) {
  const isInteger = Number.isInteger(number);
  return isInteger ? number.toString() : parseFloat(number).toFixed(2);
}
export function getAxisLeftTickValues(
  chartConfigs,
  maxYValue,
  tickCounts,
  scale
) {
  let axisLeftTickValues;

  if (chartConfigs.length === 1) {
    axisLeftTickValues = [0, maxYValue];
  } else {
    if (chartConfigs.length > 0) {
      let maxtickcounts;
      if (scale === "log") {
        maxtickcounts =
          formatYAxis(maxYValue) < tickCounts
            ? +formatYAxis(maxYValue) + 1
            : tickCounts;
      } else {
        maxtickcounts = maxYValue < tickCounts ? maxYValue : tickCounts;
      }
      axisLeftTickValues = calculateTickValues(
        [0, maxYValue],
        maxtickcounts,
        scale
      );
    }
  }

  return axisLeftTickValues;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function getSeverityBgColor(severity) {
  if (severity === LocalizationKeys.CRITICAL) {
    return "bg-r_red-50";
  } else if (severity === LocalizationKeys.WARNING) {
    return "bg-r_orange-50";
  } else if (severity === LocalizationKeys.DEBUG) {
    return "bg-r_yellow-50";
  } else if (severity === LocalizationKeys.INFO) {
    return "bg-r_yellow-50";
  }
}

export function getSeverityTextColor(severity) {
  if (severity === LocalizationKeys.CRITICAL) {
    return "text-r_red-500";
  } else if (severity === LocalizationKeys.WARNING) {
    return "text-r_orange-600";
  } else if (severity === LocalizationKeys.DEBUG) {
    return "text-r_yellow-900";
  } else if (severity === LocalizationKeys.INFO) {
    return "text-r_yellow-700";
  }
}

export function getSeverityIcon(severity, customcss) {
  if (severity === LocalizationKeys.CRITICAL) {
    return <CriticalIcon className={customcss} />;
  } else if (severity === LocalizationKeys.WARNING) {
    return <WarningIcon className={customcss} />;
  } else if (severity === LocalizationKeys.DEBUG) {
    return <DebugIcon className={customcss} />;
  } else if (severity === LocalizationKeys.INFO) {
    return <InfoIcon className={customcss} />;
  }
}

export function getAlertTypeIcon(alertype, customcss) {
  if (alertype === LocalizationKeys.REGION_STUCK) {
    return <RouteIcon className={customcss} />;
  } else if (alertype === LocalizationKeys.NO_OBJECT_DETECTED) {
    return <NO_OBJECT_DETECTED_ICON className={customcss} />;
  } else if (alertype === LocalizationKeys.CAMERA_OFFLINE) {
    return <CAMERA_OFFLINE_ICON className={customcss} />;
  } else if (alertype === LocalizationKeys.NO_REGION_OF_INTEREST) {
    return <NO_REGION_DETECTED_ICON className={customcss} />;
  }
}

export const downloadFile = async (url, name) => {
  try {
    // Fetching the file response
    const response = await fetch(url);

    // Check if the response is OK and if it's indeed a file
    if (response.ok) {
      const contentDisposition = response.headers.get("Content-Disposition");
      let fileName = name;

      // Check if the Content-Disposition header exists and has a filename
      if (contentDisposition && contentDisposition.includes("filename=")) {
        // Extract the filename from the Content-Disposition header
        const fileNameMatch = contentDisposition.match(/filename="?(.*)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1]; // Use the filename from the header
        }
      }

      const blob = await response.blob(); // Convert the response to a blob (binary large object)

      // Create a download link element dynamically
      const link = document.createElement("a");

      // Create a URL for the blob and set it as the href of the link
      const fileURL = window.URL.createObjectURL(blob);
      link.href = fileURL;

      // Set the download attribute of the link with the desired file name
      link.download = fileName;

      // Append the link to the document body and trigger a click event on it to download the file
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the link and revoke the object URL
      link.remove();
      window.URL.revokeObjectURL(fileURL);

      toast.success("Camera stats downloaded successfully.");
    } else {
      toast.warning("No camera stats data available to download.");
    }
  } catch (error) {
    toast.error("Failed to download camera stats");
    console.error("Failed to download camera stats:", error);
  }
};
export const calculateDuration = (start, end) => {
  const diffInMs = end - start; // Difference in milliseconds
  const diffInMinutes = diffInMs / 60000; // Convert to minutes

  if (diffInMinutes >= 7 * 24 * 60) {
    return `${Math.floor(diffInMinutes / (7 * 24 * 60))}w`; // Weeks
  } else if (diffInMinutes >= 24 * 60) {
    return `${Math.floor(diffInMinutes / (24 * 60))}d`; // Days
  } else if (diffInMinutes >= 60) {
    return `${Math.floor(diffInMinutes / 60)}h`; // Hours
  } else if (diffInMinutes >= 30) {
    return "30m"; // 30 minutes
  } else if (diffInMinutes >= 15) {
    return "15m"; // 15 minutes
  } else {
    return "5m"; // Less than 15 minutes
  }
};

export const cameraModel = [
  {
    id: 1,
    name: "hanhwa",
    vendorid: "Hanhwa",
    avatar: hanhwa_icon,
  },
  {
    id: 2,
    name: "rhombus",
    vendorid: "Rhombus",
    avatar: rhombus_icon,
  },
];
export const hasNonZeroData = (history) => {
  return history.some((item) => item.total_object_count > 0);
};
