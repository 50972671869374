"use client";

import { useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

export default function DropdownWithChevron({ options }) {
  const [selected, setSelected] = useState(options[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <Label className="sr-only">Change published status</Label>
      <div className="relative">
        <div className="inline-flex rounded-md">
          <ListboxButton className="inline-flex items-center rounded-l-none rounded-r-md  text-r_color_gray-600  focus:outline-none ">
            <div className="inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-r_color_gray-600 ">
              <p className="text-sm font-semibold">{selected.title}</p>
            </div>

            <span className="sr-only">Change published status</span>
            <ChevronDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-r_color_gray-600"
            />
          </ListboxButton>
        </div>

        <ListboxOptions
          transition
          className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in"
        >
          {options.map((option) => (
            <ListboxOption
              key={option.title}
              value={option}
              className="group cursor-default select-none p-4 text-sm text-gray-900 data-[focus]:bg-r_color_primary-600 data-[focus]:text-white"
            >
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <p className="font-normal group-data-[selected]:font-semibold">
                    {option.title}
                  </p>
                  <span className="text-r_color_primary-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                </div>
                {/* <p className="mt-2 text-gray-500 group-data-[focus]:text-r_color_primary-200">
                  {option.description}
                </p> */}
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
