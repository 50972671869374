const mainMenuOptions = [
  {
    text: "Show Stats Of Active Camera",
    menu_path: "topLevelPages",
    bot_message: "Sure,Please Select the options to drill down further",
  },
];

const subMenuOptions = {
  topLevelPages: [
    {
      text: "Shows Stats of Lane 1",
      menu_path: "LaneStats",
      bot_message: "Sure,Getting the Stats for Lane 1",
    },
    {
      text: "Shows TimeLine Graph of Lane 1",
      menu_path: "showTimeLineGraphLane1",
    },
    {
      text: "Shows Stats of Lane 2",
      menu_path: "Lane2Stats",
    },
    {
      text: "Drill Down Further on Lane 2",
      menu_path: "Lane2Stats",
    },

    { text: "Back to Main Menu", menu_path: "showmainMenu" },
  ],

  Lane2Stats: [
    {
      text: "How many objects have been Counted since last 1 hour ?",
      menu_path: "Lanestats",
    },
    {
      text: "When was the belt stopped",
      menu_path: "Lanestats",
    },

    { text: "Back to Main Menu", menu_path: "topLevelPages" },
  ],
};

export { mainMenuOptions, subMenuOptions };
