"use client";

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import {
  CameraGraph,
  CameraGraphBar,
  NetworkComponent,
} from "../camera-overview-graph";
import ramenColors from "../summary-charts/ramen-colors";
import StatsDisplay from "./drawer-stats";
import DonutChart from "./donutchart";
import Badge from "../core-components/badge";
import { LocalizationKeys } from "../../localizationStrings/en";

export default function CameraDrawer({
  handleDrawerClose,
  selectedRegion,
  alertList,
  duration,
  cameraDrawerMeta,
}) {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    console.log(" selectedRegion", selectedRegion);
  }, [selectedRegion]);

  return (
    <Dialog
      open={open}
      onClose={() => handleDrawerClose()}
      className="relative z-50"
    >
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <form
                className="flex h-full flex-col divide-y divide-r_color_gray-200 bg-r_color_base
 shadow-xl"
              >
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-r_color_secondary-700 px-4 py-6 sm:px-6  sticky z-20 top-0">
                    <div className="flex items-center justify-between ">
                      <DialogTitle
                        className="text-base font-semibold leading-6 text-r_color_base
"
                      >
                        {selectedRegion.name}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => handleDrawerClose()}
                          className="relative rounded-md bg-r_color_secondary-700 text-indigo-200 hover:text-r_color_base
 focus:outline-none focus:ring-2 focus:ring-r_color_base
"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="divide-y divide-r_color_gray-200 px-4 sm:px-6">
                      <div className="space-y-3   ">
                        <div className="flex justify-end  mt-3">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            {/* <div>
                              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-r_color_base px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50">
                                Live
                                <ChevronDownIcon
                                  aria-hidden="true"
                                  className="-mr-1 h-5 w-5 text-r_color_gray-400"
                                />
                              </MenuButton>
                            </div> */}

                            <MenuItems
                              transition
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-r_color_base shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="py-1">
                                <MenuItem>
                                  <div className="block px-4 py-2 text-sm text-r_color_gray-700 data-[focus]:bg-r_color_gray-100 data-[focus]:text-r_color_gray-900">
                                    Live{" "}
                                  </div>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </Menu>
                        </div>
                        <div className="relative  bg-r_color_base flex flex-col gap-3   ">
                          <div className="top-6 bg-r_color_base flex gap-3 items-center text-gray-500  text-sm font-medium leading-tight">
                            <span>
                              {`Unit count on ${cameraDrawerMeta.regionKey}`}

                              <span className="ml-2 text-xs  text-gray-400 font-normal">
                                per {cameraDrawerMeta.bucket_unit}
                              </span>
                            </span>
                            <span>
                              <Badge
                                backgroundcolor={cameraDrawerMeta.TrendBgColor}
                                textcolor={cameraDrawerMeta.TrendTextColor}
                                label={Math.abs(
                                  cameraDrawerMeta.entryDifference
                                )}
                                customIcon={cameraDrawerMeta.TrendIcon}
                              ></Badge>
                            </span>
                          </div>
                          <div className="flex h-40  ">
                            <CameraGraphBar
                              chartData={cameraDrawerMeta.cameraData.history}
                              chartColor={ramenColors.r_color_secondary[500]}
                              duration={duration}
                              bucketUnit={cameraDrawerMeta.bucket_unit}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col border-t pt-6">
                          <div className="text-r_color_gray-600 text-lg font-medium  leading-7">
                            Stats
                          </div>
                          <div>
                            <StatsDisplay
                              stats={selectedRegion.cameraData.stats}
                            />
                          </div>
                        </div>

                        {alertList && alertList.length > 0 ? (
                          <div className="flex flex-col border-t pt-6">
                            <div className="text-r_color_gray-600 text-lg font-medium leading-7">
                              Alerts
                            </div>
                            <div className="h-40">
                              <DonutChart stats={alertList} />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="text-r_color_gray-600   text-lg font-medium leading-7 ">
                              Alerts
                            </div>
                            <div className="bg-r_color_base p-4 flex flex-col rounded-md   gap-2 ">
                              <div className="flex justify-center py-6  ">
                                <div className="text-center text-r_color_gray-300 text-base font-bold  uppercase leading-7">
                                  {LocalizationKeys.NOALERTSIN_TEXT}{" "}
                                  <span className="lowercase">{duration}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="rounded-md bg-r_color_base
 px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-r_color_base
 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div> */}
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
