import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiEndpoints from "../../utils/APIEndpoints";

export default function SystemInfoCard({
  widgetLastRefreshedTime,
  orgid,
  duration,
}) {
  const [systemInfo, setSystemInfo] = useState({
    uptime: "-",
    alert_count: "-",
    online_camera_count: "-",
    offline_camera_count: "-",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace with your actual API endpoint
    const fetchSystemInfo = async () => {
      try {
        const get_system_info_url = ApiEndpoints.GET_SYSTEM_INFO(
          orgid,
          duration
        );
        const response = await fetch(get_system_info_url);
        const data = await response.json();
        // console.log("data is ", data);
        setSystemInfo({
          uptime: data.uptime,
          alert_count: data.alert_count,
          online_camera_count: data.online_camera_count,
          offline_camera_count: data.offline_camera_count,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch system stats");
        console.error("Failed to fetch system stats:", error);
      }
    };

    fetchSystemInfo();
  }, [widgetLastRefreshedTime]);

  return (
    <div className="overflow-hidden rounded-lg bg-r_color_base shadow  w-full">
      <div className="px-4 py-5 sm:p-6 sm:py-2">
        <SystemInfoCardOptions
          systemInfo={systemInfo}
          loading={loading}
          duration={duration}
        />
      </div>
    </div>
  );
}

function SystemInfoItem({ label, value }) {
  return (
    <div className="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700  sm:mt-0 text-right">
        {value}
      </dd>
    </div>
  );
}

function SystemInfoCardOptions({ systemInfo, loading, duration }) {
  if (loading) {
    return (
      <dl className="divide-y divide-gray-100">
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className="px-4 py-1 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0"
            >
              <dt className="text-sm font-medium leading-6 text-gray-900 col-span-3 bg-r_color_gray-200 animate-pulse h-5"></dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 text-right bg-r_color_gray-200 animate-pulse h-5"></dd>
            </div>
          ))}
      </dl>
    );
  }

  return (
    <dl>
      <SystemInfoItem label="System Uptime" value={systemInfo.uptime} />
      <SystemInfoItem
        label={`Alerts Activity (${duration})`}
        value={systemInfo.alert_count}
      />
      <SystemInfoItem
        label="Cameras Online"
        value={systemInfo.online_camera_count}
      />
      <SystemInfoItem
        label="Offline Cameras"
        value={systemInfo.offline_camera_count}
      />
    </dl>
  );
}
