export const LocalizationKeys = {
  Organization: "Organization",
  Sites: "Sites",
  Camera: "Camera",
  PlaceholderText_SelectOrg: "Select Organization",
  PlaceholderText_SelectSite: "Select Site",
  PlaceholderText_SelectCamera: "Select Camera",
  DefaultScreenMessage:
    "Select specific Site and Camera to show the Video feed",
  VideoNotFound: "Video Not Found",
  CameraFeed: "Camera Feed",
  PageHeaderSubheading:
    "AI-powered computer vision solution for real-time analytics and insights from live video streams",
  Add_Camera: "Add Camera",
  CAMERA_ALERT_HEADING: "Alert",
  CAMER_ALERT_SUBHEADING: "Check the recent camera specific alert here  ",
  NO_CAMERA_TEXT: "No Camera is added yet!",
  ADD_CAMERA_TEXT_MAIN_CONTAINER: "Add Camera to see the insights",
  VIDEO_ALERT_TRACKER: "Camera Alert Tracker",
  OFFLINE: "Offline",
  ONLINE: "Online",
  NODATA_TEXT: "No Data to show",
  NOALERTSIN_TEXT: "No Alerts in ",
  NO_ISSUES: "No issues detected at the moment.",
  CAMERA_FEED_ALERT_HEADING: "Camera Feed Alert Health",
  MANUFACTURER: "Manufacturer",
  SUMMARYTAB: "Summary",
  ALERTTAB: "Alert",
  CRITICAL: "Critical",
  WARNING: "Warning",
  INFO: "Info",
  DEBUG: "Debug",
  RAMEN_ASSIST: "Ramen Assist",
  REGION_STUCK: "Region Stuck",
  CAMERA_OFFLINE: "Camera Offline",
  NO_OBJECT_DETECTED: "No Objects Detected",
  NO_REGION_OF_INTEREST: "No region of Interest",
  DELETE_CAMERA_TEXT: "Delete Camera",
  DELETE_CAMERA_BTN_TEXT: "Delete camera",
  DELETE_CAMERA_FORM_HEADING: "Deleting camera stops analytics for this camera",
};
