import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const downloadCameraStats = (data, cameraName) => {
  const belts = Object.keys(data);

  // Initialize an array to hold the flattened data
  const excelData = [];

  belts.forEach((belt) => {
    if (data[belt]?.stats) {
      const stats = data[belt].stats;
      const row = { Camera: cameraName, Region: belt };

      // Loop over the stats keys dynamically
      Object.keys(stats).forEach((key) => {
        row[key] = stats[key]; // Add the dynamic key-value pairs
      });

      excelData.push(row);
    }
  });

  // Create worksheet from the flattened data
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

  // Write the workbook to buffer
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a blob and trigger download
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${cameraName}_stats.xlsx`);
};

export const downloadGlobalStats = (data) => {
  const excelData = [];

  Object.keys(data).forEach((camera) => {
    const belts = Object.keys(data[camera]);

    belts.forEach((belt) => {
      if (data[camera][belt]?.stats) {
        const stats = data[camera][belt].stats;
        const row = { Camera: camera, Region: belt };

        // Loop over the stats keys dynamically
        Object.keys(stats).forEach((key) => {
          row[key] = stats[key]; // Add the dynamic key-value pairs
        });

        excelData.push(row);
      }
    });
  });

  // Create worksheet from the flattened data
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

  // Write the workbook to buffer
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a blob and trigger download
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "all_camera_stats.xlsx");
};
