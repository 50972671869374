import React from "react";
import ramen_logo from "../../chat-bot/assets/logo.svg";

const BotResponse = ({ children }) => (
  <div className="flex  py-4 mb-6   ">
    <div className="p-2">
      <img src={ramen_logo} width={40} height={40} alt="ramen-logo" />
    </div>
    <div className="px-3 py-2 w-[80%] bg-r_color_primary-50 rounded-md">
      {children}
    </div>
  </div>
);

export default BotResponse;
