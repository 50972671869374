import { useState } from "react";
import { classNames } from "../utils/commonFunctions";

export default function HeaderTabs({ tabs, onTabClick, selectedTab }) {
  // const [selectedTab, setSelectedTab] = useState(
  //   tabs.find((tab) => tab.current)?.name || tabs[0].name
  // );

  const handleTabClick = (tabName) => {
    // setSelectedTab(tabName);
    onTabClick(tabName);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          value={selectedTab}
          onChange={(e) => handleTabClick(e.target.value)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-r_color_primary-500 focus:outline-none focus:ring-r_color_primary-500 sm:text-sm"
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={() => handleTabClick(tab.name)}
                aria-current={selectedTab === tab.name ? "page" : undefined}
                className={classNames(
                  selectedTab === tab.name
                    ? "border-r_color_primary-500 text-r_color_primary-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
