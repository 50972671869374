import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ReactComponent as TableIcon } from "../../assets/table.svg";

import { ReactComponent as ViewAsCard } from "../../assets/view-grid.svg";
import { ReactComponent as TimeRangeIcon1 } from "../../assets/Time-Range-Icon-1.svg";
import { ReactComponent as TimeRangeIcon2 } from "../../assets/Time-Range-Icon-2.svg";
import { ReactComponent as TimeRangeIcon3 } from "../../assets/Time-Range-Icon-3.svg";
import { ReactComponent as TimeRangeIcon4 } from "../../assets/Time-Range-Icon-4.svg";
import { ReactComponent as TimeRangeIcon5 } from "../../assets/Time-Range-Icon-5.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings-icon.svg";

import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import filterIcon from "../../assets/filter.svg";
import sortIcon from "../../assets/sortdescending.svg";

import { useEffect, useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import SelectMenuWithIcon from "./selectMenuWIthIcon";
import SelectMenu from "./select-menu";

export default function FilterPanel({
  globalSearchHandler,
  durationOptions,
  duration,
  handleDurationChange,
}) {
  const [selectedDuration, setSelectedDuration] = useState({});

  useEffect(() => {
    setSelectedDuration(
      durationOptions.find((item) => item.value === duration)
    );
  }, [duration, durationOptions]);
  return (
    <>
      <div className="bg-r_color_gray-200 flex p-4 gap-x-4 rounded-b-lg  relative ">
        <div className="flex-1">
          <label
            htmlFor="globalsearch"
            className="block   text-r_color_gray-600 text-sm font-medium  leading-tight truncate"
          >
            Quick Find
          </label>
          <div className="mt-2">
            <input
              id="globalsearch"
              name="globalsearch"
              type="text"
              placeholder="Seach"
              onChange={(e) => {
                globalSearchHandler(e.target.value);
              }}
              className="block w-full h-9 rounded-md border-0 py-2 px-3.5 text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 placeholder:text-r_color_gray-400 focus:ring-2 focus:ring-inset focus:ring-r_color_primary-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="flex-1">
          <FilterFlyoutMenu
            label={"Sort By"}
            icon={sortIcon}
            configuredFor={"sort"}
          />
        </div>
        <div className="flex-1">
          <FilterFlyoutMenu
            label={"Filter By"}
            icon={filterIcon}
            configuredFor={"filter"}
          />
        </div>
        <div className="flex-1 z-20 ">
          {/* <SelectMenuWithIcon options={durationOptions} label={"Time period"} /> */}
          <SelectMenu
            label="Time period"
            selected={selectedDuration}
            onChange={(selectedValue) => {
              handleDurationChange(selectedValue); // Propagate the change to the parent
            }}
            options={durationOptions}
            placeholder={""}
            showAvatar={false}
            dropdownIcon={ChevronDownIcon}
          />
        </div>
        <div>
          <label
            htmlFor="viewSwitcher"
            className="block   text-r_color_gray-600 text-sm font-medium  leading-tight"
          ></label>
          <span className="isolate inline-flex rounded-md shadow-sm mt-6">
            <button
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-r_color_gray-400 ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 focus:z-10"
            >
              <span className="sr-only">Previous</span>
              <TableIcon aria-hidden="true" className="h-5 w-5" />
            </button>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-r_color_gray-400 ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50 focus:z-10"
            >
              <span className="sr-only">Next</span>
              <ViewAsCard aria-hidden="true" className="h-5 w-5" />
            </button>
          </span>
        </div>

        <div className="w-10 h-10  absolute -top-10 right-0 bg-gray-200 rounded-tl-[100px] rounded-tr-[100px] justify-center items-center inline-flex">
          <SettingsIcon className="text-r_color_gray-600"></SettingsIcon>
        </div>
      </div>
    </>
  );
}

const solutions = [
  {
    name: "Analytics",
    description: "Get a better understanding of your traffic",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    description: "Your customers' data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools",
    href: "#",
    icon: SquaresPlusIcon,
  },
  {
    name: "Automations",
    description: "Build strategic funnels that will convert",
    href: "#",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

const people = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar: "",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
];

function FilterFlyoutMenu({ label, configuredFor, icon, disabled = true }) {
  const [selected, setSelected] = useState(people[2]);

  return (
    <Popover className="relative">
      <Listbox value={selected}>
        <Label className="block   text-r_color_gray-600 text-sm font-medium  leading-tight">
          {label}
        </Label>
        <div className="relative mt-2">
          <PopoverButton
            disabled={disabled}
            className="inline-flex w-full items-center gap-x-1 text-sm font-semibold leading-6 text-r_color_gray-900 "
          >
            <div className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 focus:outline-none focus:ring-2 focus:ring-r_color_primary-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <img
                  alt=""
                  src={icon}
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
                <span className="ml-3 block truncate text-r_color_gray-400 text-base font-normal leading-normal">
                  {configuredFor === "sort" ? " Sorted" : "Filtered"} by 0 field
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-r_color_gray-400"
                />
              </span>
            </div>
          </PopoverButton>
        </div>
      </Listbox>

      <PopoverPanel
        transition
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-r_color_gray-900/5">
          <div className="p-4">
            {solutions.map((item) => (
              <div
                key={item.name}
                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-r_color_gray-50"
              >
                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-r_color_gray-50 group-hover:bg-white">
                  <item.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-r_color_gray-600 group-hover:text-r_color_primary-600"
                  />
                </div>
                <div>
                  <a
                    href={item.href}
                    className="font-semibold text-r_color_gray-900"
                  >
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-r_color_gray-600">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 divide-x divide-r_color_gray-900/5 bg-r_color_gray-50">
            {callsToAction.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-r_color_gray-900 hover:bg-r_color_gray-100"
              >
                <item.icon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none text-r_color_gray-400"
                />
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
