export default function Badge({
  label = "Badge",
  textcolor = "text-r_color_primary-700",
  backgroundcolor = "bg-r_color_primary-50",
  bordercolor = "ring-r_color_primary-700/10",
  customIcon,
  iconPosition = "right",
}) {
  return (
    <>
      <span
        className={`inline-flex items-center rounded-full ${backgroundcolor} px-1.5 py-0.5 text-xs font-medium ${textcolor} ring-1 ring-inset ${bordercolor}`}
      >
        {iconPosition === "left" ? (
          <>
            <span className="mr-1">{customIcon}</span>
            {label}
          </>
        ) : (
          <>
            {label}
            <span className="ml-1">{customIcon}</span>
          </>
        )}
      </span>
    </>
  );
}
