import React, { useEffect, useState } from "react";
import ChatBot, { MessagesContext, PathsContext } from "react-chatbotify";
import "./chatbotv3.css";
import ramen_logo from "../chat-bot/assets/logo.svg";
import user_avatar from "../chat-bot/assets/Avatar.svg";

import useChatBotState from "./services/useChatBotState";
import flowConfig from "./services/flowService";
import SelectOptionsWidgetv2 from "./components/SelectOptionsWidgetv2";
import ramenColors from "../summary-charts/ramen-colors";
import RamenComboBox from "../tailwind-components/RamenCombobox";
import ChatBotInputBox from "./components/chatbot-inputbox";
import { BOT_ENUM } from "./botEnums";
import ChatBotComboBox from "./components/combobox";

const ChatBotV3 = ({ statistics, chartData, averages, windowSize }) => {
  const {
    botState,
    setBotState,
    showUserOptions,
    setShowUserOptions,
    messages,
    setMessages,
    userOptions,
    paths,
    setPaths,
    setForm,
    form,
    prevMenu,
    setPrevMenu,
    setUserOptions,
    mainMenuOptions,
    currentWidgetConfig,
    setCurrentWidgetConfig,
    showCombobox,
    setShowCombobox,
    setcomboboxOptions,
    comboboxOptions,
    calculatedStatistics,
    calculatedChartData,
    setchatBotInputValue,
    chatBotInputValue,
  } = useChatBotState(statistics, chartData, averages);

  const updateMessageAndSwitchTask = (menuOption) => {
    console.log("menuOption", menuOption);
    if (menuOption) {
      if (menuOption.optionClickHandler) {
        menuOption.optionClickHandler();
      }
      setMessages((prev) => [
        ...prev,
        { content: menuOption.text, sender: "user", type: "string" },
      ]);
      const botMessage = menuOption.bot_message || "";

      setTimeout(() => {
        if (botMessage) {
          setMessages((prev) => [
            ...prev,
            {
              content: botMessage,
              sender: "bot",
              type: "string",
            },
          ]);
        }
        if (menuOption.menu_path === "showmainMenu") {
          setUserOptions(mainMenuOptions);
          setPaths([]);
        } else if (menuOption.menu_path === "prevMenu") {
          setUserOptions(prevMenu);
          setPaths((prev) => prev.slice(0, -1));
        } else {
          if (menuOption.renderWidget) {
            if (menuOption.chartConfig) {
              setCurrentWidgetConfig(menuOption.chartConfig);
            } else {
              setCurrentWidgetConfig([]);
            }
          }
          setPaths((prev) => [...prev, menuOption.menu_path]);
        }
      }, 500);
    }
  };

  const chatBotInputBoxChange = (value) => {
    setchatBotInputValue(value);
    setMessages((prev) => [
      ...prev,
      { content: value, sender: "user", type: "string" },
    ]);
  };

  const updateMessageAndSwitchTaskFromCombobox = (menuOption) => {
    if (menuOption) {
      setMessages((prev) => [
        ...prev,
        { content: menuOption.label, sender: "user", type: "string" },
      ]);

      setTimeout(() => {
        setCurrentWidgetConfig(menuOption);
        setPaths((prev) => [...prev, menuOption.path]);
      }, 500);
    }
  };

  const flow = flowConfig({
    setBotState,
    botState,
    setForm,
    form,
    setShowUserOptions,
    setCurrentWidgetConfig,
    currentWidgetConfig,
    setShowCombobox,
    setcomboboxOptions,
    setPaths,
    calculatedStatistics,
    calculatedChartData,
    setUserOptions,
  });

  return (
    <div className="relative  ">
      {showUserOptions && (
        <div
          className={`absolute  right-1  w-full  flex justify-end 
            ${
              windowSize === BOT_ENUM.WINDOW_REGULARSIZE
                ? "bottom-16"
                : "bottom-0"
            }`}
        >
          <SelectOptionsWidgetv2
            updateConfig={updateMessageAndSwitchTask}
            options={userOptions}
          />
        </div>
      )}

      {showCombobox && (
        <div
          className={`absolute bottom-0 right-0 w-full  flex justify-end  
          ${
            windowSize === BOT_ENUM.WINDOW_REGULARSIZE
              ? "bottom-16"
              : "bottom-0"
          }
        
        `}
        >
          <div className="w-full max-w-[80%] mr-16 mb-2  shadow sm:rounded-lg ">
            <ChatBotComboBox
              onOptionSelect={updateMessageAndSwitchTaskFromCombobox}
              options={comboboxOptions}
              backToPrevMenuHandler={() => {
                console.log("prev menu", paths);
              }}
            />
          </div>
        </div>
      )}
      <MessagesContext.Provider value={{ messages, setMessages }}>
        <PathsContext.Provider value={{ paths, setPaths }}>
          <ChatBot
            flow={flow}
            options={{
              theme: { embedded: true, showHeader: false, showFooter: false },
              chatHistory: { disabled: true },
              classNames: "mychatbot",
              chatWindowStyle: {},
              bodyStyle: {},
              userBubble: {
                showAvatar: true,
                avatar: user_avatar,
                simStream: true,
                streamSpeed: 30,
              },
              botBubble: {
                showAvatar: true,
                avatar: ramen_logo,
                simStream: true,
                animate: true,

                streamSpeed: 30,
              },
              botOptionStyle: { border: "1px solid red" },
              botBubbleStyle: {
                backgroundColor: ramenColors.r_color_primary[600],
                color: ramenColors.r_color_base,
                border: "1px solid ",
                borderColor: ramenColors.r_color_gray[300],
                borderRadius: "6px",
                fontWeight: 500,
              },
              userBubbleStyle: {
                backgroundColor: ramenColors.r_color_gray[100],
                color: ramenColors.r_color_gray[900],
                border: "1px solid ",
                borderColor: ramenColors.r_color_gray[300],
                borderRadius: "6px",
                fontWeight: 500,
              },

              advance: {
                useAdvancedPaths: true,
                useCustomPaths: true,
                useCustomMessages: true,
              },
            }}
          />
        </PathsContext.Provider>
      </MessagesContext.Provider>
    </div>
  );
};

export default ChatBotV3;
