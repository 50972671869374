import { useEffect, useState } from "react";
import { CameraGraph } from "../../camera-overview-graph";
import DonutChart from "../../camera-overview/donutchart";
import StatsDisplay from "../../camera-overview/drawer-stats";
import ramenColors from "../../summary-charts/ramen-colors";
import ApiEndpoints from "../../utils/APIEndpoints";

const CameraInfoCardBotFlow = ({ selectedWidget, orgid, history, stats }) => {
  const [alertList, setAlertList] = useState([]);
  useEffect(() => {
    const fetchCameraAlerts = async () => {
      try {
        const getCameraEventByCamIdUrl =
          ApiEndpoints.GET_CAMERAEVENTS_BY_CAMERAID(orgid, selectedWidget.id);
        const response = await fetch(getCameraEventByCamIdUrl);
        const cameraAlert = await response.json();
        setAlertList(cameraAlert);
      } catch (error) {
        console.error("Failed to fetching  camera alert info:", error);
      }
    };
    fetchCameraAlerts();
  }, []);
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="h-24 ">
          <CameraGraph
            chartData={history}
            chartColor={ramenColors.r_color_secondary[500]}
            duration={"24h"}
          />
        </div>
        <div className="flex flex-col  pt-6  border-t border-r_color_gray-400 ">
          <div className="text-gray-700 text-base font-medium ">Stats</div>
          <div className="mt-2">
            <StatsDisplay
              labelcss={
                "text-r_color_gray-400 text-sm font-medium  leading-tight capitalize"
              }
              valuecss={
                "text-r_color_gray-700 text-sm font-normal  leading-tight"
              }
              stats={stats}
            />
          </div>
        </div>

        {alertList && alertList.length > 0 && (
          <div className="flex flex-col border-t pt-6 ">
            <div className="text-r_color_gray-600 text-lg font-medium  leading-7">
              Alerts
            </div>
            <div className="h-40 bg-r_color_base p-2  rounded-md">
              <DonutChart stats={alertList} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CameraInfoCardBotFlow;
