import React, { useState, useRef, useEffect, useCallback } from "react";
import { Stage, Layer, Line, Transformer, Image } from "react-konva";
import { toast } from "react-toastify";
import useImage from "use-image";
import ApiEndpoints from "../../../utils/APIEndpoints";

const PolygonDrawing = ({
  imageUrl,
  selectedCamera,
  orgid,
  regions,
  setRegions,
}) => {
  const [drawing, setDrawing] = useState(false); // To track if drawing mode is active
  const [currentPoints, setCurrentPoints] = useState([]); // Points being drawn
  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [showLayerList, setShowLayerList] = useState(true); // Show or hide the layer list
  const [isAddLayerDisabled, setIsAddLayerDisabled] = useState(false); // Disable Add Layer button
  const transformerRef = useRef();
  const stageRef = useRef(null); // Ref to stage for cursor change
  const layerRefs = useRef([]); // Store references to each polygon
  const [image] = useImage(imageUrl); // Load the image from the provided URL

  // Handle adding a new layer and enabling drawing mode
  const handleAddLayer = () => {
    setDrawing(true);
    setCurrentPoints([]); // Reset points for new drawing
    setSelectedLayerId(null); // Deselect current layer
    setShowLayerList(false); // Hide layer list
    setIsAddLayerDisabled(true); // Disable Add New Layer button
  };

  // Handle saving the polygon and resetting states
  const handleFinishPolygon = () => {
    if (currentPoints.length < 8) return; // We need at least 4 points (x, y pairs)

    let newLayer = {
      name: `layer${regions.length + 1}`,
      points: currentPoints,
    };

    setRegions([...regions, newLayer]);
    setDrawing(false); // Disable drawing mode
    setCurrentPoints([]); // Reset points after saving
    setIsAddLayerDisabled(false); // Enable Add New Layer button
    setShowLayerList(true); // Show layer list after saving
  };

  // Handle undo action (remove the last point)
  const handleUndo = useCallback(() => {
    setCurrentPoints((prevPoints) => prevPoints.slice(0, -2)); // Remove last x, y pair
  }, []);

  // Handle delete layer
  const handleDeleteLayer = (points) => {
    setRegions(regions.filter((layer) => layer.points !== points));
    setSelectedLayerId(null); // Clear selection when a layer is deleted
    if (transformerRef.current) {
      transformerRef.current.nodes([]); // Remove Transformer when no layer is selected
    }
  };

  // Enable undo with Ctrl+Z
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "z") {
        handleUndo();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUndo]);

  // Attach Transformer to the selected polygon
  useEffect(() => {
    const selectedPolygon = layerRefs.current[selectedLayerId];
    if (selectedPolygon && transformerRef.current) {
      transformerRef.current.nodes([selectedPolygon]);
      transformerRef.current.getLayer().batchDraw(); // Force redraw of the layer
    } else {
      transformerRef.current.nodes([]); // Deselect Transformer if no polygon is selected
    }
  }, [selectedLayerId]);

  useEffect(() => {
    console.log("updated layers", regions);
  }, [regions]);

  const handleStageClick = (e) => {
    if (!drawing) return; // If not in drawing mode, do nothing

    const stage = stageRef.current;
    const mousePos = stage.getPointerPosition();
    const { x, y } = mousePos;

    setCurrentPoints((prevPoints) => [...prevPoints, x, y]); // Add the clicked point to the current points array
  };

  return (
    <div style={{ display: "flex" }} className="overflow-auto max-h-full">
      {/* Left side - Canvas */}
      <div>
        <Stage
          width={1920}
          height={1080}
          onMouseDown={handleStageClick}
          ref={stageRef}
          style={{ cursor: drawing ? "crosshair" : "default" }} // Change cursor to pencil
        >
          <Layer>
            {/* Render the background image */}
            {image && <Image image={image} width={1920} height={1080} />}

            {regions.map((layer) => (
              <React.Fragment key={layer.id}>
                <Line
                  ref={(el) => (layerRefs.current[layer.id] = el)} // Store references to each polygon
                  points={layer.points}
                  stroke={layer.id === selectedLayerId ? "blue" : "green"}
                  strokeWidth={2}
                  fill={"green"}
                  opacity={0.3}
                  closed
                />
              </React.Fragment>
            ))}

            {/* Display the polygon being drawn */}
            {currentPoints.length > 0 && (
              <Line
                points={currentPoints}
                stroke={"blue"}
                strokeWidth={2}
                closed={false}
              />
            )}

            {/* Enable the transformer only if a polygon is selected */}
            <Transformer ref={transformerRef} />
          </Layer>
        </Stage>
      </div>

      {/* Right side - Table and buttons */}
      <div
        style={{ marginLeft: "20px" }}
        className="position fixed bg-r_color_base right-8 p-2"
      >
        {showLayerList && (
          <table border="1">
            <thead>
              <tr>
                <th>Layer Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {regions.map((layer) => (
                <tr key={layer.id}>
                  <td>
                    <input
                      type="text"
                      value={layer.name}
                      onChange={(e) =>
                        setRegions((prevLayers) => {
                          return prevLayers.map((l) =>
                            l.points === layer.points
                              ? { ...l, name: e.target.value }
                              : l
                          );
                        })
                      }
                    />
                  </td>
                  <td>
                    <button onClick={() => handleDeleteLayer(layer.points)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Buttons for adding layer, saving polygon, and saving all regions */}
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            onClick={handleAddLayer}
            type="button"
            disabled={isAddLayerDisabled} // Disable when drawing is in progress
            className={` relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
              isAddLayerDisabled ? "opacity-50 cursor-not-allowed hidden" : ""
            }`}
          >
            Add New Layer
          </button>
          {!showLayerList && (
            <button
              type="button"
              onClick={handleFinishPolygon}
              disabled={currentPoints.length < 8} // Disable until at least 4 points (8 coordinates) are added
              className="relative -ml-px inline-flex items-center bg-blue-500 text-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-blue-600 focus:z-10"
            >
              Save Polygon
            </button>
          )}
          {/* {showLayerList && (
            <button
              type="button"
              onClick={handleSaveRegions}
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              Save All Regions
            </button>
          )} */}
        </span>
      </div>
    </div>
  );
};

export default PolygonDrawing;
