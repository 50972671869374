import React, { useEffect, useRef, useState } from "react";
import PolygonDrawing from "./layout-components/camera_trouser/overview/add-region";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import Inputbox from "./core-components/inputbox";
import ApiEndpoints from "./utils/APIEndpoints";
import { toast } from "react-toastify";
import SelectMenu from "./core-components/select-menu";
import AddRegionModal from "./layout-components/camera_trouser/overview/add-region-modal";
import { CameraThumbnail } from "./thumbnail";
import { LocalizationKeys } from "../localizationStrings/en";
import ConfirmationModal from "./core-components/confirmationModal";
import { ReactComponent as Ban } from "../assets/Ban.svg";
import { cameraModel } from "./utils/commonFunctions";

export default function CameraConfig({
  selectedCamera,
  orgid,
  closeCameraTrouser,
}) {
  return (
    <div className="w-full">
      <CameraConfiguration
        selectedCamera={selectedCamera}
        orgid={orgid}
        closeCameraTrouser={closeCameraTrouser}
      />
    </div>
  );
}

function CameraConfiguration({ selectedCamera, orgid, closeCameraTrouser }) {
  const formRef = useRef(null);
  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };
  return (
    <>
      <div className="space-y-5 divide-y divide-r_color_gray-900/10">
        <AdvanceSettingForm
          selectedCamera={selectedCamera}
          orgid={orgid}
          formRef={formRef}
          closeCameraTrouser={closeCameraTrouser}
        />
        <RegionSettingForm selectedCamera={selectedCamera} orgid={orgid} />
        <DeleteCameraSection
          selectedCamera={selectedCamera}
          orgid={orgid}
          closeCameraTrouser={closeCameraTrouser}
        />
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-r_color_gray-900/10 px-4 py-4 sm:px-8  bg-r_color_base fixed bottom-0 left-0 right-0">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={closeCameraTrouser}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-r_color_primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600"
          onClick={handleSaveClick} // Trigger save
        >
          Save
        </button>
      </div>
    </>
  );
}

function AdvanceSettingForm({
  selectedCamera,
  orgid,
  formRef,
  closeCameraTrouser,
}) {
  const [objectToDetechList, setObjectToDetectlist] = useState([]);
  const [selectedObjectModal, setselectedObjectModal] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Convert certain fields to number
    if (name in formData?.feed_info) {
      setFormData((prevState) => ({
        ...prevState,
        feed_info: {
          ...prevState.feed_info,
          [name]: value, // Update only the relevant key inside feed_info
        },
      }));
    } else {
      // Handle other form fields
      let parsedValue = value;
      if (
        ["frame_height", "frame_width", "fps", "belt_stuck_duration"].includes(
          name
        )
      ) {
        parsedValue = parseFloat(value) || 0; // Handle numbers
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: parsedValue, // Update general form data
      }));
    }
  };

  useEffect(() => {
    if (formData.objects_detected) {
      const selectedObject = objectToDetechList.find(
        (item) => item.name === formData.objects_detected
      );
      setselectedObjectModal(selectedObject || null);
    } else if (objectToDetechList.length > 0) {
      setselectedObjectModal(objectToDetechList[0]);
    }
  }, [formData.objects_detected, objectToDetechList]);

  useEffect(() => {
    if (formData.vendor) {
      const selectedVendor = cameraModel.find(
        (item) => item.name === formData.vendor
      );
      setSelectedVendor(selectedVendor || null);
    }
  }, [formData.vendor]);

  useEffect(() => {
    setFormData(selectedCamera);
    const fetchObjectsToDetechList = async () => {
      try {
        const getcamera_url = ApiEndpoints.GET_SUPPORTED_MODELS(orgid);
        const response = await fetch(getcamera_url);
        const objectList = await response.json();
        const transformedRes = Object.keys(objectList).map((key, index) => {
          return {
            id: index,
            name: key,
          };
        });

        setObjectToDetectlist(transformedRes);
      } catch (error) {
        toast.error("Failed to fetch object detection list");
        console.error("Failed to fetch object detection list", error);
      }
    };
    fetchObjectsToDetechList();
  }, [selectedCamera, orgid]);
  const handleObjectDetechChange = (objectSelection) => {
    setselectedObjectModal(objectSelection);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      name: formData.name,
      description: formData.description,
      frame_height: formData.frame_height,
      feed_info: formData.feed_info,
      frame_width: formData.frame_width,
      objects_detected: selectedObjectModal.name,
      fps: formData.fps,
      belt_stuck_duration: formData.belt_stuck_duration,
    };

    try {
      const saveUrl = ApiEndpoints.GET_CAMERA_BY_ID(orgid, selectedCamera.id);
      console.log(saveUrl, payload);

      const response = await fetch(saveUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to save camera settings");
      }

      toast.success("Camera settings saved successfully");
      closeCameraTrouser("fetchCameraList");
    } catch (error) {
      toast.error("Error saving camera settings");
      console.error("Error saving camera settings", error);
    }
  };

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-r_color_gray-900">
              General Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-r_color_gray-600">
              Update the name and description of the current configuration
            </p>
          </div>

          <div className="bg-white shadow-sm ring-1 ring-r_color_gray-900/5 sm:rounded-xl md:col-span-2 w-full">
            <div className="px-4 py-6 sm:p-8 w-full">
              <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                <div className="sm:col-span-3">
                  <Inputbox
                    label="Name"
                    placeholder="Enter Name"
                    id="name"
                    name="name"
                    value={formData.name || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Inputbox
                    label="Description"
                    placeholder="Enter Description"
                    id="description"
                    name="description"
                    value={formData.description || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:col-span-3">
                  <SelectMenu
                    label="Vendor"
                    selected={selectedVendor}
                    options={cameraModel}
                    placeholder={"Select Vendor"}
                    showAvatar={true}
                    disabled={true}
                  />
                </div>
                {selectedVendor?.name === "rhombus" ? (
                  <div className="sm:col-span-3">
                    <Inputbox
                      label="Vendor ID"
                      placeholder="Enter Vendor ID"
                      id="vendorid"
                      name="vendor"
                      value={formData?.feed_info?.camera_id || ""}
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  <>
                    <div className="sm:col-span-3">
                      <Inputbox
                        label="RTSP Url"
                        placeholder="Enter RTSP Url"
                        id="rtsp_url"
                        name="rtsp_url"
                        value={formData?.feed_info?.rtsp_url || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="sm:col-span-3">
                      <Inputbox
                        label="Username"
                        placeholder="Enter Username"
                        id="username"
                        name="username"
                        value={formData?.feed_info?.username || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="sm:col-span-3">
                      <Inputbox
                        label="Password"
                        placeholder="Enter Password"
                        id="password"
                        type="password"
                        name="password"
                        value={formData?.feed_info?.password || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-5">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-r_color_gray-900">
              Advanced Settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-r_color_gray-600">
              Configure Frame and Object Detection Settings
            </p>
          </div>

          <div className="bg-white shadow-sm ring-1 ring-r_color_gray-900/5 sm:rounded-xl md:col-span-2 w-full ">
            <div className="px-4 py-6 sm:p-8 w-full">
              <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                <div className="sm:col-span-3">
                  <Inputbox
                    label="Frame Height"
                    placeholder="Enter Frame Height"
                    id="frame_height"
                    disabled={true}
                    name="frame_height"
                    value={formData.frame_height || 0}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Inputbox
                    label="Frame Width"
                    placeholder="Enter Frame Width"
                    id="frame_width"
                    disabled={true}
                    name="frame_width"
                    value={formData.frame_width || 0}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Inputbox
                    label="Frame Rate"
                    placeholder="Enter Frame Rate"
                    id="fps"
                    name="fps"
                    disabled={true}
                    value={formData.fps || 0}
                    onChange={handleChange}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Inputbox
                    label="Belt Stuck Duration"
                    placeholder="Enter Stuck Duration"
                    id="belt_stuck_duration"
                    name="belt_stuck_duration"
                    value={formData.belt_stuck_duration || 0} //change this
                    onChange={handleChange}
                  />
                </div>

                <div className="sm:col-span-3">
                  <SelectMenu
                    label="Objects to monitor"
                    selected={selectedObjectModal}
                    onChange={handleObjectDetechChange}
                    options={objectToDetechList}
                    placeholder={"Select Object Model"}
                    showAvatar={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

function RegionSettingForm({ orgid, selectedCamera }) {
  const [regions, setRegions] = useState([]); // Empty layers by default
  const [showUpdateRegionModal, setShowUpdateRegionModal] = useState(false); // Empty layers by default
  const handleRegionModalClose = () => {
    setShowUpdateRegionModal(false);
  };
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        let url = ApiEndpoints.GET_REGION_BY_CAMERAID(orgid, selectedCamera.id);
        const response = await fetch(url);
        const regions = await response.json();

        const formattedRegions = regions.map((region) => ({
          id: region.id,
          name: region.name,
          description: `Description for ${region.name}`,
          frame_height: 1080,
          frame_width: 1920,
          points: region.region.flat(), // Flatten the array of coordinates
        }));

        setRegions(formattedRegions); // Update state with the fetched regions
      } catch (error) {
        toast.error("Failed to fetch camera region info");
        console.error("Failed to fetch camera region info:", error);
      }
    };
    fetchRegions();
  }, [orgid, selectedCamera]);
  const [srcError, setSrcError] = useState({});

  const handleImageError = (cameraId) => {
    setSrcError((prevState) => ({
      ...prevState,
      [cameraId]: true,
    }));
  };
  return (
    <>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8  md:grid-cols-3  pt-5">
        <div className="px-4 sm:px-0 ">
          <h2 className="text-base font-semibold leading-7 text-r_color_gray-900">
            Region Settings
          </h2>
          <p className="mt-1 text-sm leading-6 text-r_color_gray-600">
            Add, remove Regions to detect objects
          </p>
        </div>

        <div className=" bg-white shadow-sm ring-1 ring-r_color_gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 w-full">
              <div className="sm:col-span-3 w-full">
                <table border="1" className=" w-full">
                  <tbody>
                    {regions.map((layer) => (
                      <tr key={layer.id}>
                        <td>
                          <Inputbox
                            label=""
                            placeholder=""
                            value={layer.name}
                            disabled={true}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  onClick={() => {
                    setShowUpdateRegionModal(true);
                  }}
                  className="rounded-md mt-6 bg-r_color_primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600"
                >
                  Show Region
                </button>
              </div>
              <div className="sm:col-span-3 h-56 ">
                <CameraThumbnail
                  camera={selectedCamera}
                  srcError={srcError}
                  handleImageError={handleImageError}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showUpdateRegionModal && (
        <>
          <AddRegionModal
            handleClose={handleRegionModalClose}
            selectedCamera={selectedCamera}
            orgid={orgid}
            regions={regions}
          ></AddRegionModal>
        </>
      )}
    </>
  );
}

function DeleteCameraSection({ orgid, selectedCamera, closeCameraTrouser }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // Empty layers by default
  const handleDeleteCamera = async () => {
    const cameraId = selectedCamera.id;
    const cameradeleteurl = ApiEndpoints.DELETE_CAMERA_BY_ID(orgid, cameraId);
    try {
      const response = await fetch(cameradeleteurl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success("Camera deleted successfully");
        closeCameraTrouser("fetchCameraList");
      } else {
        const errorData = await response.json();
        toast.error(errorData.errors || "Error deleting camera");
      }
    } catch (error) {
      console.error("Error deleting camera:", error);
      toast.error("An unexpected error occurred");
    } finally {
      setShowConfirmationModal(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pb-20  md:grid-cols-3  pt-5">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-r_color_gray-900">
            {LocalizationKeys.DELETE_CAMERA_TEXT}
          </h2>
          <p className="mt-1 text-sm leading-6 text-r_color_gray-600">
            {LocalizationKeys.DELETE_CAMERA_FORM_HEADING}
          </p>
        </div>

        <div className=" bg-white shadow-sm ring-1 ring-r_color_gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-6">
            <button
              onClick={() => {
                setShowConfirmationModal(true);
              }}
              className="rounded-md  bg-r_red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-r_red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_red-600"
            >
              {LocalizationKeys.DELETE_CAMERA_BTN_TEXT}
            </button>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          Icon={Ban}
          iconcss={"h-6 w-6 text-red-500"}
          iconbg={"bg-red-100"}
          confimationButtonBg={"bg-red-500 hover:bg-red-600"}
          confirmationText={"Delete"}
          writtenConfig={true}
          writtenConfigPassPharse={"DELETE"}
          heading="Delete Camera"
          subheading={
            <>
              <div className="text-gray-500 text-sm font-normal  leading-tight">
                Are you sure you want to delete
                <span className="text-gray-800 text-sm font-semibold  leading-tight ml-1">
                  {selectedCamera.name}
                </span>
                ?
                <div className=" mt-4">
                  <span className="text-gray-500 text-sm font-medium  leading-tight">
                    Type
                  </span>
                  <span className="text-gray-900 text-sm font-bold  leading-tight mx-1">
                    'DELETE'
                  </span>
                  <span className="text-gray-500 text-sm font-medium leading-tight">
                    to confirm
                  </span>
                </div>
              </div>
            </>
          }
          cancelText="Cancel"
          onConfirm={handleDeleteCamera}
          onCancel={() => {
            setShowConfirmationModal(false);
          }}
          open={showConfirmationModal}
          setOpen={setShowConfirmationModal}
        />
      )}
    </>
  );
}
