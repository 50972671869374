import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Dropdown3Dot from "./core-components/dropdownWithIcon";
import { LocalizationKeys } from "../localizationStrings/en";
import Badge from "./core-components/badge";
import {
  getAlertTypeIcon,
  getSeverityBgColor,
  getSeverityIcon,
  getSeverityTextColor,
} from "./utils/commonFunctions";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CameraAlertList({ listlabel, alertList, loading }) {
  const [recentEventList, setRecentEventList] = useState([]);

  const handleResolve = () => {
    console.log("Account settings clicked");
  };

  const handleIgnore = () => {
    console.log("Ignore clicked");
  };

  const menuItems = [
    { label: "Mark as Resolved", onClick: handleResolve },
    { label: "Ignore this Alert", onClick: handleIgnore },
  ];
  useEffect(() => {
    //take first 5 events from cameraEventList set setRecentEventList
    if (alertList) {
      // Take the first 5 events from cameraEventList
      const recentEvents = alertList.slice(0, 50);
      setRecentEventList(recentEvents);
    }
  }, [alertList]);

  const renderWidget = () => {
    // Handle the case loading present
    if (loading) {
      return (
        <div className=" w-full  px-4 py-3 mt-3 bg-r_color_base rounded-md shadow flex-col justify-start items-center gap-3 inline-flex">
          <div className="max-h-full   w-full  overflow-scroll gap-2 flex flex-col ">
            {Array.from({ length: 100 }).map((_, index) => (
              <div
                key={index}
                className="w-full   min-h-[52px] bg-r_color_gray-300  animate-pulse "
              />
            ))}
          </div>
        </div>
      );
    }
    if (recentEventList.length === 0) {
      return (
        <div className="bg-r_color_base p-4 mt-3 flex flex-col rounded-md shadow-sm gap-2 ">
          <div className="flex justify-center py-6  ">
            <div className="text-center text-r_color_gray-200 de text-base font-bold  uppercase leading-7">
              {LocalizationKeys.NO_ISSUES}
            </div>
          </div>
        </div>
      );
    }

    return (
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-1 sm:gap-6 "
      >
        {recentEventList.map((alert, index) => (
          <li key={alert.id} className="col-span-1 flex rounded-md shadow-sm">
            <div
              className={classNames(
                "flex  flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium bg-r_color_base text-white pl-2"
              )}
            >
              <Badge
                label={alert.severity}
                customIcon={getSeverityIcon(alert.severity)}
                iconPosition="left"
                backgroundcolor={getSeverityBgColor(alert.severity)}
                textcolor={getSeverityTextColor(alert.severity)}
              ></Badge>
            </div>
            <div className="flex flex-1 items-center justify-between  rounded-r-md border-b border-r border-t border-gray-200 bg-white overflow-visible">
              <div className="flex-1 truncate px-4 py-2 text-sm ">
                <a
                  href={alert.href}
                  className="font-medium text-gray-900 hover:text-gray-600"
                >
                  {alert.message}
                </a>
                <p className="text-gray-500">
                  {formatDistanceToNow(new Date(alert.timestamp * 1000), {
                    addSuffix: true,
                  })}
                </p>
              </div>
              <div className="flex-shrink-0 pr-2">
                {/* <Dropdown3Dot items={menuItems}></Dropdown3Dot> */}
                <span className="px-6 mr-4">
                  {getAlertTypeIcon(alert.event_type)}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className="flex flex-col h-full">
      <div className="text-gray-500   text-xs font-medium uppercase leading-none tracking-tight">
        {listlabel}
      </div>
      <div className=" flex-1 overflow-scroll max-h-trouserAlertListContainer ">
        {renderWidget()}
      </div>
    </div>
  );
}
