import Navbar from "../../navbar";
import PageHeader from "./pageheader";
import SystemInfoCard from "./systeminfocard";
import VideoAlertTrackerCard from "./videoAlertTrackerCard";
import TabsV2 from "./tabv2";
import MainContainerDropdown from "./maincontainer_dropdown";
import { LocalizationKeys } from "../../../localizationStrings/en";
import dashboard_icon from "../../../assets/Dashboard.svg";
import devices_icon from "../../../assets/Devices.svg";
import report_icon from "../../../assets/document.svg";
import site_icon from "../../../assets/Sites.svg";

import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import MainContainerCameraList from "./CameraListMainContainer";
import AlertBar from "./alertbar";
import Login from "../login";
import GlobalAlerts from "./globalAlert";
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";
import ChatbotModal from "../../chat-bot/chat-bot-modal";
import { BOT_ENUM } from "../../chat-bot/botEnums";
import { PlusIcon } from "@heroicons/react/20/solid";
import AddCameraFlow from "../../addCameraModal";

import ApiEndpoints from "../../utils/APIEndpoints";
import "react-tooltip/dist/react-tooltip.css";
import { downloadFile } from "../../utils/commonFunctions";

export default function MasterLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarClick = () => {
    setSidebarOpen(true);
  };
  const [cameraList, setCameraList] = useState([]);
  const [cameraListLoading, setcameraListLoading] = useState(true);

  const [sortedCameraList, setSortedCameraList] = useState([]);
  const [orgid, setOrgId] = useState("org_ramennetworks");
  const durationOptions = [
    { name: "Past 15 minutes", value: "15m", id: 1 },
    { name: "Past 1 hours", value: "1h", id: 2 },
    { name: "Past 24 hours", value: "24h", id: 3 },
    { name: "Past 7 days", value: "7d", id: 4 },
  ];

  const [duration, setDuration] = useState(durationOptions[2].value);
  const [authenticated, setAuthenticated] = useState(false);
  const [showRamenAIClient, setShowRamenAIClient] = useState(false);
  const [isCameraTrouserOpen, setisCameraTrouserOpen] = useState(false);
  const [cameraEventList, setCameraEventList] = useState([]);
  const [cameraEventLoading, setCameraEventLoading] = useState(true);
  const [ramenAssistWindowSize, setRamenAssistWindowSize] = useState(
    BOT_ENUM.WINDOW_REGULARSIZE
  );

  const [selectedTab, setSelectedTab] = useState(LocalizationKeys.SUMMARYTAB);
  const [widgetLastRefreshedTime, setWidgetLastRefreshedTime] = useState(
    Date.now()
  );

  const [showAddCameraFlow, setShowAddCameraFlow] = useState(false);
  const handleCloseCameraFlow = () => {
    setShowAddCameraFlow(false);
  };

  const handleTabChange = (tabName) => {
    let sortedList = [...cameraList];
    switch (tabName) {
      case "By # of Alerts":
        sortedList.sort((a, b) => b.alerts.total - a.alerts.total);
        break;
      case "By Status":
        sortedList.sort((a, b) => {
          if (a.status === b.status) return 0;
          return a.status ? -1 : 1;
        });
        break;
      case "By Name":
        sortedList.sort((a, b) => a.name.localeCompare(b.name));
        break;
      default:
        break;
    }
    setSortedCameraList(sortedList);
  };

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    const fetchCameraList = async () => {
      try {
        const getcamera_url = ApiEndpoints.GET_CAMERA(orgid);
        const response = await fetch(getcamera_url);
        const cameraData = await response.json();

        // Fetch camera event stats
        const getCameraEventStatsUrl = ApiEndpoints.GET_CAMERA_EVENTS_STATS(
          orgid,
          duration
        );
        const eventStatsResponse = await fetch(getCameraEventStatsUrl);
        const eventStatsData = await eventStatsResponse.json();

        // Merge camera data with event stats
        const mergedData = cameraData.map((camera) => ({
          ...camera,
          alerts: eventStatsData[camera.id] || { total: 0 },
        }));

        setCameraList(mergedData);

        setSortedCameraList(mergedData);
        setcameraListLoading(false);
      } catch (error) {
        setcameraListLoading(false);
        toast.error("Failed to fetch camera list");
        console.error("Failed to fetch camera list:", error);
      }
    };

    const fetchEventsForAllCamera = async () => {
      try {
        const getCameraEventsUrl = ApiEndpoints.GET_CAMERA_EVENTS(
          orgid,
          duration
        );
        const response = await fetch(getCameraEventsUrl);
        const data = await response.json();
        setCameraEventList(data);
        setCameraEventLoading(false);
      } catch (error) {
        setCameraEventLoading(false);
        toast.error("Failed to fetch camera EventList");
        console.error("Failed to fetch camera EventList:", error);
      }
    };
    if (isCameraTrouserOpen === false) {
      setCameraEventLoading(true);
      fetchCameraList();
      fetchEventsForAllCamera();
    }
  }, [
    orgid,
    widgetLastRefreshedTime,
    authenticated,
    duration,
    isCameraTrouserOpen,
  ]);

  const handleLogin = () => {
    setAuthenticated(true);
  };

  const handleFetchCameraList = () => {
    setWidgetLastRefreshedTime(Date.now());
  };
  const handleAddCamera = () => {
    setWidgetLastRefreshedTime(Date.now());
    setShowAddCameraFlow(false);
  };

  if (!authenticated) {
    return <Login onLogin={handleLogin} />;
  }

  const handleLogout = () => {
    setAuthenticated(false);
  };
  const toggleRamenAssit = () => {
    setShowRamenAIClient((prev) => !prev);
  };

  const toggleRamenAssitWindowSize = () => {
    setRamenAssistWindowSize((windowSize) =>
      windowSize === BOT_ENUM.WINDOW_REGULARSIZE
        ? BOT_ENUM.WINDOW_MAXIMIZE
        : BOT_ENUM.WINDOW_REGULARSIZE
    );
  };

  const masterPageTabs = [
    { name: LocalizationKeys.SUMMARYTAB, href: "#", current: true },
    { name: LocalizationKeys.ALERTTAB, href: "#", current: false },
  ];
  function handleMasterPageTabClick(tabName) {
    console.log(`Tab selected: ${tabName}`);
    setSelectedTab(tabName);
    // Additional logic when a tab is selected
  }
  function viewAllGlobalAlerts() {
    setSelectedTab(LocalizationKeys.ALERTTAB);
  }

  const renderMainContent = () => {
    if (selectedTab === LocalizationKeys.SUMMARYTAB) {
      return (
        <div className=" lg:grid  sm:px-6  w-full h-full flex flex-col md:grid-cols-12 lg:gap-x-14">
          <div className="flex  camera_stats_column col-span-4 flex-col gap-6  pt-0 sm:pt-11   overflow-auto">
            <SystemInfoCard
              widgetLastRefreshedTime={widgetLastRefreshedTime}
              orgid={orgid}
              duration={duration}
              isCameraTrouserOpen={isCameraTrouserOpen}
            />
            <AlertBar
              orgid={orgid}
              widgetLastRefreshedTime={widgetLastRefreshedTime}
              duration={duration}
              isCameraTrouserOpen={isCameraTrouserOpen}
            />
            <VideoAlertTrackerCard
              orgid={orgid}
              viewAllGlobalAlerts={viewAllGlobalAlerts}
              widgetLastRefreshedTime={widgetLastRefreshedTime}
              cameraEventList={cameraEventList}
              loading={cameraEventLoading}
              isCameraTrouserOpen={isCameraTrouserOpen}
            />
          </div>
          <div className="flex   video_listing col-span-8 flex-col">
            <div className="flex mt-4  justify-between items-center border-b border-r_color_gray-300 pb-2.5">
              <MainContainerDropdown></MainContainerDropdown>
              <TabsV2 handleTabChange={handleTabChange}></TabsV2>
            </div>
            <div className=" max-h-mainContainerHeight  overflow-auto h-full">
              <MainContainerCameraList
                orgid={orgid}
                cameraList={sortedCameraList}
                cameraListLoading={cameraListLoading}
                toggleRamenAssit={toggleRamenAssit}
                showRamenAIClient={showRamenAIClient}
                widgetLastRefreshedTime={widgetLastRefreshedTime}
                fetchCameraList={handleFetchCameraList}
                duration={duration}
                durationOptions={durationOptions}
                isCameraTrouserOpen={isCameraTrouserOpen}
                setisCameraTrouserOpen={setisCameraTrouserOpen}
              ></MainContainerCameraList>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <GlobalAlerts
          widgetLastRefreshedTime={widgetLastRefreshedTime}
          orgid={orgid}
          alertList={cameraEventList}
          isLoading={cameraEventLoading}
          duration={duration}
          durationOptions={durationOptions}
          handleDurationChange={handleDurationChange}
        ></GlobalAlerts>
      );
    }
  };

  function handleMasterPageTabClick(tabName) {
    console.log(`Tab selected: ${tabName}`);
    setSelectedTab(tabName);
    // Additional logic when a tab is selected
  }
  function viewAllGlobalAlerts() {
    setSelectedTab(LocalizationKeys.ALERTTAB);
  }
  const ontimerComplete = () => {
    setWidgetLastRefreshedTime(Date.now());
  };

  const handleGlobalStatsDownload = async (selectedOption) => {
    let url = "";
    if (selectedOption.value === "rawStats") {
      url = ApiEndpoints.DOWNLOAD_STATS_RAW(orgid, null, duration);
    } else {
    }
    url = ApiEndpoints.DOWNLOAD_STATS(orgid, null, duration);
    const fileName = `global_raw_stats.xlsx`;
    await downloadFile(url, fileName);
  };

  const handleDurationChange = (duration) => {
    setDuration(duration.value);
  };

  return (
    <>
      <div className="h-full ">
        {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
        <div className="flex flex-col h-full ">
          <TopNav
            handleSidebarClick={handleSidebarClick}
            toggleRamenAssit={toggleRamenAssit}
            showRamenAIClient={showRamenAIClient}
            handleLogout={handleLogout}
          />

          {/* <main className="flex-1 lg:pl-20  "> */}
          <main className="flex-1   ">
            <div className=" h-full flex flex-col ">
              <PageHeader
                heading={"Ramen Eye"}
                subheading={LocalizationKeys.PageHeaderSubheading}
                org_id={orgid}
                onCameraAdded={() => {
                  handleFetchCameraList();
                }}
                customcss={"p-6  sm:px-6 sm:py-0 mt-6 "}
                headerTabs={masterPageTabs}
                handleTabClick={handleMasterPageTabClick}
                ontimerComplete={ontimerComplete}
                showDownloadBtn={true}
                handleGlobalStatsDownload={handleGlobalStatsDownload}
                selectedTab={selectedTab}
              >
                <button
                  type="button"
                  onClick={() => setShowAddCameraFlow(true)}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-r_color_primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-r_color_primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-600"
                >
                  <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                  {LocalizationKeys.Add_Camera}
                </button>
              </PageHeader>
              <div className="flex-1  px-6  sm:p-0  ">
                {renderMainContent()}
              </div>
            </div>
          </main>
        </div>
      </div>
      <ChatbotModal
        statistics={[]}
        chartData={[]}
        showRamenAIClient={showRamenAIClient}
        averages={[]}
        onClose={toggleRamenAssit}
        viewIn={ramenAssistWindowSize}
        toggleRamenAssitWindowSize={toggleRamenAssitWindowSize}
      ></ChatbotModal>
      {showAddCameraFlow && (
        <AddCameraFlow
          handleClose={handleCloseCameraFlow}
          orgId={orgid}
          onCameraAdded={handleAddCamera}
        />
      )}
      <ToastContainer className={"z-[9999]"} />
    </>
  );
}
