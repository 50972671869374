import AddCameraFlow from "../../addCameraModal";
import { useState } from "react";
import HeaderTabs from "../../core-components/tabswithUnderline";
import RefreshTimerButton from "../../core-components/refreshTimerButton";
import { ReactComponent as DownloadIcon } from "./../../../assets/Download.svg";
import ButtonWithDropdown from "../../core-components/buttonWIthDropdown";

export default function PageHeader({
  heading,
  subheading,
  org_id,
  onCameraAdded,
  customcss,
  headerTabs,
  handleTabClick,
  ontimerComplete,
  showDownloadBtn,

  handleGlobalStatsDownload,
  selectedTab,
  children,
  showTimerBtn = true,
}) {
  const [showCameraFlow, setShowCameraFlow] = useState(false);
  const handleCloseCameraFlow = () => {
    setShowCameraFlow(false);
  };

  return (
    <>
      <div className={`  flex flex-col    ${customcss}`}>
        <div className="w-full flex flex-col md:flex-row gap-y-2 ">
          <div className=" flex-1">
            <h3 className="text-2xl font-bold  leading-8 text-r_color_gray-900">
              {heading}
            </h3>
            <p className="mt-2 max-w-5xl text-sm font-normal leading-5 text-r_color_gray-500">
              {subheading}
            </p>
          </div>
          <div className="flex items-center  gap-x-5">
            {showDownloadBtn && (
              <ButtonWithDropdown
                options={[
                  { name: "Raw Stats", value: "rawStats" },
                  { name: "Processed Stats", value: "processedStats" },
                ]}
                label="Download Stats"
                onOptionClick={handleGlobalStatsDownload}
              ></ButtonWithDropdown>
            )}
            {showTimerBtn && (
              <RefreshTimerButton onTimerComplete={ontimerComplete} />
            )}
            {children}
          </div>
        </div>
        {headerTabs && (
          <HeaderTabs
            tabs={headerTabs}
            onTabClick={handleTabClick}
            selectedTab={selectedTab}
          ></HeaderTabs>
        )}
        {showCameraFlow && (
          <AddCameraFlow
            handleClose={handleCloseCameraFlow}
            orgId={org_id}
            onCameraAdded={onCameraAdded}
          />
        )}
      </div>
    </>
  );
}
