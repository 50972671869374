import ramenColors from "./ramen-colors";
// CustomTooltip.js
import React from "react";
import { transparent } from "tailwindcss/colors";

const secondarycolor = ramenColors.r_color_secondary;
const generateDefsAndPatterns = (keys) => {
  const patterns = [
    {
      id: "dots",
      type: "patternDots",
      background: secondarycolor[600],
      color: secondarycolor[500],
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: secondarycolor[50],
      color: secondarycolor[300],
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
    {
      id: "squares-pattern",
      type: "patternSquares",
      size: 4,
      padding: 4,
      stagger: false,
      background: secondarycolor[800],
      color: secondarycolor[700],
    },
    {
      id: "dots",
      type: "patternDots",
      background: secondarycolor[300],
      color: secondarycolor[600],
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: secondarycolor[600],
      color: secondarycolor[700],
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
    {
      id: "lines",
      type: "patternLines",
      background: secondarycolor[50],
      color: secondarycolor[100],
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
    {
      id: "lines",
      type: "patternLines",
      background: secondarycolor[500],
      color: secondarycolor[400],
      rotation: -90,
      lineWidth: 6,
      spacing: 10,
    },
    // Add more patterns if needed
  ];

  const defs = [];
  const fill = [];
  const colors = [];

  keys?.forEach((key, index) => {
    const pattern = patterns[index % patterns.length];
    const patternId = `${pattern.id}-${index}`;

    defs.push({
      ...pattern,
      id: patternId,
    });

    fill.push({
      match: {
        id: key,
      },
      id: patternId,
    });
    colors.push(pattern.background);
  });

  return { defs, fill, colors };
};

const chartTheme = {
  axis: {
    ticks: {
      line: {
        stroke: "#555555",
      },
      text: {
        fill: ramenColors.r_color_gray["500"],
        fontSize: 12,
        fontFamily: "Inter var, sans-serif",
      },
    },
  },
  grid: {
    line: {
      stroke: "#dddddd",
      strokeDasharray: "4 4",
    },
  },

  legend: {
    text: {
      fontSize: 6,
      fill: ramenColors.r_green["500"],
    },
  },
  tooltip: {
    container: {
      background: "#ffffff",
      color: "#333333",
      fontSize: "12px",
      borderRadius: "2px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
      padding: "5px 9px",
    },
  },
  labels: {
    text: {
      fill: "red",
    },
  },
};

const CustomTooltip = ({
  datum,
  scale = "linear",
  formatYAxis = (value) => value,
}) => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-none">
      <div className="border-t border-r_color_gray-100">
        <dl className="divide-y divide-r_color_gray-100">
          <div className="p-2 flex items-center gap-1">
            <dt className="text-sm font-medium  flex ">
              <div
                className="w-4 h-4 mr-1 block "
                style={{ background: datum.color, color: transparent }}
              >
                .
              </div>
              <span className="font-light text-r_color_gray-700 truncate">
                {datum.data.label} -{" "}
              </span>
            </dt>
            <dd className="text-sm leading-6 text-r_color_gray-900 font-semibold">
              {scale === "linear"
                ? datum.data.transformedValue
                  ? datum.data.transformedValue
                  : datum.data.value
                : formatYAxis(datum.value)}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

const ChartLegend = ({ data, colors, defs }) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      {data?.map((item, index) => {
        const patternDef = defs[index];
        const fill = colors[index];

        return (
          <div key={item.id} className="flex items-center">
            <div className="w-4 h-4 mr-2">
              {patternDef ? (
                <svg width="100%" height="100%">
                  <rect
                    width="100%"
                    height="100%"
                    fill={`url(#${patternDef.id})`}
                  />
                  <defs>
                    <pattern
                      id={patternDef.id}
                      patternUnits="userSpaceOnUse"
                      width={patternDef.size || 10}
                      height={patternDef.size || 10}
                      patternTransform={
                        patternDef.rotation
                          ? `rotate(${patternDef.rotation})`
                          : undefined
                      }
                    >
                      <rect
                        width="100%"
                        height="100%"
                        fill={patternDef.background}
                      />
                      {patternDef.type === "patternDots" && (
                        <circle
                          cx={patternDef.size / 2}
                          cy={patternDef.size / 2}
                          r={patternDef.size / 4}
                          fill={patternDef.color}
                        />
                      )}
                      {patternDef.type === "patternLines" && (
                        <path
                          d={`M0 ${patternDef.size / 2} L${patternDef.size} ${
                            patternDef.size / 2
                          }`}
                          stroke={patternDef.color}
                          strokeWidth={patternDef.lineWidth}
                        />
                      )}
                    </pattern>
                  </defs>
                </svg>
              ) : (
                <div
                  style={{
                    backgroundColor: fill,
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>
            <span className="text-sm font-medium text-r_color_gray-700">
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export { CustomTooltip };
export { chartTheme };

export { generateDefsAndPatterns };
export { ChartLegend };
