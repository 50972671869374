import axios from "axios";
import ApiEndpoints from "../../utils/APIEndpoints";

const fetchOrgs = async () => {
  const response = await axios.get("/get_orgs");
  console.log("Fetched organizations--------:", response.data);
  return response.data;
};

const fetchSites = async (selectedOrg) => {
  const response = await axios.get(`/get_sites?org_id=${selectedOrg}`);
  return response.data;
};
const fetchCameras = async (orgid) => {
  try {
    const getcamera_url = ApiEndpoints.GET_CAMERA(orgid);
    const response = await fetch(getcamera_url);
    const cameraData = await response.json();

    // // Fetch camera event stats
    // const getCameraEventStatsUrl = ApiEndpoints.GET_CAMERA_EVENTS_STATS(orgid);
    // const eventStatsResponse = await fetch(getCameraEventStatsUrl);
    // const eventStatsData = await eventStatsResponse.json();

    // // Merge camera data with event stats
    // const mergedData = cameraData.map((camera) => ({
    //   ...camera,
    //   alerts: eventStatsData[camera.name] || { total: 0 },
    // }));

    // Return the merged data instead of setting state
    return cameraData;
  } catch (error) {
    console.error("Failed to fetch camera list:", error);
    return error("Failed to fetch camera list");
  }
};

export { fetchOrgs, fetchSites, fetchCameras };
