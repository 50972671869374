import { useState, useRef } from "react";
import video_camera_icon from "../assets/videocamera.svg";
import exclamation_circle_icon from "../assets/exclamation_circle.svg";
import HLSVideoPlayerWithSegmentEvent from "./layout-components/feed/VideoFeedv2";

export const CameraThumbnail = ({ camera, srcError, handleImageError }) => {
  const [imageHovered, setImageHovered] = useState(false);
  const hoverTimeoutRef = useRef(null);

  const handleMouseEnter = () => {
    // Set a timeout to delay the hover effect for 2 seconds (2000 ms)
    hoverTimeoutRef.current = setTimeout(() => {
      setImageHovered(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    // Clear the timeout if the mouse leaves before 2 seconds
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    setImageHovered(false);
  };

  // Render logic based on the camera.thumbnail and srcError states
  if (camera.thumbnail === "") {
    return (
      <div className="flex aspect-h-4 aspect-w-3 bg-r_color_gray-200 sm:aspect-none group-hover:opacity-75 h-full max-h-[220px] sm:h-[220px] w-full">
        <div className="flex flex-col justify-center items-center flex-1 bg-r_color_gray-800  h-full ">
          <img
            src={video_camera_icon}
            alt="Video camera icon"
            className="h-16 w-16 object-center"
          />
          <div className="text-center text-r_color_gray-400 text-sm font-normal leading-tight">
            No thumbnail yet
          </div>
        </div>
      </div>
    );
  } else if (srcError[camera.id]) {
    return (
      <div className="flex w-full aspect-h-4 aspect-w-3 bg-r_color_gray-200 sm:aspect-none group-hover:opacity-75 sm:h-40 ">
        <div className="flex flex-col justify-center items-center h-full flex-1 bg-r_color_gray-800">
          <img
            src={exclamation_circle_icon}
            alt="Error icon"
            className="h-16 w-16 object-center"
          />
          <div className="text-center text-r_color_gray-400 text-sm font-normal leading-tight">
            Error in fetching thumbnail
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative h-full w-full max-h-[220px]">
        {imageHovered ? (
          <div
            // onMouseLeave={handleMouseLeave}
            className="h-full w-full bg-r_color_gray-900 text-white"
          >
            <HLSVideoPlayerWithSegmentEvent
              src={`/hls/${camera.id}/playlist.m3u8`}
              showTimeScale={false}
            />
          </div>
        ) : (
          <div
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            className="flex w-full aspect-h-4 aspect-w-3 bg-r_color_gray-200 sm:aspect-none group-hover:opacity-75 sm:h-full"
          >
            <img
              alt={camera.imageAlt || "Camera thumbnail"}
              src={`data:image/jpeg;base64,${camera.thumbnail}`}
              className="h-full w-full object-cover object-center sm:h-full sm:w-full"
              onError={() => handleImageError(camera.id)}
            />
          </div>
        )}
      </div>
    );
  }
};
