import React, { useEffect, useState } from "react";

import { BOT_ENUM } from "./botEnums";
import ChatBotV3 from "./chatbotv3";

export default function ChatbotMessageArea({
  statistics,
  chartData,
  averages,
  viewIn,
}) {
  return (
    <main
      className={`${
        viewIn === BOT_ENUM.WINDOW_REGULARSIZE ? "col-span-12 " : "col-span-6"
      } overflow-y-scroll  flex flex-col  border-2 `}
    >
      <div className="flex justify-center items-center w-full " id="chatbotv3">
        <ChatBotV3
          statistics={statistics}
          chartData={chartData}
          averages={averages}
          windowSize={viewIn}
        ></ChatBotV3>
      </div>
    </main>
  );
}
