import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import user_logo from "../../chat-bot/assets/Avatar.svg";
import { useEffect, useState } from "react";

export default function SelectOptionsWidgetv2({ updateConfig, options }) {
  const [showMenu, setShowMenu] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentOptions(options);
    }, 1000);
  }, [options]);

  const handleOptionClick = (option) => {
    updateConfig(option);
  };
  useEffect(() => {
    setShowMenu(showMenu);
  }, [showMenu]);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="relative flex  w-full">
      {showMenu && (
        <ul
          role="list"
          className=" absolute z-50  right-0 bottom-0  mb-12 divide-y  w-full max-w-[80%] mr-8 divide-gray-100 overflow-hidden bg-r_color_base  shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
          {currentOptions.map((option) => (
            <li
              key={option.text}
              className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a>
                      <span className="absolute inset-x-0 -top-px bottom-0" />
                      {option.text}
                    </a>
                    {option.subheading && (
                      <div className="text-gray-500 text-sm font-normal leading-tight">
                        {option.subheading}
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <img
        alt=""
        onClick={toggleMenu}
        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        className="inline-block absolute bottom-0 right-0 h-14 w-14 rounded-full cursor-pointer z-50 "
      />
    </div>
  );
}
