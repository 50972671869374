import { useEffect, useState } from "react";
import factory_image from "../../../../assets/factory-robot.jpg";
import CameraOverviewCardInfoList from "./camera-overview-card-info-list";
import Button from "../../../core-components/button";
import Dropdown3Dot from "../../../core-components/dropdownWithIcon";
import { toast } from "react-toastify";
import { CameraThumbnail } from "../../../thumbnail";
import { LocalizationKeys } from "../../../../localizationStrings/en";
import downloadExcel, {
  downloadCameraStats,
} from "../../../core-components/downloadExcel";
import ApiEndpoints from "../../../utils/APIEndpoints";
import { downloadFile } from "../../../utils/commonFunctions";

export default function CameraOverviewCard({
  selectedCamera,
  orgid,
  duration,
  refreshData,
  cameraGraphData,
  alertList,
}) {
  const [cameraInfo, setCameraInfo] = useState([]);
  const [srcError, setSrcError] = useState({});

  const handleImageError = (cameraId) => {
    setSrcError((prevState) => ({
      ...prevState,
      [cameraId]: true,
    }));
  };

  const downloadProcessedStats = async () => {
    const url = ApiEndpoints.DOWNLOAD_STATS(orgid, selectedCamera.id, duration);
    const fileName = `${selectedCamera.name}_processed_stats.xlsx`;
    await downloadFile(url, fileName);
  };

  const downloadRawStats = async () => {
    const url = ApiEndpoints.DOWNLOAD_STATS_RAW(
      orgid,
      selectedCamera.id,
      duration
    );
    const fileName = `${selectedCamera.name}_raw_stats.xlsx`;
    await downloadFile(url, fileName);
  };

  useEffect(() => {
    setCameraInfo(selectedCamera);
  }, [selectedCamera, orgid, refreshData]);
  return (
    <div className="lg:max-w-[449px] bg-r_color_base rounded-b-lg   ">
      <article className="flex flex-col items-start justify-between">
        <div className="relative w-full lg:w-[448px]  h-56 rounded-2xl ring-1 ring-inset ring-r_color_gray-900/10 ">
          <CameraThumbnail
            camera={selectedCamera}
            srcError={srcError}
            handleImageError={handleImageError}
          />
          {/* <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-r_color_gray-900/10" /> */}
        </div>
        <div className="lg:max-w-xl w-full">
          <div className=" flex flex-col  text-xs w-full">
            <div className=" px-6">
              <div>
                <div className="text-r_color-r_color_gray-900 mt-2 text-2xl font-bold leading-8">
                  {cameraInfo.name}{" "}
                  <span
                    className={`h-2 w-2 rounded-full  inline-block  ${
                      cameraInfo.status === "Online"
                        ? "bg-r_green-400"
                        : "bg-r_color_gray-400"
                    }`}
                  ></span>
                </div>
                <div className="text-r_color-500 text-sm font-normal leading-5">
                  {LocalizationKeys.MANUFACTURER}{" "}
                  <span className="ml-1 "> {cameraInfo.vendor}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="group relative  px-6 pb-6">
            <CameraOverviewCardInfoList
              cameraGraphData={cameraGraphData}
              cameraInfo={cameraInfo}
              alertList={alertList}
              duration={duration}
              refreshData={refreshData}
            ></CameraOverviewCardInfoList>
            <div className="lg:flex justify-between gap-x-2 ">
              <button
                type="button"
                onClick={downloadRawStats}
                className="rounded-md w-full truncate bg-r_color_base px-3 py-2 text-sm font-semibold text-r_color_gray-900 shadow-sm ring-1 ring-inset ring-r_color_gray-300 hover:bg-r_color_gray-50"
              >
                Download Raw stats
              </button>
              <button
                type="button"
                onClick={downloadProcessedStats}
                className="rounded-md w-full truncate bg-r_color_primary-600 px-3 py-2 text-sm font-semibold text-r_color_base shadow-sm hover:bg-r_color_primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-r_color_primary-500"
              >
                Download Stats
              </button>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}
