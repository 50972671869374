import React, { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel, // Import getSortedRowModel
  useReactTable,
} from "@tanstack/react-table";

import { useVirtualizer } from "@tanstack/react-virtual";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TableBordered({
  dataset,
  columns,
  appliedGlobalSearch,
  isloading,
}) {
  const [filteredData, setFilteredData] = useState(dataset);
  const [sorting, setSorting] = useState([]); // Add sorting state

  useEffect(() => {
    if (appliedGlobalSearch) {
      const lowercasedSearch = appliedGlobalSearch.toLowerCase();
      const filtered = dataset.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(lowercasedSearch)
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(dataset);
    }
  }, [appliedGlobalSearch, dataset]);

  const table = useReactTable({
    data: filteredData,
    columns,
    state: { sorting }, // Add sorting state
    onSortingChange: setSorting, // Add sorting handler
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // Add sorted row model
  });

  const [tableHeight, setTableHeight] = useState(400); // Default height

  const rows = table.getRowModel().rows;

  const tableContainerRef = React.useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50,
    getScrollElement: () => tableContainerRef.current,
    overscan: 100,
  });

  useEffect(() => {
    const updateTableHeight = () => {
      if (tableContainerRef.current) {
        const parentElement = tableContainerRef.current.parentElement;
        const parentHeight = parentElement.clientHeight;
        const offsetTop = tableContainerRef.current.offsetTop;
        const availableHeight = parentHeight;
        console.log(
          `parent ${parentElement} , parent height ${parentHeight} offsetTop ${offsetTop} available ${availableHeight}`
        );

        setTableHeight(availableHeight);
      }
    };

    updateTableHeight();

    window.addEventListener("resize", updateTableHeight);
    return () => window.removeEventListener("resize", updateTableHeight);
  }, []);

  return (
    <div className="h-full w-full">
      <div
        className="-mx-4 w-full ring-1 ring-r_color_gray-300 sm:mx-0 sm:rounded-lg overflow-y-auto"
        ref={tableContainerRef}
        style={{ maxHeight: `${tableHeight}px` }}
      >
        <table className="min-w-full divide-y grid divide-r_color_gray-300 border-separate border-spacing-0">
          <thead className="sticky grid top-0 z-10 bg-r_color_gray-50 bg-opacity-75 backdrop-blur backdrop-filter">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="flex w-full border-b border-r_color_gray-300"
              >
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={classNames(
                      "flex items-center px-3 py-3.5 text-left text-xs font-medium text-r_color_gray-500 leading-none tracking-wide uppercase",
                      index === 0
                        ? "sm:pl-6 lg:pl-8 py-3.5 pl-4 pr-3 rounded-tl-lg"
                        : "",
                      index === columns.length - 1 ? "rounded-tr-lg" : ""
                    )}
                    style={{
                      display: "flex",
                      width: header.getSize(),
                      cursor: header.column.getCanSort()
                        ? "pointer"
                        : "default", // Change cursor if sortable
                    }}
                    onClick={header.column.getToggleSortingHandler()} // Make header clickable
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort() && (
                      <span className="ml-2">
                        {{
                          asc: " 🔼", // Ascending sort indicator
                          desc: " 🔽", // Descending sort indicator
                        }[header.column.getIsSorted()] ?? null}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="bg-r_color_base"
            style={{
              display: "grid debug",
              height: isloading
                ? `${48 * 50}px`
                : `${rowVirtualizer.getTotalSize() || 50}px`, // Ensures there's a height even when no rows
              position: "relative", // needed for absolute positioning of rows
            }}
          >
            {isloading ? (
              Array.from({ length: 50 }).map((_, index) => (
                <tr key={index} className="flex w-full">
                  <td
                    colSpan={columns.length}
                    className={classNames(
                      "border-b border-r_color_gray-200 h-14 w-full animate-pulse",
                      index % 2 === 0
                        ? "bg-r_color_gray-200 "
                        : "bg-r_color_gray-50"
                    )}
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  ></td>
                </tr>
              ))
            ) : rowVirtualizer.getVirtualItems().length === 0 ? (
              <tr className="flex w-full">
                <td
                  colSpan={columns.length}
                  className="flex justify-center  w-full items-center py-5 text-sm text-r_color_gray-500"
                >
                  No Data Available
                </td>
              </tr>
            ) : (
              rowVirtualizer.getVirtualItems().map((virtualRow, dataIndex) => {
                const row = rows[virtualRow.index];
                return (
                  <tr
                    data-index={virtualRow.index} // needed for dynamic row height measurement
                    ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
                    key={row.id}
                    className={classNames(
                      "border-b border-r_color_gray-200",
                      virtualRow.index % 2 === 0
                        ? "bg-r_color_base"
                        : "bg-r_color_gray-50" // Alternating row colors
                    )}
                    style={{
                      display: "flex",
                      position: "absolute",
                      transform: `translateY(${virtualRow.start}px)`,
                      width: "100%",
                    }}
                  >
                    {row.getVisibleCells().map((cell, columnIndex) => {
                      return (
                        <td
                          key={cell.id}
                          style={{
                            display: "flex",
                            width: cell.column.getSize(),
                          }}
                          className={classNames(
                            columnIndex === 0
                              ? "relative py-4 pl-4 pr-3 text-sm sm:pl-6 lg:pl-8 text-r_color_primary-500 font-bold leading-tight"
                              : "px-3 py-3.5 text-sm text-r_color_gray-500 lg:table-cell font-normal leading-tight",
                            columnIndex === columns.length - 1 &&
                              "relative py-3.5 pl-3 pr-4 text-sm font-medium sm:pr-6"
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
