import { PaperClipIcon } from "@heroicons/react/20/solid";

import ramen_logo from "../../chat-bot/assets/logo.svg";
export default function TableView({ heading, subheading, data }) {
  return (
    <div className=" flex w-full py-4">
      <div className="p-2">
        <img src={ramen_logo} width={40} height={40} alt="ramen-logo" />
      </div>
      <table className="max-w-[80%] bg-white border border-gray-200 rounded-lg overflow-hidden">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left text-gray-600 font-medium">
              Region Name
            </th>
            <th className="px-4 py-2 text-left text-gray-600 font-medium">
              Past 1 Hour
            </th>
            <th className="px-4 py-2 text-left text-gray-600 font-medium">
              Past 30 Minutes
            </th>
            <th className="px-4 py-2 text-left text-gray-600 font-medium">
              Past 15 Minutes
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((region) => (
            <tr
              key={region}
              className="border-b hover:bg-gray-50 transition duration-200"
            >
              <td className="px-4 py-2 text-gray-700">{heading}</td>
              <td className="px-4 py-2 text-gray-700">
                {data[region]?.avgLastHour.toFixed(2)}
              </td>
              <td className="px-4 py-2 text-gray-700">
                {data[region]?.avgLastHalfHour.toFixed(2)}
              </td>
              <td className="px-4 py-2 text-gray-700">
                {data[region]?.avgLastFifteenMinutes.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
