import { useState } from "react";

const initialTabs = [
  { name: "By # of Alerts", href: "#", current: true },
  { name: "By Status", href: "#", current: false },
  { name: "By Name", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabsV2({ handleTabChange }) {
  const [tabs, setTabs] = useState(initialTabs);

  const handleTabClick = (clickedTab) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === clickedTab.name,
      }))
    );
    handleTabChange(clickedTab.name);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          value={tabs.find((tab) => tab.current).name}
          onChange={(e) => {
            const selectedTab = tabs.find((tab) => tab.name === e.target.value);
            handleTabClick(selectedTab);
          }}
          className="block w-full rounded-md border-r_color_gray-300 focus:border-r_color_primary-500 focus:ring-r_color_primary-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              aria-current={tab.current ? "page" : undefined}
              className={classNames(
                tab.current
                  ? "bg-r_color_primary-100 text-r_color_primary-700"
                  : "text-r_color_gray-500 hover:text-r_color_gray-700",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
              onClick={(e) => {
                e.preventDefault();
                handleTabClick(tab);
              }}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
